import { Fragment, useCallback, useEffect, useState } from "react"
import Switch from "../../components/common/Switch"
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Checkbox,
	Container,
	Grid,
	GridItem,
	Heading,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	Text,
	useMediaQuery,
} from "@chakra-ui/react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { clearPermissions, filterRolesAndUsers, sortRoles } from "../../store/permissions/permissions.slice"
import {
	getPermissions,
	setAllUsersFunctionalitie,
	setRoleFunctionalitie,
	setUserFunctionalitie,
} from "../../actions/permissions/permissions-actions"

import Animation from "../../utils/animations/Routes/AnimatedPage"
import BsSortDown from "../../assets/icons/permissions/BsSortDown"
import BsSortUp from "../../assets/icons/permissions/BsSortUp"
import SettingsDotsIcon from "../../assets/icons/permissions/SettingsDotsIcon"
import BackForwardButtons from "../../components/common/BackForwardButtons"
import { SearchIcon } from "@chakra-ui/icons"
import LoadingHOC from "../../components/common/LoadingHOC"
import i18next from "i18next"

const Permissions = () => {
	const [t] = useTranslation("global")
	const dispatch = useDispatch()
	const [isExtraSmallWidth] = useMediaQuery("(max-width: 480px)")
	const [isSmallWidth] = useMediaQuery("(max-width: 768px)")
	const [isGetPermissions, setIsGetPermissions] = useState(false)
	const [isSortDesc, setIsSortDesc] = useState(false)
	// const [socket, setSocket] = useState(null);
	const modules = useSelector((state) => state.permissions.modules)
	const permissions = useSelector((state) => state.permissions.permissions)

	// useEffect(() => {
	// 	const newSocket = io("http://localhost:6001");
	// 	setSocket(newSocket);

	// 	return () => {
	// 	  newSocket.disconnect();
	// 	};
	//   }, []);

	useEffect(() => {
		dispatch(getPermissions()).then(() => setIsGetPermissions(true))
		return () => dispatch(clearPermissions())
	}, [dispatch])

	const handleSearch = useCallback(
		(e) => {
			dispatch(filterRolesAndUsers(e))
		},
		[dispatch],
	)

	const handleSortItems = useCallback(() => {
		setIsSortDesc((prevState) => !prevState)
		dispatch(sortRoles(isSortDesc ? "desc" : "asc"))
	}, [dispatch, isSortDesc])

	const getIconsList = useCallback((icons, color, fontColor) => {
		return icons.map((icon) => {
			const Image = require(
				`../../assets/icons/permissions/${icon.icon_name ? icon.icon_name : "ExampleIcon"}`,
			).default
			return (
				<Icon
					key={icon.id}
					w={["33px", "35px", "40px"]}
					h={["33px", "35px", "40px"]}
					p={2}
					color={fontColor}
					borderRadius={24}
					bgColor={color}
				>
					<Image />
				</Icon>
			)
		})
	}, [])

	const handleAllUsersSwitchChange = useCallback(
		(functionalityId, state) => {
			dispatch(setAllUsersFunctionalitie({ functionalityId, state }))
		},
		[dispatch],
	)

	const getPrimarySwitches = useCallback(
		(moduleFunctionalities) => {
			return moduleFunctionalities.map((item) => (
				<Switch
					key={item.id}
					aria-label='Switch functionalitie'
					style={{
						width: isExtraSmallWidth ? "30px" : isSmallWidth ? "32px" : "38px",
						height: isExtraSmallWidth ? "20px" : isSmallWidth ? "20px" : "22px",
					}}
					state={item.state}
					onChange={() => handleAllUsersSwitchChange(item.id, item.state)}
				/>
			))
		},
		[handleAllUsersSwitchChange, isExtraSmallWidth, isSmallWidth],
	)

	const handleRoleSwitchChange = useCallback(
		(roleId, functionalityId, state, stateId) => {
			dispatch(setRoleFunctionalitie({ roleId, functionalityId, state, stateId }))
		},
		[dispatch],
	)

	const getRolesSwitches = useCallback(
		(moduleFunctionalities, stateId) => {
			return moduleFunctionalities.map((item) => (
				<Switch
					key={item.id}
					aria-label='Switch functionalitie'
					style={{
						width: isExtraSmallWidth ? "30px" : isSmallWidth ? "32px" : "38px",
						height: isExtraSmallWidth ? "20px" : isSmallWidth ? "20px" : "22px",
					}}
					state={item.state}
					onChange={() =>
						handleRoleSwitchChange(item.role_id, item.module_functionality_id, item.state, stateId)
					}
				/>
			))
		},
		[handleRoleSwitchChange, isExtraSmallWidth, isSmallWidth],
	)

	const handleToogleCheckbox = useCallback(
		(userId, moduleFunctionalitieId, isSet, roleId, moduleId) => {
			dispatch(
				setUserFunctionalitie({
					userId,
					moduleFunctionalitieId,
					isSet,
					roleId,
					moduleId,
				}),
			)
		},
		[dispatch],
	)

	const getCheckboxes = useCallback(
		(module_functionalities, roleId, moduleId) => {
			return module_functionalities.map((item) => (
				<Checkbox
					key={item.id}
					isChecked={item.is_set}
					colorScheme='teal'
					aria-label='Checkbox functionalitie'
					onChange={() => handleToogleCheckbox(item.user_id, item.id, item.is_set, roleId, moduleId)}
				></Checkbox>
			))
		},
		[handleToogleCheckbox],
	)

	const getUserStates = useCallback(
		(module) => {
			return module.map((item) => (
				<GridItem
					key={item.id}
					display='flex'
					alignItems='center'
					h={55}
					border='1px solid #E5E5DF'
					justifyContent='space-around'
					bgColor='#F5F5F5'
				>
					{getRolesSwitches(item.module_functionalities, item.id)}
				</GridItem>
			))
		},
		[getRolesSwitches],
	)

	const getUserFunctionalities = useCallback(
		(modules, roleId) => {
			return modules.map((item) => (
				<GridItem
					key={item.id}
					display='flex'
					alignItems='center'
					h={55}
					border='1px solid #E5E5DF'
					justifyContent='space-around'
				>
					{getCheckboxes(item.module_functionalities, roleId, item.id)}
				</GridItem>
			))
		},
		[getCheckboxes],
	)

	const getUsersFunctionalities = useCallback(
		(users, roleId) => {
			return users.map((item) => (
				<Fragment key={item.id}>
					<GridItem
						position='sticky'
						left='0'
						zIndex={1}
						bgColor='#fff'
						display='flex'
						alignItems='center'
						h={55}
						border='1px solid #E5E5DF'
						ps={5}
					>
						{item.name}
					</GridItem>
					{getUserFunctionalities(item.modules, roleId)}
				</Fragment>
			))
		},
		[getUserFunctionalities],
	)

	const functionalitiesList = modules.map((item) => (
		<GridItem
			key={item.id}
			display='flex'
			alignItems='center'
			h={55}
			border='1px solid #E5E5DF'
			justifyContent='space-around'
		>
			{getIconsList(item.module_functionalities, item.primary_color, item.font_color)}
		</GridItem>
	))

	const statesForAllUsers = modules.map((item) => (
		<GridItem
			key={item.id}
			display='flex'
			alignItems='center'
			h={55}
			border='1px solid #E5E5DF'
			justifyContent='space-around'
			bgColor='#F5F5F5'
		>
			{getPrimarySwitches(item.module_functionalities, item.id)}
		</GridItem>
	))

	const users = permissions.map((item) => (
		<AccordionItem key={item.id} border='hidden' className='permissions-accordion-item'>
			<Grid
				position='relative'
				px={2}
				templateColumns={
					isExtraSmallWidth
						? `repeat(${modules.length + 1},  minmax(230px, 1fr))`
						: isSmallWidth
						  ? `repeat(${modules.length + 1},  minmax(270px, 1fr))`
						  : `repeat(${modules.length + 1},  minmax(470px, 1fr))`
				}
				zIndex={1}
			>
				<GridItem
					position='sticky'
					zIndex={1}
					left='0'
					display='flex'
					alignItems='center'
					h={55}
					border='1px solid #E5E5DF'
					bgColor='#F5F5F5'
				>
					<AccordionButton h='100%' justifyContent='space-between'>
						<Box
							as='span'
							aria-label='accordion-button'
							display='flex'
							fontSize={["13", "14", "16"]}
							fontWeight={600}
						>
							{item.name}
						</Box>
						<AccordionIcon boxSize={6} />
					</AccordionButton>
				</GridItem>
				{getUserStates(item.states)}
			</Grid>
			<AccordionPanel p={0}>
				<Grid
					px={2}
					templateColumns={
						isExtraSmallWidth
							? `repeat(${modules.length + 1},  minmax(230px, 1fr))`
							: isSmallWidth
							  ? `repeat(${modules.length + 1},  minmax(270px, 1fr))`
							  : `repeat(${modules.length + 1},  minmax(470px, 1fr))`
					}
					fontSize={["13", "14", "16"]}
				>
					{getUsersFunctionalities(item.users, item.id)}
				</Grid>
			</AccordionPanel>
		</AccordionItem>
	))

	const modulesList = modules.map((item) => (
		<GridItem
			key={item.id}
			display='flex'
			alignItems='center'
			p={5}
			h={55}
			fontWeight={400}
			border='1px solid #E5E5DF'
		>
			<Box
				display='flex'
				w='100%'
				h='41'
				justifyContent='center'
				alignItems='center'
				bgColor={item.primary_color}
				borderRadius='4px'
			>
				<Text color={item.font_color} fontWeight={600}>
					{item[`name_${i18next.language}`]}
				</Text>
			</Box>
		</GridItem>
	))

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Box display='flex' justifyContent='space-between' flexWrap='wrap'>
					<Box display='inline-flex' alignItems='center' mb={[2, 2, 2, 0]}>
						<BackForwardButtons />
						<Heading fontSize={["15", "15", "16"]} ms={2}>
							{t("Permissions.heading")}
						</Heading>
					</Box>
					<Box display='inline-flex' h={["35px", "auto"]}>
						<InputGroup>
							<Input
								placeholder={t("Permissions.searchInputPlaceholder")}
								bgColor={"#fff"}
								h={["35px", "40px"]}
								boxShadow={
									"0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);"
								}
								borderRadius={"4px"}
								onChange={(e) => handleSearch(e.target.value)}
							/>
							<InputRightElement>
								<SearchIcon />
							</InputRightElement>
						</InputGroup>
						<Button
							aria-label='sort-button'
							bgColor='#fff'
							boxSize={["auto", "40px"]}
							boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
							mx={3}
							onClick={handleSortItems}
						>
							{isSortDesc ? <BsSortDown boxSize={5} /> : <BsSortUp boxSize={5} />}
						</Button>
						<Button
							aria-label='settings-button'
							bgColor='#fff'
							boxSize={["auto", "40px"]}
							boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
						>
							<SettingsDotsIcon boxSize={5} />
						</Button>
					</Box>
				</Box>
				<Box
					w='100%'
					h='100%'
					my={3}
					borderRadius='4px'
					boxShadow={" 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)"}
					bgColor='#fff'
					overflow='auto'
				>
					<LoadingHOC isLoading={isGetPermissions}>
						<Accordion allowMultiple>
							<Grid px={3} pb={4}>
								<Grid
									position='sticky'
									top='0'
									zIndex={2}
									px={2}
									pt={5}
									bgColor='#fff'
									templateColumns={
										isExtraSmallWidth
											? `repeat(${modules.length + 1},  minmax(230px, 1fr))`
											: isSmallWidth
											  ? `repeat(${modules.length + 1},  minmax(270px, 1fr))`
											  : `repeat(${modules.length + 1},  minmax(470px, 1fr))`
									}
									fontSize={["13", "14", "16"]}
								>
									<GridItem
										position='sticky'
										left='0'
										bgColor='#fff'
										display='flex'
										alignItems='center'
										ps={[3, 4, 5]}
										h={55}
										color='#636b77'
										fontWeight={400}
										border='1px solid #E5E5DF'
									>
										{t("Permissions.module")}
									</GridItem>
									{modulesList}
									<GridItem
										position='sticky'
										left='0'
										bgColor='#fff'
										display='flex'
										alignItems='center'
										ps={[3, 4, 5]}
										h={55}
										color='#636b77'
										fontWeight={400}
										border='1px solid #E5E5DF'
									>
										{t("Permissions.functionalitie")}
									</GridItem>
									{functionalitiesList}
									<GridItem
										position='sticky'
										left='0'
										zIndex={2}
										display='flex'
										alignItems='center'
										h={55}
										border='1px solid #E5E5DF'
										ps={[3, 4, 5]}
										fontWeight={600}
										bgColor='#F5F5F5'
									>
										{t("Permissions.forAllUsers")}
									</GridItem>
									{statesForAllUsers}
								</Grid>
								{users}
							</Grid>
						</Accordion>
					</LoadingHOC>
				</Box>
			</Container>
		</Animation>
	)
}

export default Permissions
