/* eslint-disable no-undef */
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Divider,
	Flex,
	Image,
	Menu,
	MenuButton,
	MenuItemOption,
	MenuList,
	MenuOptionGroup,
	Text,
	useMediaQuery,
} from "@chakra-ui/react"
import { memo, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import i18next from "i18next"
import { useDispatch } from "react-redux"

import Footer from "../../../components/Footer/Footer"
import LoginForm from "../../../components/Auth/Login/LoginForm/LoginForm"
import Animation from "../../../utils/animations/Routes/AnimatedPage"
import { ChevronDownIcon } from "@chakra-ui/icons"
import MicrosoftIcon from "../../../assets/icons/login/MicrosoftIcon"
import { loginWithMicrosoft } from "../../../actions/auth/auth/auth-actions"

const Login = () => {
	const [t] = useTranslation("global")
	const dispatch = useDispatch()
	const [isDisabled, setIsDisabled] = useState(false)
	const [isLargerThan1664] = useMediaQuery("(min-width: 1664px)")

	const handleChangeLang = useCallback((lang) => {
		localStorage.setItem("language", lang)
		i18next.isChangedLanguage = true
		i18next.changeLanguage(lang)
	}, [])

	const handleLoginWithMicrosoft = () => {
		setIsDisabled(true)
		dispatch(loginWithMicrosoft()).then(() => setIsDisabled(false))
	}

	return (
		<Animation>
			<main>
				<Box bg='#E5E5DF' h={["92vh", "91vh", "91vh", "93vh"]}>
					<Image
						w='100%'
						h='100%'
						objectFit={"cover"}
						src={require("../../../assets/images/login/login-background.webp")}
						alt='background-image'
					/>
					<Box
						h={["auto", "auto"]}
						w={["60%", "50%", "50%", "30%", "18%"]}
						position='absolute'
						top={["43%", "43%", "45%", "46%", "50%"]}
						left='50%'
						transform='translate(-50%, -50%)'
						py={["1%", "1%", "1%"]}
						borderRadius='24'
						bgColor='#FFF'
						boxShadow='0px 4px 24px 0px rgba(189, 189, 189, 0.20)'
					>
						<Box display='flex' justifyContent='center' gap={[2, 2, 2, 3]}>
							<Image
								boxSize={["9", "12", "14", "60px", "16"]}
								src={`${process.env.PUBLIC_URL}/logos/${process.env.REACT_APP_API_KEY}-LOGO-192.png`}
								alt='FireStop Logo'
							/>
							<Image
								w={["12", "16", "80px", "82px", "98px"]}
								h={["9", "12", "14", "60px", "16"]}
								src={require("../../../assets/images/login/Poland (PL).png")}
								alt='Polish Flag'
							/>
						</Box>
						<LoginForm setIsDisabled={setIsDisabled} />
						<Text
							color={isDisabled ? "#d3d3d3" : "#727272"}
							fontSize={["10", "14"]}
							textDecoration='underline'
							display='flex'
							justifyContent='end'
							cursor={isDisabled && "not-allowed"}
							mr='17%'
							mt={["1", "1", "2"]}
						>
							{isDisabled ? (
								t("Login.main.remindPassword")
							) : (
								<Link style={{ display: isDisabled ? "none" : "block" }} to={"/reset-password"}>
									{t("Login.main.remindPassword")}
								</Link>
							)}
						</Text>
						<Flex mt={[3, 5]} alignItems={"center"} justifyContent={"center"}>
							<Button
								isDisabled={isDisabled}
								w='75%'
								display={"flex"}
								variant={"outline"}
								px={[1, 2]}
								h={["30px", "35px", "40px", "40px", isLargerThan1664 ? "40px" : "auto"]}
								gap={[6, 8, 10]}
								onClick={handleLoginWithMicrosoft}
							>
								<Text whiteSpace={"pre-wrap"} fontSize={[9, 12, 14, 14, 15]}>
									{t("Login.main.loginWithMicrosoft")}
								</Text>
								<MicrosoftIcon
									transform={["scale(2.5)", "scale(3)", "scale(4)"]}
									me={[4, 6, 8]}
									boxSize={5}
								/>
							</Button>
						</Flex>
						<Box w='70%' position='relative' left='50%' transform='translateX(-50%)'>
							<Divider borderColor='#000' mt={[2, 3]} />
							<Accordion border={"transparent"} allowMultiple>
								<AccordionItem>
									<h2>
										<AccordionButton p={[1, 2]}>
											<Box
												as='span'
												flex='1'
												textAlign='left'
												fontSize={["10", "14", "14", "15", "16"]}
											>
												{t("Login.main.options")}
											</Box>
											<AccordionIcon />
										</AccordionButton>
									</h2>
									<AccordionPanel p='0'>
										<Menu autoSelect='false'>
											<MenuButton
												as={Button}
												isDisabled={isDisabled}
												w='100%'
												variant='outline'
												fontSize={["9", "11", "13", "14", "16"]}
												h={"50%"}
												p={[1, 1, 2]}
												flexWrap={"wrap"}
												leftIcon={
													<Image
														bgColor='#E2E8F0'
														borderRadius={8}
														p={1}
														boxSize={["4", "4", "5"]}
														src={require("../../../assets/icons/login/languagesIcon.webp")}
													/>
												}
												rightIcon={<ChevronDownIcon boxSize={5} />}
											>
												{t("Login.main.selectText")}
											</MenuButton>
											<MenuList minW={[150, 150, 215]}>
												<MenuOptionGroup type='radio' onChange={handleChangeLang}>
													<MenuItemOption value='pl' p={[1, 1, 2, 2]}>
														<Box display='inline-flex'>
															<Image
																w='100%'
																h='100%'
																boxSize={[4, 5, 5, 5]}
																src={require("../../../assets/icons/login/PolandFlag.webp")}
															/>{" "}
															<Text mx={5} fontSize={[10, 14, 14, 14, 16]}>
																{t("Login.main.polishLanguage")}
															</Text>{" "}
														</Box>
													</MenuItemOption>
													<MenuItemOption value='en' p={[1, 1, 2, 2]}>
														<Box display='inline-flex'>
															<Image
																w='100%'
																h='100%'
																boxSize={[4, 5, 5, 5]}
																src={require("../../../assets/icons/login/EnglandFlag.webp")}
															/>{" "}
															<Text mx={5} fontSize={[10, 14, 14, 14, 16]}>
																{t("Login.main.englishLanguage")}
															</Text>
														</Box>
													</MenuItemOption>
													<MenuItemOption value='nl' p={[1, 1, 2, 2]}>
														<Box display='inline-flex'>
															<Image
																w='100%'
																h='100%'
																boxSize={[4, 5, 5, 5]}
																src={require("../../../assets/icons/login/NetherlandFlag.webp")}
															/>{" "}
															<Text mx={5} fontSize={[10, 14, 14, 14, 16]}>
																{t("Login.main.netherlandLanguage")}
															</Text>
														</Box>
													</MenuItemOption>
												</MenuOptionGroup>
											</MenuList>
										</Menu>
									</AccordionPanel>
								</AccordionItem>
							</Accordion>
						</Box>
					</Box>
				</Box>
			</main>
			<Footer />
		</Animation>
	)
}

export default memo(Login)
