import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons"
import { Box, Button } from "@chakra-ui/react"
import { memo, useState } from "react"
import { useNavigate } from "react-router-dom"

const BackForwardButtons = () => {
	const navigate = useNavigate()
	const [isDisabledBack] = useState(
		window.navigation?.canGoBack === undefined ? false : !window.navigation?.canGoBack,
	)
	const [isDisabledForward] = useState(
		window.navigation?.canGoForward === undefined ? false : !window.navigation?.canGoForward,
	)

	return (
		<Box display={"inline-flex"}>
			<Button
				isDisabled={isDisabledBack}
				aria-label='back-button'
				bgColor='#fff'
				minW='30px'
				w={["40px", "45px", "48px"]}
				h={["auto", "auto", "40px"]}
				boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.2)"}
				borderRadius='24px'
				onClick={() => navigate(-1)}
			>
				<ArrowBackIcon boxSize={7} />
			</Button>
			<Button
				isDisabled={isDisabledForward}
				aria-label='forward-button'
				bgColor='#fff'
				minW='30px'
				w={["40px", "45px", "48px"]}
				h={["auto", "auto", "40px"]}
				boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.2)"}
				borderRadius='24px'
				mx={3}
				onClick={() => navigate(1)}
			>
				<ArrowForwardIcon boxSize={7} />
			</Button>
		</Box>
	)
}

export default memo(BackForwardButtons)
