import { keyframes } from "@emotion/react"

export const firstLineAnimationNormal = keyframes`
    70% {
        transform: rotate(90deg) translateX(85%) translateY(140%);
        transform-origin: right;
    }
    100% {
        transform: rotate(50deg) translateX(37%) translateY(445%);
        transform-origin: right;
    }
    `

export const firstLineAnimationReverse = keyframes`
    0% {
        transform: rotate(50deg) translateX(36%) translateY(300%);
        transform-origin: right;
    }
    30% {
        transform: rotate(90deg) translateX(85%) translateY(140%);
        transform-origin: right;
    }
    100% {
        transform: rotate(0deg) translateX(0) translateY(0);
        transform-origin: right;
    }
`

export const secondLineAnimationNormal = keyframes`
    50% {
        opacity: 0.5;
    }
    70% {
        transform: rotate(90deg) translateX(30%) translateY(395%) ;
        transform-origin: right;
        opacity: 0;
    }
    100% {
        transform: rotate(90deg) translateX(50%) translateY(0%) ;
        transform-origin: right;
        opacity: 0;
    }
    `

export const secondLineAnimationReverse = keyframes`
    0% {
        transform: rotate(90deg) translateX(50%) translateY(220%);
        transform-origin: right;
        opacity: 0;
    }
    30% {
        transform: rotate(90deg) translateX(30%) translateY(540%);
        transform-origin: right;
        opacity: 0;
    }
    100% {
        transform: rotate(0deg) translateX(0) translateY(0);
        transform-origin: right;
        opacity: 1;
    }
`

export const thirdLineAnimationNormal = keyframes`
    70% {
        transform: rotate(90deg) translateX(-75%) translateY(-140%) ;
        transform-origin: left;
    }
    100% {
        transform: rotate(130deg) translateX(-99%) translateY(-180%) ;
        transform-origin: left;
    }
    `

export const thirdLineAnimationReverse = keyframes`
    0% {
        transform: rotate(130deg) translateX(-109%) translateY(-95%);
        transform-origin: left;
    }
    30% {
        transform: rotate(90deg) translateX(-75%) translateY(-140%);
        transform-origin: left;
    }
    100% {
        transform: rotate(0deg) translateX(0) translateY(0);
        transform-origin: left;
    }
`

export const chevronAnimationUp = keyframes`
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(180deg);
    }
`

export const getChevronAnimationDown = (isOpen) =>
	isOpen === 0
		? keyframes`
    0% {
        transform: rotateZ(180deg);
    }
    100% {
        transform: rotateZ(0deg);
    }
`
		: null
