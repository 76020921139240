import { useParams } from "react-router-dom"
import { Box, Flex, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import i18next from "i18next"

import Animation from "../../../../../../utils/animations/Routes/AnimatedPage"
import { clearInventoryMaterials } from "../../../../../../store/modules/warehouse/warehouses/warehouses-slice"
import { getInventoryMaterials } from "../../../../../../actions/modules/warehouse/warehouses/warehouses-actions"
import QuantitySelect from "../../../../../../components/Modules/Warehouse/Warehouses/Warehouse/QuantitySelect"
import StaticSearch from "../../../../../../components/common/StaticSearch"
import InventoryGroupActionButtons from "../../../../../../components/Modules/Warehouse/Warehouses/WarehouseInventory/InventoryGroup/InventoryGroupActionButtons"
import LoadingHOC from "../../../../../../components/common/LoadingHOC"
import InventoryMaterialsList from "../../../../../../components/Modules/Warehouse/Warehouses/WarehouseInventory/InventoryGroup/InventoryMaterialsList"
import NoData from "../../../../../../components/common/NoData"

const WarehouseInventoryGroup = () => {
	const dispatch = useDispatch()
	const materialsList = useSelector((state) => state.warehouses.inventoryMaterials)
	const [materials, setMaterials] = useState()
	const [materialsCopy, setMaterialsCopy] = useState()
	const [isLoading, setIsLoading] = useState(true)
	const [searchingTerm, setSearchingTerm] = useState("")
	const [recordsPerPage, setRecordsPerPage] = useState(5)
	const [isDisabledActions, setIsDisabledActions] = useState(false)
	const { warehouseId, groupId, documentId } = useParams()

	useEffect(() => {
		if (!materialsCopy) return
		setData(materialsCopy)
	}, [recordsPerPage])

	const setData = (initData) => {
		setMaterials(initData.slice(0, recordsPerPage))
		setMaterialsCopy(initData)
	}

	useEffect(() => {
		setIsLoading(true)
		dispatch(getInventoryMaterials({ warehouseId, groupId, documentId })).then((data) => {
			setData(data.payload.materialGroups[0].materials)
			setIsLoading(false)
		})

		return () => dispatch(clearInventoryMaterials())
	}, [i18next.language])

	const handleSetResults = (results) => {
		setData(results)
	}

	return (
		<Animation styles={{ display: "flex", flexDirection: "column", height: "80%" }}>
			<Flex
				w='100%'
				alignItems={"center"}
				gap={5}
				justifyContent={"space-between"}
				flexWrap={{ base: "wrap", xl: "nowrap" }}
			>
				{materialsList && (
					<Text fontSize={[13, 14, 15, 16]} fontWeight={600}>
						{materialsList.name}
					</Text>
				)}
				<Flex w={"100%"} justifyContent={"flex-end"} gap={2} flexWrap={{ base: "wrap", lg: "nowrap" }}>
					<QuantitySelect
						isDisabled={!materialsList?.materials?.length}
						setRecordsPerPage={setRecordsPerPage}
						maxLength={materialsCopy?.length}
					/>
					<Flex>
						<StaticSearch
							fieldsToFilter={["name"]}
							isDisabled={!materialsList?.materials?.length}
							searchText={searchingTerm}
							setSearchText={setSearchingTerm}
							handleSetResults={handleSetResults}
							originalArray={materialsList?.materials}
						/>
					</Flex>
					<InventoryGroupActionButtons
						isDisabled={!materialsList?.materials?.length}
						isDisabledActions={isDisabledActions}
						setIsDisabledActions={setIsDisabledActions}
						groupName={materialsList?.name}
						data={materials}
						parameterNames={materialsList?.parameter_names}
						setMaterials={setMaterials}
						setMaterialsCopy={setMaterialsCopy}
					/>
				</Flex>
			</Flex>
			<LoadingHOC isLoading={!isLoading}>
				<Box my={10} pb={5}>
					{materials?.length ? (
						<InventoryMaterialsList
							parameterNames={materialsList?.parameter_names}
							materials={materials}
							setMaterials={setMaterials}
							materialsList={materialsList?.materials}
							status={materialsList?.status}
							materialsCopy={materialsCopy}
							setMaterialsCopy={setMaterialsCopy}
							recordsPerPage={recordsPerPage}
							setIsDisabledActions={setIsDisabledActions}
							isDisabledActions={isDisabledActions}
						/>
					) : (
						<NoData />
					)}
				</Box>
			</LoadingHOC>
		</Animation>
	)
}

export default WarehouseInventoryGroup
