import { Container, Flex, Heading } from "@chakra-ui/layout"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import BackForwardButtons from "../../../../../components/common/BackForwardButtons"
import EmployeeTabs from "../../../../../components/Modules/Users/Employees/EmployeeTabs/EmployeeTabs"
import { editEmployee, getEmployeeById } from "../../../../../actions/modules/users/employees/employees-actions"
import {
	clearDownloadedEmployee,
	changeDownloadEmployeeLanguage,
} from "../../../../../store/modules/users/employees/employees-slice"
import { useTranslation } from "react-i18next"

const EditEmployee = () => {
	const { i18n } = useTranslation("global")
	const dispatch = useDispatch()
	const location = useLocation()
	const downloadedEmployee = useSelector((state) => state.employees.downloadedEmployee)
	const [initialValues, setInitialValues] = useState()

	useEffect(() => {
		dispatch(changeDownloadEmployeeLanguage())
	}, [i18n.language])

	useEffect(() => {
		const id = location.pathname.split("/")[4]
		dispatch(getEmployeeById(Number(id)))

		return () => dispatch(clearDownloadedEmployee())
	}, [])

	useEffect(() => {
		if (downloadedEmployee !== initialValues) {
			setInitialValues(downloadedEmployee)
		}
	}, [downloadedEmployee, initialValues])

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Flex alignItems={"center"} gap={3} mb={5}>
					<BackForwardButtons />
					<Heading
						whiteSpace={"pre-wrap"}
						wordBreak={"break-all"}
						fontWeight={600}
						fontSize={["15", "16", "17", "18", "19"]}
					>
						{initialValues && `${initialValues?.employee.first_name} ${initialValues?.employee.last_name}`}
					</Heading>
				</Flex>
				<EmployeeTabs
					isPatchMethod={true}
					initialValues={downloadedEmployee?.employee}
					employeeInitialDates={downloadedEmployee?.dropdowns}
					onSubmit={editEmployee}
				/>
			</Container>
		</Animation>
	)
}

export default EditEmployee
