import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import { Badge, Button, Checkbox, Flex, Image, Text } from "@chakra-ui/react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import { setSortedMaterials } from "../../../../../../store/modules/warehouse/warehouses/warehouses-slice"
import RecordsList from "../../../../../common/RecordsList"
import Pagination from "../../../../../common/Pagination"
import { RubberIcon } from "../../../../../../assets/icons/warehouse/RubberIcon"
import DebouncedInput from "../../Warehouse/DebouncedInput"
import {
	updateConfirmMaterial,
	updateFactState,
} from "../../../../../../actions/modules/warehouse/warehouses/warehouses-actions"
import LackImageIcon from "../../../../../../assets/icons/materials/LackImageIcon"

const InventoryMaterialsList = ({
	parameterNames,
	materials,
	status,
	setMaterials,
	materialsCopy,
	setMaterialsCopy,
	materialsList,
	recordsPerPage,
	setIsDisabledActions,
	isDisabledActions,
}) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const [headers, setHeaders] = useState([])
	const [records, setRecords] = useState([])
	const [isSortDescByMaterialName, setIsSortDescByMaterialName] = useState(true)
	const [isSortDescByWarehouseState, setIsSortDescByWarehouseState] = useState(true)
	const [isSortDescByFactState, setIsSortDescByFactState] = useState(true)
	const [isSortDescParameters, setSortDescParamters] = useState(parameterNames?.map(() => true))
	const [paginationLength, setPaginationLength] = useState(materialsCopy.length)
	const { warehouseId } = useParams()

	useEffect(() => {
		if (paginationLength === materialsCopy.length) return
		setPaginationLength(materialsCopy.length)
	}, [materialsCopy])

	const sorting = useCallback((array, field, order, isStringField = true) => {
		const compareFunction = (a, b) => {
			const aValue = isStringField ? a[field].toString().toLowerCase() : a[field]
			const bValue = isStringField ? b[field].toString().toLowerCase() : b[field]

			if (aValue < bValue) return !order ? -1 : 1
			if (aValue > bValue) return !order ? 1 : -1
			return 0
		}

		return [...array].sort(compareFunction)
	}, [])

	const concatenatedParameterValues = (values) => {
		let valuesString = ""

		values.forEach((value, index) => {
			valuesString += value
			if (index === values.length - 1) return
			valuesString += " x "
		})

		return valuesString
	}

	const parametersSorting = useCallback((array, index, order) => {
		const compareFunction = (a, b) => {
			const aValue = concatenatedParameterValues(a.parameter_values[index].values).toLowerCase()
			const bValue = concatenatedParameterValues(b.parameter_values[index].values).toLowerCase()

			if (aValue < bValue) return !order ? -1 : 1
			if (aValue > bValue) return !order ? 1 : -1
			return 0
		}

		return [...array].sort(compareFunction)
	}, [])

	const handleSetSortedData = useCallback(
		(setIsDescSort, sortedFullData, sortedFilterData, parameterIndex) => {
			dispatch(setSortedMaterials(sortedFullData))
			setMaterials(sortedFilterData.slice(0, recordsPerPage))
			setMaterialsCopy(sortedFilterData)
			setPaginationLength(sortedFilterData.length)
			parameterIndex || parameterIndex === 0
				? setIsDescSort((prevState) =>
						prevState.map((state, index) => (parameterIndex === index ? !state : state)),
				  )
				: setIsDescSort((prevState) => !prevState)
		},
		[recordsPerPage],
	)

	const handleSorting = useCallback(
		(field, isDescSort, setIsDescSort, parameterIndex, isStringField = true) => {
			let sortedMaterials = []
			let sortedMaterialsInitArray = []

			sortedMaterials =
				parameterIndex || parameterIndex === 0
					? parametersSorting(materialsCopy, parameterIndex, isDescSort)
					: sorting(materialsCopy, field, isDescSort, isStringField)
			sortedMaterialsInitArray =
				parameterIndex || parameterIndex === 0
					? parametersSorting(materialsList, parameterIndex, isDescSort)
					: sorting(materialsList, field, isDescSort, isStringField)

			handleSetSortedData(setIsDescSort, sortedMaterialsInitArray, sortedMaterials, parameterIndex)
		},
		[materialsList, materials],
	)

	const handleChangePage = (page) => {
		const start = (page - 1) * recordsPerPage
		const end = start + recordsPerPage
		const newMaterials = materialsCopy.slice(start, end)
		setMaterials(newMaterials)
	}

	const handleSaveFactState = (_, value, product_id) => {
		dispatch(updateFactState({ warehouseId, product_id, value })).then((res) => {
			if (res.error) return

			setMaterials((prevState) =>
				prevState.map((material) => {
					if (material.product_id !== product_id) return material
					return {
						...material,
						fact_state: Number(value),
					}
				}),
			)
			setMaterialsCopy((prevState) =>
				prevState.map((material) => {
					if (material.product_id !== product_id) return material
					return {
						...material,
						fact_state: Number(value),
					}
				}),
			)
		})
	}

	const handleConfirmMaterial = (id, value) => {
		setIsDisabledActions(true)
		dispatch(updateConfirmMaterial({ warehouseId, id, value: !value })).then((res) => {
			if (res.error) {
				setIsDisabledActions(false)
				return
			}

			setMaterials((prevState) =>
				prevState.map((material) => {
					if (material.product_id !== id) return material
					return {
						...material,
						is_confirmed: !value,
					}
				}),
			)
			setMaterialsCopy((prevState) =>
				prevState.map((material) => {
					if (material.product_id !== id) return material
					return {
						...material,
						is_confirmed: !value,
					}
				}),
			)
			setIsDisabledActions(false)
		})
	}

	const handleClearMaterialState = (product_id) => {
		setIsDisabledActions(true)
		dispatch(updateFactState({ warehouseId, product_id, value: 0 })).then((res) => {
			if (res.error) {
				setIsDisabledActions(false)
				return
			}

			setMaterials((prevState) =>
				prevState.map((material) => {
					if (material.product_id !== product_id) return material
					return {
						...material,
						fact_state: 0,
					}
				}),
			)
			setMaterialsCopy((prevState) =>
				prevState.map((material) => {
					if (material.product_id !== product_id) return material
					return {
						...material,
						fact_state: 0,
					}
				}),
			)
			setIsDisabledActions(false)
		})
	}

	const materialsHeaders = useCallback(
		(parameterNames) => {
			const parametersHeaders = parameterNames.map((parameter, index) => ({
				name: parameter.name,
				isDescSort: isSortDescParameters[index],
				sortingMethod: () => handleSorting("", isSortDescParameters[index], setSortDescParamters, index),
			}))

			return [
				{
					name: t("Warehouses.image"),
				},
				{
					name: t("Warehouses.material"),
					isDescSort: isSortDescByMaterialName,
					sortingMethod: () => handleSorting("name", isSortDescByMaterialName, setIsSortDescByMaterialName),
				},
				...parametersHeaders,
				{
					name: t("Warehouses.warehouseState"),
					isDescSort: isSortDescByWarehouseState,
					sortingMethod: () =>
						handleSorting(
							"warehouse_state",
							isSortDescByWarehouseState,
							setIsSortDescByWarehouseState,
							null,
							false,
						),
				},
				{
					name: t("Warehouses.factState"),
					isDescSort: isSortDescByFactState,
					sortingMethod: () =>
						handleSorting("fact_state", isSortDescByFactState, setIsSortDescByFactState, null, false),
				},
				{
					name: t("Warehouses.clear"),
					justifyContent: "end",
				},
			]
		},
		[
			isSortDescParameters,
			isSortDescByMaterialName,
			isSortDescByWarehouseState,
			isSortDescByFactState,
			handleSorting,
		],
	)

	const getRecord = (material) => [
		{
			body: material?.image ? (
				<Image src={material.image} alt='Material image' boxSize={"32px"} objectFit={"contain"} />
			) : (
				<LackImageIcon alt='Lack of material image' boxSize={"32px"} objectFit={"contain"} />
			),
		},
		{
			body: (
				<Text fontSize={{ base: "12px", md: "13px", lg: "14px" }} wordBreak={"break-word"}>
					{material.name}
				</Text>
			),
		},
		...material.parameter_values.map((parameter) => ({
			body: (
				<Badge
					bgColor={"#F2F2EF"}
					whiteSpace={"pre-wrap"}
					fontSize={{ base: "10px", md: "11px", lg: "12px" }}
					wordBreak={"break-word"}
				>
					{concatenatedParameterValues(parameter.values)}
				</Badge>
			),
		})),
		{
			body: (
				<Text fontSize={{ base: "12px", md: "13px", lg: "14px" }} wordBreak={"break-word"}>
					{material.warehouse_state}
				</Text>
			),
		},
		{
			body: (
				<Flex gap={2}>
					{status === 1 ? (
						<Text fontSize={{ base: "12px", md: "13px", lg: "14px" }} wordBreak={"break-word"}>
							{material.fact_state}
						</Text>
					) : (
						<>
							<DebouncedInput
								isDisabled={material.is_confirmed || isDisabledActions}
								h={{ base: "25px", md: "30px" }}
								placeholder={t("Warehouses.enterState")}
								id={material.id}
								product_id={material.product_id}
								value={material.fact_state}
								borderColor={material.warehouse_state == material.fact_state ? "green.300" : "inherit"}
								handleSave={handleSaveFactState}
							/>
							<Checkbox
								isDisabled={isDisabledActions}
								isChecked={material.is_confirmed}
								colorScheme='teal'
								onChange={() => handleConfirmMaterial(material.product_id, material.is_confirmed)}
							/>
						</>
					)}
				</Flex>
			),
		},
		{
			body: (
				<Button
					isDisabled={material.is_confirmed || isDisabledActions}
					bgColor={"#F2F2EF"}
					minW='30px'
					boxSize={{ base: "30px", md: "35px" }}
					onClick={() => handleClearMaterialState(material.product_id)}
				>
					<RubberIcon boxSize={{ base: 4, md: 5 }} color='red' />
				</Button>
			),
			justifyContent: "end",
		},
	]

	const getMaterialsRecords = (materials) =>
		materials?.map((material) => {
			const record = getRecord(material)
			if (status === 1) record.pop()

			return { childrens: record }
		})

	useEffect(() => {
		const headers = materialsHeaders(parameterNames)
		if (status === 1) headers.pop()

		setHeaders(headers)
		setRecords(getMaterialsRecords(materials))
	}, [materials, i18next.language, isDisabledActions])

	return (
		<>
			<RecordsList headers={headers} records={records} />
			{!!headers.length && !!records.length && (
				<Pagination
					style={{
						marginTop: "10px",
						paddingBottom: "10px",
					}}
					totalRecords={paginationLength}
					array={materialsCopy}
					recordsPerPage={recordsPerPage}
					onPageChange={(page) => handleChangePage(page)}
				/>
			)}
		</>
	)
}

export default InventoryMaterialsList
