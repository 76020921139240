import { Button, useDisclosure } from "@chakra-ui/react"
import { memo, useEffect, useState } from "react"
import { PDFViewer } from "@react-pdf/renderer"
import { useTranslation } from "react-i18next"
import { BrowserView } from "react-device-detect"
import { useSelector } from "react-redux"

import PDFModal from "../../../../../common/Modal/PDFModal"
import InventoryGroupPDF from "./InventoryGroupPDF"

const InventoryGroupPrintButton = ({ data, groupName, parameterNames, isDisabled }) => {
	const { t } = useTranslation("global")
	const [parameters, setParameters] = useState([])
	const { isOpen, onOpen, onClose } = useDisclosure()
	const currentWarehouse = useSelector((state) => state.warehouses.currentWarehouse)

	useEffect(() => {
		if (!parameterNames) return
		setParameters(parameterNames?.map((parameter) => parameter.name))
	}, [parameterNames])

	return (
		<BrowserView>
			<Button
				isDisabled={isDisabled}
				fontSize={[13, 14, 15, 16]}
				fontWeight={"400"}
				h={["25px", "35px", "40px"]}
				bgColor={"#FFF"}
				borderRadius={"4px"}
				boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
				onClick={onOpen}
			>
				{t("Warehouses.print")}
			</Button>
			<PDFModal isOpen={isOpen} onClose={onClose}>
				<PDFViewer style={{ width: "100%", height: "75vh" }}>
					<InventoryGroupPDF
						data={data}
						warehouseString={t("Warehouses.warehouse")}
						warehouseName={currentWarehouse?.name}
						tableHeaders={[
							t("Warehouses.material"),
							...parameters,
							t("Warehouses.warehouseState"),
							t("Warehouses.factState"),
						]}
						tableFields={["name", ...parameters, "warehouse_state", "fact_state"]}
						materialGroupString={t("Warehouses.materialGroup")}
						materialGroup={groupName}
					/>
				</PDFViewer>
			</PDFModal>
		</BrowserView>
	)
}

export default memo(InventoryGroupPrintButton)
