import { Badge, Button, Flex, Text } from "@chakra-ui/react"
import { useLocation, useNavigate } from "react-router-dom"
import { memo, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import i18next from "i18next"

import PermissionsChecked from "../../../common/PermissionsChecked"
import BinIcon from "../../../../assets/icons/materials/BinIcon"
import EditIcon from "../../../../assets/icons/materials/EditIcon"
import RecordsList from "../../../common/RecordsList"
import Pagination from "../../../common/Pagination"

const OrdersList = ({
	orders,
	isDisabledForm,
	hasDeleteAccess,
	handleOpenDialog,
	ordersCopy,
	paginationLength,
	handleChangePage,
	isProjectSortDesc,
	handleSortingByProject,
	isCreatedSortDesc,
	handleSortingByCreated,
	isPrioritySortDesc,
	handleSortingByPriority,
	isNumberSortDesc,
	handleSortingByNumber,
	isDeliverySortDesc,
	handleSortingByDelivery,
	isStatusSortDesc,
	handleSortingByStatus,
}) => {
	const { t } = useTranslation("global")
	const navigate = useNavigate()
	const location = useLocation()
	const [headers, setHeaders] = useState([])
	const [records, setRecords] = useState([])

	const handleEditNavigate = useCallback(
		(id) => {
			navigate(`/${location.pathname.split("/")[1]}/shop/${id}`)
		},
		[location.pathname, navigate],
	)

	const getOrdersListHeaders = [
		{
			name: t("OrderList.project"),
			isDescSort: isProjectSortDesc,
			sortingMethod: handleSortingByProject,
		},
		{
			name: t("OrderList.created"),
			isDescSort: isCreatedSortDesc,
			sortingMethod: handleSortingByCreated,
		},
		{
			name: t("OrderList.priority"),
			isDescSort: isPrioritySortDesc,
			sortingMethod: handleSortingByPriority,
		},
		{
			name: t("OrderList.number"),
			isDescSort: isNumberSortDesc,
			sortingMethod: handleSortingByNumber,
		},
		{
			name: t("OrderList.delivery"),
			isDescSort: isDeliverySortDesc,
			sortingMethod: handleSortingByDelivery,
		},
		{
			name: t("OrderList.status"),
			isDescSort: isStatusSortDesc,
			sortingMethod: handleSortingByStatus,
		},
	]

	const getRecord = (order) => [
		{
			body: <Text wordBreak={"break-word"}>{order.project}</Text>,
		},
		{
			body: <Text wordBreak={"break-word"}>{order.created}</Text>,
		},
		{
			body: (
				<Badge fontSize={[9, 10, 11, 12]} color='#FFF' bgColor={order.priority_color}>
					{order.priority}
				</Badge>
			),
		},
		{
			body: <Text wordBreak={"break-word"}>{order.number}</Text>,
		},
		{
			body: (
				<Badge fontSize={[9, 10, 11, 12]} bgColor='#F2F2EF'>
					{order.delivery_date}
				</Badge>
			),
		},
		{
			body: (
				<Flex
					justifyContent='space-between'
					alignItems='center'
					w='100%'
					py={[1, 2]}
					flexWrap={{ base: "wrap", md: "nowrap" }}
					gap={{ base: 1, md: 2 }}
				>
					<Flex
						flexWrap={{ base: "wrap", md: "nowrap" }}
						w='100%'
						gap={1}
						bgColor={order.status_color}
						borderLeft={`4px solid ${order.status_border_color}`}
						borderRadius='4px'
						py={1}
						px={{ base: 1, md: 0.5, lg: 3 }}
						justifyContent={{ base: "center", md: "space-between" }}
						alignItems='center'
					>
						<Text
							wordBreak={"break-word"}
							textAlign={["center", "left"]}
							fontSize={{ base: "12px", md: "13px", lg: "14px" }}
						>
							{order.status}
						</Text>
						<Button
							aria-label='Edit button'
							isDisabled={isDisabledForm}
							onClick={() => handleEditNavigate(order.id)}
							bgColor={order.status_border_color}
							minW='25px'
							p={0}
							boxSize={[5, 6, 7]}
						>
							<EditIcon boxSize={[4, 4, 5]} />
						</Button>
					</Flex>
					<PermissionsChecked
						permissions={"order"}
						checkingRequired={!order.my_order}
						allowedPermissions={["delete_order"]}
					>
						<Flex justifyContent='center' w={{ base: "100%", md: "auto" }}>
							<Button
								aria-label='Remove button'
								isDisabled={isDisabledForm || (!hasDeleteAccess && order.status_code !== 10)}
								bgColor='#FFF'
								minW='25px'
								p={0}
								boxSize={{ base: 7, md: 8 }}
								onClick={() => handleOpenDialog(order.id)}
							>
								<BinIcon
									color={"red.500"}
									w={{ base: "20px", md: "23px" }}
									h={{ base: "22px", md: "25px" }}
								/>
							</Button>
						</Flex>
					</PermissionsChecked>
				</Flex>
			),
		},
	]

	const getOrdersListRecords = (orders) =>
		orders.map((order) => ({
			childrens: getRecord(order),
		}))

	useEffect(() => {
		setHeaders(getOrdersListHeaders)
		setRecords(getOrdersListRecords(orders))
	}, [orders, i18next.language])

	return (
		<>
			<RecordsList headers={headers} records={records} />
			{!!headers.length && !!records.length && (
				<Pagination
					style={{
						marginTop: "10px",
						paddingBottom: "10px",
					}}
					array={ordersCopy}
					totalRecords={paginationLength}
					onPageChange={(page) => handleChangePage(page)}
				/>
			)}
		</>
	)
}

export default memo(OrdersList)
