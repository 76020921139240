import { createSlice } from "@reduxjs/toolkit"
import {
	addRole,
	getOrdersSettings,
	removeAutoEmailUser,
	saveAutoRemoveTime,
	setAutomaticEmails,
	setUsersRoles,
} from "../../../../actions/modules/orders/ordersSettings/orders-settings-actions"

/**
 * Slice for order settings
 */
const ordersSettingsSlice = createSlice({
	name: "orderSettings",
	initialState: {
		ordersSettings: null,
	},
	reducers: {
		clearOrdersSettings(state) {
			state.ordersSettings = null
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getOrdersSettings.fulfilled, (state, action) => {
				const data = action.payload
				delete data?.message
				state.ordersSettings = data
			})
			.addCase(saveAutoRemoveTime.fulfilled, (state, action) => {
				state.ordersSettings.deleting_after_time.config.time = action.payload.config.time
			})
			.addCase(setAutomaticEmails.fulfilled, (state, action) => {
				state.ordersSettings.automatic_mail.filtered_users = action.payload.filtered_users
				state.ordersSettings.automatic_mail.config = action.payload.config
			})
			.addCase(removeAutoEmailUser.fulfilled, (state, action) => {
				const data = state.ordersSettings.automatic_mail.config.filter((item) => item.id !== action.meta.arg)
				state.ordersSettings.automatic_mail.config = data
				state.ordersSettings.automatic_mail.filtered_users = action.payload.filtered_users
			})
			.addCase(addRole.fulfilled, (state, action) => {
				state.ordersSettings.order_group_permissions.config = action.payload.config
				state.ordersSettings.user_order_group_permissions.config = action.payload.users.config
			})
			.addCase(setUsersRoles.fulfilled, (state, action) => {
				state.ordersSettings.user_order_group_permissions.config = action.payload.config
				state.ordersSettings.order_group_permissions.config = action.payload.roles.config
			})
	},
})

export const { clearOrdersSettings } = ordersSettingsSlice.actions

export default ordersSettingsSlice
