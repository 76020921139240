import { Box, Button, Collapse, Flex, Progress, Text, useBreakpointValue, useDisclosure } from "@chakra-ui/react"
import { useLocation, useNavigate } from "react-router-dom"
import { memo } from "react"
import { AddIcon, MinusIcon } from "@chakra-ui/icons"
import { useDispatch, useSelector } from "react-redux"

import SettingsDotsIcon from "../../../../assets/icons/permissions/SettingsDotsIcon"
import { DcoumentIcon } from "../../../../assets/icons/warehouse/DcoumentIcon"
import { InventoryIcon } from "../../../../assets/icons/warehouse/InventoryIcon"
import Warehouse from "../../../../assets/icons/header/Warehouse"
import EditIcon from "../../../../assets/icons/materials/EditIcon"
import BinIcon from "../../../../assets/icons/materials/BinIcon"
import RemoveAlert from "../../../common/RemoveAlert"
import { deleteWarehouse } from "../../../../actions/modules/warehouse/warehouses/warehouses-actions"
import { useGetCurrentLimitedWarehouse } from "../../../../hooks/Modules/Warehouse/useGetCurrentLimitedWarehouse"
import { useTranslation } from "react-i18next"
import PermissionsChecked from "../../../common/PermissionsChecked"

const MagazineCard = ({ magazine }) => {
	const { t } = useTranslation("global")
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const location = useLocation()
	const limitedWarehouse = useGetCurrentLimitedWarehouse(magazine.id)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure()
	const endingHeight = useBreakpointValue({ base: "58px", sm: "68px", md: "78px", lg: "90px" }, { ssr: false })
	const userRoles = useSelector((state) => state.auth["warehouse_module_permissions"])

	const handleNavigateToWarehouse = () => {
		const isAllowedWarehousePreview = userRoles.includes("warehouse_preview")
		const splittedPath = location.pathname.split("/")

		if (isAllowedWarehousePreview) {
			navigate(`/${splittedPath[1]}/${splittedPath[2]}/${magazine.id}`)
			return
		}

		navigate(`/${splittedPath[1]}/${splittedPath[2]}/${magazine.id}/documents`)
	}

	const handleNavigateToCurrentPlace = (place) => (e) => {
		e.stopPropagation()

		const splittedPath = location.pathname.split("/")
		navigate(`/${splittedPath[1]}/${splittedPath[2]}/${magazine.id}/${place}`)
	}

	const handleNavigateToEdit = (e) => {
		e.stopPropagation()
		const splittedPath = location.pathname.split("/")
		navigate(`/${splittedPath[1]}/${splittedPath[2]}/edit/${magazine.id}`)
	}

	const handleOpenAlert = (e) => {
		e.stopPropagation()
		onOpenAlert()
	}

	const handleConfirmRemove = () => {
		dispatch(deleteWarehouse(magazine.id)).then(() => {
			onCloseAlert()
		})
	}

	return (
		<Box
			position={"relative"}
			w={["280px", "300px", "320px", "330px", "340px"]}
			h={["170px", "190px", "210px", "220px", "230px"]}
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			transition={"transform 0.1s"}
			cursor={"pointer"}
			_hover={{
				transform: "scale(1.05)",
			}}
			onClick={handleNavigateToWarehouse}
		>
			<Flex
				h='100%'
				alignItems='center'
				bgColor={"#FFF"}
				borderRadius='4px'
				overflow='hidden'
				flexDirection={"column"}
				justifyContent={"space-around"}
			>
				<Text textAlign={"center"} fontSize={[13, 14, 15, 16]}>
					{magazine.name}
				</Text>
				<Flex justifyContent={"center"} alignItems={"flex-end"} flexWrap={"wrap"} gap={2}>
					<PermissionsChecked permissions={"warehouse"} allowedPermissions={["warehouse_preview"]}>
						<Button
							p={0}
							minW='25px'
							boxSize={["25px", "30px", "35px", "40px"]}
							bgColor={"#E5E5DF"}
							_hover={{ bgColor: "#F0F0EC" }}
							onClick={handleNavigateToWarehouse}
						>
							<Warehouse boxSize={[5, 6]} />
						</Button>
					</PermissionsChecked>
					<Button
						p={0}
						minW='25px'
						boxSize={["25px", "30px", "35px", "40px"]}
						bgColor={"#E5E5DF"}
						_hover={{ bgColor: "#F0F0EC" }}
						onClick={handleNavigateToCurrentPlace("documents")}
					>
						<DcoumentIcon boxSize={[4, 5]} />
					</Button>
					<PermissionsChecked permissions={"warehouse"} allowedPermissions={["document_edit"]}>
						{!limitedWarehouse && (
							<>
								<Button
									p={0}
									minW='25px'
									boxSize={["25px", "30px", "35px", "40px"]}
									bgColor={"#E5E5DF"}
									_hover={{ bgColor: "#F0F0EC" }}
									onClick={handleNavigateToCurrentPlace("add")}
								>
									<AddIcon boxSize={[3, 4]} />
								</Button>
								<Button
									p={0}
									minW='25px'
									boxSize={["25px", "30px", "35px", "40px"]}
									bgColor={"#E5E5DF"}
									_hover={{ bgColor: "#F0F0EC" }}
									onClick={handleNavigateToCurrentPlace("remove")}
								>
									<MinusIcon boxSize={[3, 4]} />
								</Button>
							</>
						)}
					</PermissionsChecked>
					<PermissionsChecked permissions={"warehouse"} allowedPermissions={["inventory"]}>
						<Button
							p={0}
							minW='25px'
							boxSize={["25px", "30px", "35px", "40px"]}
							bgColor={"#E5E5DF"}
							_hover={{ bgColor: "#F0F0EC" }}
							onClick={handleNavigateToCurrentPlace(
								limitedWarehouse ? `documents/inv/${limitedWarehouse?.document_id}` : "inventory",
							)}
						>
							<InventoryIcon boxSize={[3, 4]} />
						</Button>
					</PermissionsChecked>
					<Flex flexDirection={"column"} onMouseLeave={onClose} onMouseEnter={onOpen}>
						<Collapse in={isOpen} animateOpacity endingHeight={endingHeight}>
							<Flex flexDirection={"column"} mb={1} gap={1} alignItems={"center"} borderRadius={4}>
								<Button
									p={0}
									minW='25px'
									boxSize={["25px", "30px", "35px", "40px"]}
									bgColor={"#E5E5DF"}
									_hover={{ bgColor: "#F0F0EC" }}
									onClick={handleOpenAlert}
								>
									<BinIcon color='red' boxSize={[4, 5]} />
								</Button>
								<Button
									p={0}
									minW='25px'
									boxSize={["25px", "30px", "35px", "40px"]}
									bgColor={"#E5E5DF"}
									_hover={{ bgColor: "#F0F0EC" }}
									onClick={handleNavigateToEdit}
								>
									<EditIcon boxSize={[4, 5]} />
								</Button>
							</Flex>
						</Collapse>
						<Button
							p={0}
							minW='25px'
							boxSize={["25px", "30px", "35px", "40px"]}
							bgColor={"#E5E5DF"}
							_hover={{ bgColor: "#F0F0EC" }}
							onMouseEnter={onOpen}
							onTouchStart={isOpen ? onClose : onOpen}
							onClick={(e) => e.stopPropagation()}
						>
							<SettingsDotsIcon boxSize={[4, 5]} />
						</Button>
					</Flex>
					<PermissionsChecked permissions={"warehouse"} allowedPermissions={["inventory"]}>
						{limitedWarehouse && (
							<Flex flexDir={"column"} alignItems={"center"} justifyContent={"flex-end"}>
								<Text fontSize={[13, 14, 15, 16]}>
									{t("Warehouses.inventory")}: {magazine.progress}%
								</Text>
								<Progress
									h={{ base: "4px", sm: "4.5px", md: "5px", lg: "5.5px", xl: "6px" }}
									w={["200px", "220px", "240px", "250px", "260px"]}
									colorScheme='teal'
									borderRadius={8}
									hasStripe
									value={magazine.progress}
								/>
							</Flex>
						)}
					</PermissionsChecked>
				</Flex>
			</Flex>
			<RemoveAlert isOpen={isOpenAlert} onClose={onCloseAlert} handleConfirmRemove={handleConfirmRemove} />
		</Box>
	)
}

export default memo(MagazineCard)
