import { Button, Flex } from "@chakra-ui/react"
import { memo, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CheckIcon } from "@chakra-ui/icons"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import InventoryGroupExcelButton from "./InventoryGroupExcelButton"
import InventoryGroupCopyButton from "./InventoryGroupCopyButton"
import InventoryGroupPDFButton from "./InventoryGroupPDFButton"
import { updateConfirmAllMaterials } from "../../../../../../actions/modules/warehouse/warehouses/warehouses-actions"
import InventoryGroupPrintButton from "./InventoryGroupPrintButton"

const InventoryGroupActionButtons = ({
	data,
	groupName,
	parameterNames,
	isDisabled,
	isDisabledActions,
	setIsDisabledActions,
	setMaterials,
	setMaterialsCopy,
}) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const materialsList = useSelector((state) => state.warehouses.inventoryMaterials)
	const { warehouseId } = useParams()
	const [isAllConfirmed, setIsAllConfirmed] = useState(
		materialsList?.materials?.every((material) => material.is_confirmed),
	)

	useEffect(() => {
		if (!materialsList) return
		const isConfirmed = materialsList.materials.every((material) => material.is_confirmed)
		setIsAllConfirmed(isConfirmed)
	}, [materialsList])

	const handleMarkAll = () => {
		setIsDisabledActions(true)
		dispatch(
			updateConfirmAllMaterials({
				warehouseId,
				products: materialsList?.materials?.map((material) => material.product_id),
				confirm: !isAllConfirmed,
			}),
		).then((res) => {
			if (res.error) {
				setIsDisabledActions(false)
				return
			}

			setMaterials((prevState) =>
				prevState.map((material) => ({
					...material,
					is_confirmed: !isAllConfirmed,
				})),
			)
			setMaterialsCopy((prevState) =>
				prevState.map((material) => ({
					...material,
					is_confirmed: !isAllConfirmed,
				})),
			)
			setIsDisabledActions(false)
		})
	}

	return (
		<Flex gap={2} w={["100%", "auto"]} flexWrap={["wrap", "wrap", "nowrap"]} justifyContent={"end"}>
			<InventoryGroupExcelButton
				isDisabled={isDisabled}
				groupName={groupName}
				parameterNames={parameterNames}
				jsonData={data}
			/>
			<InventoryGroupPDFButton
				isDisabled={isDisabled}
				data={data}
				groupName={groupName}
				parameterNames={parameterNames}
			/>
			<InventoryGroupCopyButton
				isDisabled={isDisabled}
				data={data}
				groupName={groupName}
				parameterNames={parameterNames}
			/>
			<InventoryGroupPrintButton
				isDisabled={isDisabled}
				data={data}
				groupName={groupName}
				parameterNames={parameterNames}
			/>
			{materialsList?.status === 0 && (
				<Button
					isDisabled={isDisabled || isDisabledActions}
					fontSize={[13, 14, 15, 16]}
					fontWeight={"400"}
					h={["25px", "35px", "40px"]}
					bgColor={"#FFF"}
					borderRadius={"4px"}
					boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
					gap={2}
					onClick={handleMarkAll}
				>
					<CheckIcon border='1px solid black' p={0.5} borderRadius={3} />
					{isAllConfirmed ? t("Warehouses.unmarkAll") : t("Warehouses.markAll")}
				</Button>
			)}
		</Flex>
	)
}

export default memo(InventoryGroupActionButtons)
