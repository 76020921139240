import { Button, Flex, Text } from "@chakra-ui/react"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"

const ActionButtons = ({ onOpen }) => {
	const { t } = useTranslation("global")
	const { isSubmitting } = useFormikContext()

	return (
		<Flex justifyContent='end' mt={4}>
			<Button
				isDisabled={isSubmitting}
				bgColor='red.600'
				_hover={{ backgroundColor: "red.700" }}
				colorScheme='red'
				color='#FFF'
				me={2}
				h={["30px", "35px", "40px"]}
				fontSize={[13, 14, 15, 16]}
				onClick={onOpen}
			>
				<Text aria-label='Cancel button'>{t("Employees.cancel")}</Text>
			</Button>
			<Button
				isDisabled={isSubmitting}
				type='submit'
				bgColor='green.600'
				_hover={{ backgroundColor: "green.700" }}
				colorScheme='green'
				color='#FFF'
				h={["30px", "35px", "40px"]}
				fontSize={[13, 14, 15, 16]}
			>
				<Text aria-label='Save button'>{t("Employees.save")}</Text>
			</Button>
		</Flex>
	)
}

export default ActionButtons
