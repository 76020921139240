import { Box, Button, Divider, Flex, Heading, Text } from "@chakra-ui/react"
import { memo } from "react"
import { useTranslation } from "react-i18next"

import FormikForm from "../../../../common/FormikForm"
import UsersInGroups from "./UsersInGroups"
import { setUsersRoles } from "../../../../../actions/modules/users/settings/settings-actions"

const UsersInGroupsForm = ({ initialData, groups }) => {
	const { t } = useTranslation("global")

	const saveMiddleware = (values) => ({
		data: {
			users: values.users.map((user) => ({
				id: user.id,
				user: user.user,
				user_group_permission_id: Number(user.user_group_permission_id),
			})),
		},
	})

	return (
		<FormikForm
			isPatchMethod={true}
			shouldNavigate={false}
			initialValues={{
				users: initialData?.config,
			}}
			middleware={saveMiddleware}
			onSubmit={setUsersRoles}
		>
			<Box minH={["300px", "400px", "500px", "700px"]}>
				<Heading fontSize={[14, 15, 16, 17]} fontWeight={400}>
					{t("UsersSettings.usersInGroups")}
				</Heading>
				<Text fontSize={[13, 14, 15, 16]} color='teal.600'>
					{t("UsersSettings.usersListAndRoles")}
				</Text>
				<Divider my={2} w='100%' borderColor='#D4D4CD' borderWidth='1px' />
				<UsersInGroups config={initialData?.config} groups={groups} />
				<Flex h='100%' minH={["100px", "150px", "200px", "150px"]} alignItems='end' justifyContent='end'>
					<Button
						type='submit'
						fontSize={[13, 14, 15, 16]}
						bgColor='green.600'
						_hover={{ backgroundColor: "green.700" }}
						colorScheme='green'
						h={["30px", "35px", "40px"]}
					>
						<Text aria-label='Save button'>{t("UsersSettings.save")}</Text>
					</Button>
				</Flex>
			</Box>
		</FormikForm>
	)
}

export default memo(UsersInGroupsForm)
