import { createAsyncThunk } from "@reduxjs/toolkit"

import { handleRequest } from "../../../../utils/api/api"

/**
 * Get materials groups function with dispatch action
 */
export const getMaterialsGroups = createAsyncThunk("materials/getMaterialsGroups", async () => {
	const responseData = await handleRequest({
		endPoint: "/api/orders/materialGroups",
	})
	return responseData
})

export const createMaterialsGroup = createAsyncThunk("materials/createMaterialGroup", async (values, lang) => {
	const responseData = await handleRequest({
		endPoint: "/api/orders/materialGroups",
		method: "POST",
		body: {
			...values,
			lang: lang,
		},
		config: {
			headers: {
				"content-type": "multipart/form-data",
			},
		},
	})
	return responseData
})

export const removeMaterialGroup = createAsyncThunk("materials/removeMaterialGroup", async (id) => {
	const responseData = await handleRequest({
		endPoint: `/api/orders/materialGroups/${id}`,
		method: "DELETE",
	})
	return responseData
})

export const updateMaterial = createAsyncThunk("materials/updateMaterialGroup", async (values, lang) => {
	const responseData = await handleRequest({
		endPoint: `/api/orders/materialGroups/${values.id}`,
		method: "POST",
		body: {
			...values,
			lang: lang,
		},
		config: {
			headers: {
				"content-type": "multipart/form-data",
			},
		},
	})
	return responseData
})

/**
 * Get material group by id function with dispatch action
 */
export const getMaterial = createAsyncThunk("materials/getMaterial", async (id, lang) => {
	const responseData = await handleRequest({
		endPoint: `/api/orders/materialGroups/${id}`,
		config: {
			headers: {
				"Material-Language": lang,
			},
		},
	})
	return responseData
})
