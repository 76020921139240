import { createAsyncThunk } from "@reduxjs/toolkit"
import { handleRequest } from "../../utils/api/api"

/**
 * Change language function
 */
export const changeLang = createAsyncThunk("changeLanguage", async (language) => {
	const responseData = await handleRequest({
		endPoint: "/api/changeLanguage",
		method: "POST",
		body: { language },
	})
	return responseData
})
