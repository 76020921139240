import { createIcon } from "@chakra-ui/react"

const Materials = createIcon({
	displayName: "Materials",
	viewBox: "0 0 24 24",
	path: (
		<>
			<rect width='24' height='24' fill='transparent' />
			<g transform='translate(-4.800000,31.000000) scale(0.032000,-0.041000)'>
				<path
					fill='currentColor'
					d='M545 638c-33-28-59-54-58-57 5-16 25-7 76 35l57 46 105-91c59-50 111-91 116-91 21 0 6 25-36 62-37 32-45 46-45 74 0 29-3 34-24 34-13 0-26-6-29-12-3-9-16-2-39 20-19 17-40 32-48 32-8-1-42-24-75-52z'
				/>
				<path
					fill='currentColor'
					d='M557 597c-26-23-47-50-47-59s-11-30-25-46c-25-30-25-31-5-37 43-14 46-85 4-85-11 0-13-6-9-20 3-10 1-28-5-40-10-19-8-20 39-20h50l3 83 3 82 48 3c68 4 77-6 77-95v-74l58 3 57 3 3 96 3 96-93 76c-51 43-97 77-103 77-5-1-31-20-58-43zM374 605c-4-8-1-22 6-30 10-12 2-14-52-17-55-3-63-5-63-23s8-20 63-23c54-3 62-5 52-17-15-18-6-45 14-45s74 47 81 71c5 16-3 31-31 60-40 41-61 48-70 24z'
				/>
				<path
					fill='currentColor'
					d='M135 602c-13-13-3-32 19-38 19-4 28-19 45-79 12-40 28-79 36-85 8-7 55-10 131-8 103 3 119 5 122 20s-9 18-114 20l-117 3-26 80c-24 74-28 80-58 86-18 3-35 4-38 1zM264 356c-12-31 0-51 31-51 27 0 30 3 30 30 0 25-4 30-28 33-17 2-29-2-33-12zM391 356c-21-26-9-51 24-51 27 0 30 3 30 30 0 23-5 31-21 33-11 2-26-4-33-12z'
				/>
			</g>
		</>
	),
})

export default Materials
