import { memo, useCallback } from "react"
import Select from "./Select"

const SelectWithFilteration = ({ array, setFilterValue, filterFields, searchingBy, handleSetResults, ...props }) => {
	const UpdateArray = (name, newValue) => {
		const fields = [...filterFields]
		fields.map((field) => {
			if (field.name === name) {
				field.value = newValue
			}
			return field
		})
		return fields
	}
	const handleSelect = ({ name }) => {
		setFilterValue(name)
		filterData(UpdateArray(props.name, name))
	}

	const handleClear = (name) => {
		setFilterValue("")
		filterData(UpdateArray(name, ""))
	}

	const filterData = useCallback(
		(fields) => {
			const filtered = array.filter((record) => {
				const requirementDependencies = fields.every((field) => {
					const fieldValue = record[field.name]?.toString().toLowerCase()
					return (
						typeof fieldValue && (field.value === "" || fieldValue === field.value.toString().toLowerCase())
					)
				})
				if (searchingBy) {
					const matchesSearching =
						searchingBy.value === "" ||
						record[searchingBy.name]
							.toString()
							.toLowerCase()
							.includes(searchingBy.value.toString().toLowerCase())
					return matchesSearching && requirementDependencies
				}
				return requirementDependencies
			})
			handleSetResults(filtered)
		},
		[array, filterFields],
	)

	return <Select handleClearInput={handleClear} onChange={handleSelect} {...props} />
}

export default memo(SelectWithFilteration)
