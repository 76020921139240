import { Navigate, Outlet } from "react-router-dom"

import { useSelector } from "react-redux"

const HasPermissionsRoutes = ({ allowedPermissions }) => {
	const permissions = useSelector((state) => state.auth.modules)

	return permissions?.find((permission) => allowedPermissions?.includes(permission.url)) ? (
		<Outlet />
	) : (
		<Navigate to='/' />
	)
}

export default HasPermissionsRoutes
