import { Box, Button, Container, Divider, Flex, Text, useDisclosure } from "@chakra-ui/react"
import { useLocation, useNavigate } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import Animation from "../../../../utils/animations/Routes/AnimatedPage"
import { AddIcon } from "@chakra-ui/icons"
import { getOrderList, removeOrderFromOrderList } from "../../../../actions/modules/orders/orderList/order-list-actions"
import { clearOrderList, sortOrderList } from "../../../../store/modules/orders/order-list/order-list-slice"
import RemoveAlert from "../../../../components/common/RemoveAlert"
import OrdersList from "../../../../components/Modules/Orders/OrderList/OrdersList"
import BackForwardButtons from "../../../../components/common/BackForwardButtons"
import SelectWithFilteration from "../../../../components/common/SelectWithFilteration"
import ModuleHeading from "../../../../components/common/ModuleHeading"
import LoadingHOC from "../../../../components/common/LoadingHOC"
import i18next from "i18next"
import StaticSearch from "../../../../components/common/StaticSearch"

const OrderList = () => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const [isProjectSortDesc, setIsProjectSortDesc] = useState(true)
	const [isCreatedSortDesc, setIsCreatedSortDesc] = useState(true)
	const [isPrioritySortDesc, setIsPrioritySortDesc] = useState(true)
	const [isNumberSortDesc, setIsNumberSortDesc] = useState(true)
	const [isDeliverySortDesc, setIsDeliverySortDesc] = useState(true)
	const [isStatusSortDesc, setIsStatusSortDesc] = useState(true)
	const [isDisabledForm, setIsDisabledForm] = useState(false)
	const orderList = useSelector((state) => state.orderList.orderList)
	const initialValues = useSelector((state) => state.orderList.initialValues)
	const hasDeleteAccess = useSelector((state) => state.auth.order_module_permissions)?.includes("delete_order")
	const [orderListLength, setOrderListLength] = useState()
	const [orders, setOrders] = useState()
	const [ordersCopy, setOrdersCopy] = useState()
	const [paginationLength, setPaginationLength] = useState(0)
	const [searchingTerm, setSearchingTerm] = useState("")
	const [itemIdToRemove, setItemIdToRemove] = useState()
	const [projectFilterValue, setProjectFilterValue] = useState("")
	const [createdFilterValue, setCreatedFilterValue] = useState("")
	const [priorityFilterValue, setPriorityFilterValue] = useState("")
	const [statusFilterValue, setStatusFilterValue] = useState("")
	const [isLoading, setIsLoading] = useState(true)
	const { isOpen, onOpen, onClose } = useDisclosure()

	const resetFields = useCallback(() => {
		setIsProjectSortDesc(true)
		setIsCreatedSortDesc(true)
		setIsPrioritySortDesc(true)
		setIsNumberSortDesc(true)
		setIsDeliverySortDesc(true)
		setIsStatusSortDesc(true)
		setOrders(null)
		setOrdersCopy(null)
		setPaginationLength(0)
		setSearchingTerm("")
		setProjectFilterValue("")
		setCreatedFilterValue("")
		setPriorityFilterValue("")
		setStatusFilterValue("")
		setOrderListLength()
		setIsLoading(true)
	}, [])

	useEffect(() => {
		resetFields()
		dispatch(getOrderList())
	}, [i18next.language])

	useEffect(() => {
		if (orderList && orderList.length !== orderListLength) {
			resetFields()
			setOrderListLength(orderList.length)
			setOrders(orderList.slice(0, 10))
			setOrdersCopy(orderList)
			setPaginationLength(orderList.length || 0)
			setIsLoading(false)
		}
	}, [orderList])

	useEffect(() => {
		return () => dispatch(clearOrderList())
	}, [dispatch])

	const handleChangePage = (page) => {
		const start = (page - 1) * 10
		const end = start + 10
		const newOrderList = ordersCopy.slice(start, end)
		setOrders(newOrderList)
	}

	const handleSettingSort = useCallback(
		(setSortDesc, isSortDesc, sortingItem) => {
			if (!orderList) return
			let sortedOrders
			let sortedOrdersList
			if (isSortDesc) {
				sortedOrders = [...ordersCopy].sort((a, b) => {
					if (a[sortingItem] > b[sortingItem]) return -1
					if (a[sortingItem] < b[sortingItem]) return 1
					return 0
				})
				sortedOrdersList = [...orderList].sort((a, b) => {
					if (a[sortingItem] > b[sortingItem]) return -1
					if (a[sortingItem] < b[sortingItem]) return 1
					return 0
				})
			} else {
				sortedOrders = [...ordersCopy].sort((a, b) => {
					if (a[sortingItem] < b[sortingItem]) return -1
					if (a[sortingItem] > b[sortingItem]) return 1
					return 0
				})
				sortedOrdersList = [...orderList].sort((a, b) => {
					if (a[sortingItem] < b[sortingItem]) return -1
					if (a[sortingItem] > b[sortingItem]) return 1
					return 0
				})
			}
			setOrdersCopy(sortedOrders)
			setOrders(sortedOrders.slice(0, 10))
			setSortDesc((prevState) => !prevState)
			dispatch(sortOrderList({ orderList: sortedOrdersList }))
		},
		[dispatch, orderList, ordersCopy],
	)

	const handleSortingByProject = () => {
		handleSettingSort(setIsProjectSortDesc, isProjectSortDesc, "project")
	}

	const handleSortingByCreated = useCallback(() => {
		if (!orderList) return
		let sortedOrders
		let sortedOrdersList

		const dateExtractor = (string) => {
			const parts = string.split(" ")
			const dateString = parts[0]
			const timeString = parts[1].slice(1, -1)
			return new Date(dateString + "T" + timeString)
		}

		if (isCreatedSortDesc) {
			sortedOrders = [...ordersCopy].sort((a, b) => dateExtractor(a.created) - dateExtractor(b.created))
			sortedOrdersList = [...orderList].sort((a, b) => dateExtractor(a.created) - dateExtractor(b.created))
		} else {
			sortedOrders = [...ordersCopy].sort((a, b) => dateExtractor(b.created) - dateExtractor(a.created))
			sortedOrdersList = [...orderList].sort((a, b) => dateExtractor(b.created) - dateExtractor(a.created))
		}
		setOrdersCopy(sortedOrders)
		setOrders(sortedOrders.slice(0, 10))
		setIsCreatedSortDesc((prevState) => !prevState)
		dispatch(sortOrderList({ orderList: sortedOrdersList }))
	}, [dispatch, isCreatedSortDesc, orderList, ordersCopy])

	const handleSortingByPriority = () => {
		handleSettingSort(setIsPrioritySortDesc, isPrioritySortDesc, "priority")
	}

	const handleSortingByNumber = () => {
		handleSettingSort(setIsNumberSortDesc, isNumberSortDesc, "number")
	}

	const handleSortingByDelivery = useCallback(() => {
		if (!orderList) return
		let sortedOrders
		let sortedOrdersList
		if (isDeliverySortDesc) {
			sortedOrders = [...ordersCopy].sort((a, b) => a.delivery_date.localeCompare(b.delivery_date))
			sortedOrdersList = [...orderList].sort((a, b) => a.delivery_date.localeCompare(b.delivery_date))
		} else {
			sortedOrders = [...ordersCopy].sort((a, b) => b.delivery_date.localeCompare(a.delivery_date))
			sortedOrdersList = [...orderList].sort((a, b) => b.delivery_date.localeCompare(a.delivery_date))
		}
		setOrdersCopy(sortedOrders)
		setOrders(sortedOrders.slice(0, 10))
		setIsDeliverySortDesc((prevState) => !prevState)
		dispatch(sortOrderList({ orderList: sortedOrdersList }))
	}, [dispatch, isDeliverySortDesc, orderList, ordersCopy])

	const handleSortingByStatus = () => {
		handleSettingSort(setIsStatusSortDesc, isStatusSortDesc, "status")
	}

	const handleOpenDialog = useCallback(
		(id) => {
			setItemIdToRemove(id)
			onOpen()
		},
		[onOpen],
	)

	const handleCloseDialog = useCallback(() => {
		setItemIdToRemove(null)
		onClose()
	}, [onClose])

	const handleRemoveOrder = () => {
		setIsDisabledForm(true)
		dispatch(removeOrderFromOrderList(itemIdToRemove)).then(() => {
			setIsDisabledForm(false)
			handleCloseDialog()
		})
	}

	const handleSetResults = (filteredArray) => {
		setOrders(filteredArray.slice(0, 10))
		setOrdersCopy(filteredArray)
		setPaginationLength(filteredArray.length)
	}

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Box
					display='flex'
					justifyContent='space-between'
					flexWrap={{ base: "wrap", xl: "nowrap" }}
					mt={2}
					gap={[0, 0, 0, 2, 0]}
				>
					<Box display='inline-flex' alignItems='flex-start' mb={[2, 2, 2, 2, 0]} w='auto'>
						<BackForwardButtons />
						<ModuleHeading heading={t("OrderList.heading")} />
					</Box>
					<Box
						display='inline-flex'
						w={"100%"}
						flexDirection={"row"}
						alignItems={{ base: "end", xl: "start" }}
						justifyContent={"end"}
						flexWrap={"wrap"}
						gap={5}
					>
						<Flex gap={2} w={["100%"]} justifyContent={"flex-end"} alignSelf={"flex-end"}>
							<StaticSearch
								fieldsToFilter={["number"]}
								isDisabled={!orderList?.length}
								searchText={searchingTerm}
								setSearchText={setSearchingTerm}
								handleSetResults={handleSetResults}
								originalArray={orderList}
								dependencies={[
									{ name: "project", value: projectFilterValue },
									{ name: "created", value: createdFilterValue },
									{ name: "priority", value: priorityFilterValue },
									{ name: "status", value: statusFilterValue },
								]}
							/>
							<Button
								aria-label='Add new order button'
								bgColor='#319795'
								color='#FFF'
								colorScheme='teal'
								minW='30px'
								boxSize={["30px", "35px", "40px"]}
								boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
								onClick={() => navigate(`/${location.pathname.split("/")[1]}/shop`)}
							>
								<AddIcon boxSize={5} />
							</Button>
						</Flex>
						<Flex alignItems={"center"} gap={2} h={["auto", "auto", "auto", "auto", "40px"]}>
							<Text
								color='#2C7A7B'
								whiteSpace={"pre-wrap"}
								wordBreak={"break-all"}
								fontSize={[13, 14, 15, 16]}
							>
								{t("OrderList.project")}
							</Text>
							<SelectWithFilteration
								name='project'
								isDisabled={!orderList?.length || !initialValues?.projects}
								value={projectFilterValue}
								setFilterValue={setProjectFilterValue}
								searchingBy={{ name: "number", value: searchingTerm }}
								filterFields={[
									{ name: "project", value: projectFilterValue },
									{ name: "created", value: createdFilterValue },
									{ name: "priority", value: priorityFilterValue },
									{ name: "status", value: statusFilterValue },
								]}
								values={initialValues?.projects}
								array={orderList}
								placeholder={t("Order.select")}
								handleSetResults={handleSetResults}
							/>
						</Flex>
						<Flex alignItems={"center"} gap={2} h={["auto", "auto", "auto", "auto", "40px"]}>
							<Text
								color='#2C7A7B'
								whiteSpace={"pre-wrap"}
								wordBreak={"break-all"}
								fontSize={[13, 14, 15, 16]}
							>
								{t("OrderList.createdBy")}
							</Text>
							<SelectWithFilteration
								name='created'
								isDisabled={!orderList?.length || !initialValues?.created_by}
								value={createdFilterValue}
								setFilterValue={setCreatedFilterValue}
								searchingBy={{ name: "number", value: searchingTerm }}
								filterFields={[
									{ name: "project", value: projectFilterValue },
									{ name: "created", value: createdFilterValue },
									{ name: "priority", value: priorityFilterValue },
									{ name: "status", value: statusFilterValue },
								]}
								values={initialValues?.created_by}
								array={orderList}
								placeholder={t("Order.select")}
								handleSetResults={handleSetResults}
							/>
						</Flex>
						<Flex alignItems={"center"} gap={2} h={["auto", "auto", "auto", "auto", "40px"]}>
							<Text
								color='#2C7A7B'
								whiteSpace={"pre-wrap"}
								wordBreak={"break-all"}
								fontSize={[13, 14, 15, 16]}
							>
								{t("OrderList.priority")}
							</Text>
							<SelectWithFilteration
								name='priority'
								isDisabled={!orderList?.length}
								value={priorityFilterValue}
								setFilterValue={setPriorityFilterValue}
								searchingBy={{ name: "number", value: searchingTerm }}
								filterFields={[
									{ name: "project", value: projectFilterValue },
									{ name: "created", value: createdFilterValue },
									{ name: "priority", value: priorityFilterValue },
									{ name: "status", value: statusFilterValue },
								]}
								values={[
									{ id: 1, name: t("OrderList.low") },
									{ id: 2, name: t("OrderList.standard") },
									{ id: 3, name: t("OrderList.height") },
								]}
								array={orderList}
								placeholder={t("Order.select")}
								handleSetResults={handleSetResults}
							/>
						</Flex>
						<Flex alignItems={"center"} gap={2} h={["auto", "auto", "auto", "auto", "40px"]}>
							<Text
								color='#2C7A7B'
								whiteSpace={"pre-wrap"}
								wordBreak={"break-all"}
								fontSize={[13, 14, 15, 16]}
							>
								{t("OrderList.status")}
							</Text>
							<SelectWithFilteration
								name='status'
								isDisabled={!orderList?.length}
								value={statusFilterValue}
								setFilterValue={setStatusFilterValue}
								searchingBy={{ name: "number", value: searchingTerm }}
								filterFields={[
									{ name: "project", value: projectFilterValue },
									{ name: "created", value: createdFilterValue },
									{ name: "priority", value: priorityFilterValue },
									{ name: "status", value: statusFilterValue },
								]}
								values={[
									{ id: 10, name: t("OrderList.inProgress") },
									{ id: 20, name: t("OrderList.created") },
									{ id: 30, name: t("OrderList.inPreparation") },
									{ id: 40, name: t("OrderList.readyToShip") },
								]}
								array={orderList}
								placeholder={t("Order.select")}
								handleSetResults={handleSetResults}
							/>
						</Flex>
					</Box>
				</Box>
				<LoadingHOC isLoading={!isLoading}>
					<Divider borderColor='#FFF' borderWidth={["1px", "2px"]} my={4} />
					{orders?.length ? (
						<>
							<OrdersList
								orders={orders}
								handleOpenDialog={handleOpenDialog}
								hasDeleteAccess={hasDeleteAccess}
								isDisabledForm={isDisabledForm}
								ordersCopy={ordersCopy}
								paginationLength={paginationLength}
								handleChangePage={handleChangePage}
								handleSortingByProject={handleSortingByProject}
								handleSortingByCreated={handleSortingByCreated}
								handleSortingByPriority={handleSortingByPriority}
								handleSortingByNumber={handleSortingByNumber}
								handleSortingByDelivery={handleSortingByDelivery}
								handleSortingByStatus={handleSortingByStatus}
								isProjectSortDesc={isProjectSortDesc}
								isCreatedSortDesc={isCreatedSortDesc}
								isPrioritySortDesc={isPrioritySortDesc}
								isNumberSortDesc={isNumberSortDesc}
								isDeliverySortDesc={isDeliverySortDesc}
								isStatusSortDesc={isStatusSortDesc}
							/>
							<RemoveAlert
								isOpen={isOpen}
								onClose={handleCloseDialog}
								handleConfirmRemove={handleRemoveOrder}
							/>
						</>
					) : (
						<Flex justifyContent='center' alignItems='center' h='80%'>
							<Text fontSize='24px' letterSpacing='4px' fontWeight='600' color='#05050580'>
								{t("History.noData")}
							</Text>
						</Flex>
					)}
				</LoadingHOC>
			</Container>
		</Animation>
	)
}

export default OrderList
