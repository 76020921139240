import { Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useLocation, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"

import Animation from "../../../../../../utils/animations/Routes/AnimatedPage"
import TakeItPanel from "../../../../../../components/Modules/Warehouse/Warehouses/WarehouseTake/TakeItPanel"
import LoadingHOC from "../../../../../../components/common/LoadingHOC"
import { getWarehouseDropdowns } from "../../../../../../actions/modules/warehouse/warehouses/warehouses-actions"

const WarehouseTakePage = () => {
	const { t } = useTranslation("global")
	const location = useLocation()
	const dispatch = useDispatch()
	const [dropdownsData, setDropdownsData] = useState()
	const [isLoading, setIsLoading] = useState(true)
	const { warehouseId } = useParams()

	useEffect(() => {
		dispatch(getWarehouseDropdowns(warehouseId)).then((res) => {
			setDropdownsData(res.payload)
			setIsLoading(false)
		})
	}, [])

	const getCurrentHeader = () => {
		switch (!!location?.state) {
			case true:
				return t("Warehouses.editWarehouseDocument")

			case false:
				return t("Warehouses.takeFromWarehouse")

			default:
				return t("Warehouses.takeFromWarehouse")
		}
	}

	return (
		<Animation styles={{ display: "flex", flexDirection: "column", height: "80%" }}>
			<Text>{getCurrentHeader()}</Text>
			<LoadingHOC isLoading={!isLoading}>
				<TakeItPanel dropdownsData={dropdownsData} />
			</LoadingHOC>
		</Animation>
	)
}

export default WarehouseTakePage
