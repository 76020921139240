import { createIcon } from "@chakra-ui/react"

const LanguageIcon = createIcon({
	displayName: "LanguageIcon",
	viewBox: "0 0 22 22",
	path: (
		<>
			<path
				fill='currentColor'
				d='M8.546 3.2c.338 0 .613.269.613.6V5H14.068c.34 0 .614.269.614.6 0 .332-.275.6-.614.6h-1.637l-.057.176a20.53 20.53 0 01-.58 1.548c-.523 1.243-1.34 2.849-2.505 4.243a21.046 21.046 0 01-3.21 3.095 17.782 17.782 0 01-1.397.982l-.086.053-.024.014-.006.004-.003.001-.313-.516.313.517a.622.622 0 01-.841-.212.593.593 0 01.216-.821l.003-.002.016-.01a10.4 10.4 0 00.349-.221c.243-.16.592-.404 1.01-.728a19.828 19.828 0 003.023-2.915c1.06-1.268 1.821-2.756 2.32-3.94.207-.493.367-.928.483-1.268h-8.12a.607.607 0 01-.613-.6c0-.331.275-.6.614-.6h4.909V3.8c0-.331.275-.6.614-.6zm6.75 6c.245 0 .467.143.564.364l3.681 8.4a.595.595 0 01-.322.788.619.619 0 01-.806-.316l-.892-2.036h-4.45l-.893 2.037a.619.619 0 01-.806.315.595.595 0 01-.322-.788l3.681-8.4a.614.614 0 01.565-.364zm-1.7 6h3.399l-1.7-3.877-1.699 3.877z'
			/>
			<path
				fill='currentColor'
				d='M6.637 7.726a.62.62 0 00-.826-.26.594.594 0 00-.266.808L6.091 8l-.546.274.001.002.002.004.008.015a6.639 6.639 0 00.135.243c.095.165.234.401.419.695.369.587.918 1.401 1.643 2.32a19.287 19.287 0 001.975 2.15 15.202 15.202 0 00.876.756l.014.011.005.003.001.001c.001.001.001.001.376-.474l-.375.475a.623.623 0 00.86-.108.591.591 0 00-.11-.842l-.002-.001-.008-.007a14.042 14.042 0 01-.79-.683 18.09 18.09 0 01-1.85-2.013 25.8 25.8 0 01-1.57-2.216 18.41 18.41 0 01-.512-.868l-.005-.01-.001-.001z'
			/>
		</>
	),
})

export default LanguageIcon
