import { Box, Button, Container, Divider, Flex } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AddIcon } from "@chakra-ui/icons"
import { useDispatch, useSelector } from "react-redux"

import MagazineCard from "../../../../components/Modules/Warehouse/Warehouses/MagazineCard"
import Animation from "../../../../utils/animations/Routes/AnimatedPage"
import BackForwardButtons from "../../../../components/common/BackForwardButtons"
import ModuleHeading from "../../../../components/common/ModuleHeading"
import StaticSearch from "../../../../components/common/StaticSearch"
import BsSortDown from "../../../../assets/icons/permissions/BsSortDown"
import BsSortUp from "../../../../assets/icons/permissions/BsSortUp"
import { getWarehouses } from "../../../../actions/modules/warehouse/warehouses/warehouses-actions"
import LoadingHOC from "../../../../components/common/LoadingHOC"
import { clearWarehouses } from "../../../../store/modules/warehouse/warehouses/warehouses-slice"
import NoData from "../../../../components/common/NoData"
import PermissionsChecked from "../../../../components/common/PermissionsChecked"

const Warehouses = () => {
	const { t, i18n } = useTranslation("global")
	const dispatch = useDispatch()
	const warehouses = useSelector((state) => state.warehouses.warehouses)
	const navigate = useNavigate()
	const [isDisabled] = useState(false)
	const [isSortDesc, setIsSortDesc] = useState(false)
	const [searchingTerm, setSearchingTerm] = useState("")
	const [isLoading, setIsLoading] = useState(true)
	const [filteredWarehouses, setFilteredWarehouses] = useState([])

	useEffect(() => {
		setIsLoading(true)
	}, [i18n.language])

	useEffect(() => {
		if (!isLoading) return
		dispatch(getWarehouses()).then(() => {
			setIsLoading(false)
		})
	}, [isLoading])

	useEffect(() => {
		return () => dispatch(clearWarehouses())
	}, [])

	useEffect(() => {
		setFilteredWarehouses(warehouses)
	}, [warehouses])

	const handleSearching = (array) => {
		setFilteredWarehouses(array)
	}

	const handleSorting = () => {
		const sortedRecords = [...filteredWarehouses].sort((a, b) =>
			isSortDesc
				? a.name.toString().localeCompare(b.name.toString())
				: b.name.toString().localeCompare(a.name.toString()),
		)
		setFilteredWarehouses(sortedRecords)
		setIsSortDesc((prevState) => !prevState)
	}

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Box
					display='flex'
					justifyContent='space-between'
					flexWrap={{ base: "wrap", md: "nowrap" }}
					mt={2}
					gap={{ md: 2 }}
				>
					<Box display='inline-flex' alignItems='flex-start' mb={[2, 2, 2, 0]}>
						<Flex alignItems={"center"}>
							<BackForwardButtons />
							<ModuleHeading heading={t("Warehouses.heading")} />
						</Flex>
					</Box>
					<Box
						display='inline-flex'
						w='100%'
						justifyContent={"end"}
						h={["35px", "auto"]}
						alignItems='center'
						gap={2}
					>
						<StaticSearch
							fieldsToFilter={["name"]}
							isDisabled={isDisabled}
							searchText={searchingTerm}
							setSearchText={setSearchingTerm}
							handleSetResults={handleSearching}
							originalArray={warehouses}
						/>
						<Button
							isDisabled={isDisabled || !warehouses?.length}
							my='auto'
							aria-label='sort-button'
							bgColor='#fff'
							minW='30px'
							p={1}
							boxSize={["30px", "35px", "35px", "40px"]}
							boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
							onClick={handleSorting}
						>
							<Flex justifyContent='center'>
								{isSortDesc ? <BsSortDown boxSize={5} /> : <BsSortUp boxSize={5} />}
							</Flex>
						</Button>
						<PermissionsChecked permissions={"warehouse"} allowedPermissions={["add_warehouse"]}>
							<Button
								aria-label='Add new warehouse button'
								bgColor='#319795'
								color='#FFF'
								colorScheme='teal'
								minW='30px'
								p={1}
								boxSize={["30px", "35px", "35px", "40px"]}
								boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
								onClick={() => navigate(`/warehouse/warehouses/add`)}
							>
								<AddIcon boxSize={[4, 4, 5]} />
							</Button>
						</PermissionsChecked>
					</Box>
				</Box>
				<Divider borderColor='#FFF' borderWidth={["1px", "2px"]} my={4} />
				<LoadingHOC isLoading={!isLoading}>
					{warehouses?.length ? (
						<Flex flexWrap={"wrap"} gap={5} m={3}>
							{filteredWarehouses?.map((magazine) => (
								<MagazineCard key={magazine.id} magazine={magazine} />
							))}
						</Flex>
					) : (
						<NoData />
					)}
				</LoadingHOC>
			</Container>
		</Animation>
	)
}

export default Warehouses
