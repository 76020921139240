import { Icon } from "@chakra-ui/react"

const Package = (props) => (
	<Icon viewBox='0 0 23 23' {...props}>
		<path
			fill='currentColor'
			d='M11.96 3.671a.56.56 0 00-.42 0L4.828 6.37 7.53 7.456l6.923-2.782-2.495-1.003zm4.009 1.612L9.046 8.065l2.704 1.087 6.923-2.782-2.704-1.087zm3.656 1.922l-7.313 2.94v8.954l7.313-2.939V7.206zm-8.438 11.896v-8.958L3.875 7.205v8.957l7.313 2.939zm-.064-16.48a1.68 1.68 0 011.254 0l8.02 3.224a.563.563 0 01.353.525v9.791a1.134 1.134 0 01-.708 1.05l-8.083 3.248a.56.56 0 01-.418 0l-8.082-3.248a1.126 1.126 0 01-.709-1.05V6.37a.567.567 0 01.353-.525l8.02-3.224z'
		/>
	</Icon>
)

export default Package
