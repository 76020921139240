import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
} from "@chakra-ui/react"
import { Field, useField } from "formik"
import React, { useState } from "react"

import IoEyeOutlineInvisible from "../../assets/icons/login/IoEyeOutlineInvisible"
import IoEyeOutline from "../../assets/icons/login/IoEyeOutline"

const PasswordField = ({ label, ...props }) => {
	const [field, meta] = useField(props)
	const [showPassword, setShowPassword] = useState(false)

	const fieldProps = {
		...field,
		...props,
	}

	const handleShowHidePassword = () => {
		if (props.disabled) return
		setShowPassword(!showPassword)
	}

	return (
		<FormControl mb={["1", "1", "1", "2"]} isInvalid={meta.error && meta.touched}>
			<FormLabel my='0'>{label}</FormLabel>
			<InputGroup w={props.w || props.width}>
				<Field
					as={Input}
					my='0'
					autoComplete='on'
					{...fieldProps}
					type={showPassword ? "text" : "password"}
					pe={12}
				/>
				<InputRightElement h='100%' alignContent='center' flexWrap='wrap'>
					<Button
						type='button'
						aria-label='Show/Hide button'
						h={props.h}
						colorScheme='transparent'
						isDisabled={props.disabled}
						bgColor={"transparent"}
						onClick={handleShowHidePassword}
					>
						{showPassword ? (
							<IoEyeOutlineInvisible boxSize={[3, 4, 5]} />
						) : (
							<IoEyeOutline boxSize={[3, 4, 5]} />
						)}
					</Button>
				</InputRightElement>
			</InputGroup>
			<FormErrorMessage my='0'>{meta.error}</FormErrorMessage>
		</FormControl>
	)
}

export default PasswordField
