import { createIcon } from "@chakra-ui/react"

const BookmarkIcon = createIcon({
	displayName: "Icons",
	viewBox: "0 0 22 22",
	path: (
		<g>
			<path
				d='M16 2H6c-1.1 0-1.99.9-1.99 2L4 20l7-3 7 3V4c0-1.1-.9-2-2-2z'
				stroke='black'
				strokeWidth='0.2'
				fill='currentColor'
			/>
		</g>
	),
})

export default BookmarkIcon
