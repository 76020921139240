import { createIcon } from "@chakra-ui/react"

const BackIcon = createIcon({
	displayName: "BackIcon",
	viewBox: "0 0 49 49",
	path: (
		<g>
			<path
				d='M44.1358 24.5H7.04492'
				stroke='#000'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M7.04492 24.5L20.954 9.22727'
				stroke='#000'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M7.04492 24.5L20.954 39.7727'
				stroke='#000'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</g>
	),
})

export default BackIcon
