import { Box, Container, Flex, Heading } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import BackForwardButtons from "../../../../../components/common/BackForwardButtons"
import NewWarehouseAddForm from "../../../../../components/Modules/Warehouse/Warehouses/Add/NewWarehouseAddForm"

const AddNewWarehouse = () => {
	const { t } = useTranslation("global")
	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Box display='inline-flex' alignItems='flex-start' mb={[2, 2, 2, 0]}>
					<Flex alignItems={"center"}>
						<BackForwardButtons />
						<Heading fontSize={["15", "16", "17", "18", "19"]} ms={2}>
							{t("Warehouses.addNewWarehouse")}
						</Heading>
					</Flex>
				</Box>
				<NewWarehouseAddForm />
			</Container>
		</Animation>
	)
}

export default AddNewWarehouse
