/* eslint-disable no-unused-vars */
import { createAsyncThunk } from "@reduxjs/toolkit"

import { handleRequest } from "../../utils/api/api"

/**
 * Get permissions function
 */
export const getPermissions = createAsyncThunk("permissions/getPermissions", async () => {
	const responseData = await handleRequest({
		endPoint: "/api/permissions",
	})
	return responseData
})

/**
 * Setting User Functionalitie
 */
export const setUserFunctionalitie = createAsyncThunk(
	"permissions/setUserFunctionalitie",
	async ({ userId, moduleFunctionalitieId, isSet, roleId, moduleId }) => {
		const responseData = await handleRequest({
			endPoint: "/api/userPermission",
			method: "POST",
			body: {
				user_id: userId,
				module_functionality_id: moduleFunctionalitieId,
				is_set: isSet,
			},
		})
		return responseData
	},
)

/**
 * Setting Role Functionalitie
 */
export const setRoleFunctionalitie = createAsyncThunk(
	"permissions/setRoleFunctionalitie",
	async ({ roleId, functionalityId, state, stateId }) => {
		const responseData = await handleRequest({
			endPoint: "/api/rolePermission",
			method: "POST",
			body: {
				role_id: roleId,
				module_functionality_id: functionalityId,
				state,
			},
		})
		return responseData
	},
)

/**
 * Setting All Users Functionalitie
 */
export const setAllUsersFunctionalitie = createAsyncThunk(
	"permissions/setAllUsersFunctionalitie",
	async ({ functionalityId, state }) => {
		const responseData = await handleRequest({
			endPoint: "/api/allUserPermission",
			method: "POST",
			body: {
				module_functionality_id: functionalityId,
				state,
			},
		})
		return responseData
	},
)

/**
 * Get available modules function
 */
export const getAvailableModules = createAsyncThunk("availablePermission/getAvailableModules", async () => {
	const responseData = await handleRequest({
		endPoint: "/api/appPermissions",
	})
	return responseData
})

/**
 * Setting Application Functionalitie
 */
export const setApplicationFunctionalitie = createAsyncThunk(
	"availablePermission/setApplicationFunctionalitie",
	async ({ moduleId, moduleFunctionalityId, state }) => {
		const responseData = await handleRequest({
			endPoint: "/api/moduleFunctionalityAppPermissions",
			method: "POST",
			body: {
				module_id: moduleId,
				module_functionality_id: moduleFunctionalityId,
				visibility_state: state,
			},
		})
		return responseData
	},
)

/**
 * Setting application availabel modules
 */
export const setApplicationModules = createAsyncThunk(
	"availablePermission/setApplicationModules",
	async ({ moduleId, state }) => {
		const responseData = await handleRequest({
			endPoint: "/api/moduleAppPermissions",
			method: "POST",
			body: {
				module_id: moduleId,
				visibility_state: state,
			},
		})
		return responseData
	},
)
