import { ChevronDownIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, FormControl, FormErrorMessage, Input, Text } from "@chakra-ui/react"
import { useField, useFormikContext } from "formik"
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

const OrderSettingsSelect = ({ values, allValues, isDisabled, onChange, iconStyles, ...props }) => {
	const { t } = useTranslation("global")
	const [field, meta] = useField(props)
	const [options, setOptions] = useState(values)
	const optionsRef = useRef(null)
	const chevronRef = useRef(null)
	const searchInputRef = useRef(null)
	const [searchText, setSearchText] = useState("")
	const [searchValue, setSearchValue] = useState("")
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const { setFieldValue } = useFormikContext()

	useEffect(() => {
		if (options !== values && searchValue === "") {
			setOptions(values)
		}
	}, [options, values])

	useEffect(() => {
		const name = allValues.filter((value) => value.id === field.value.user_id)[0]?.name
		if (name && name !== searchText) {
			setSearchText(name)
		}
		if (field.value === "") {
			setSearchText("")
		}
	}, [field.value])

	useEffect(() => {
		if (searchText === "" && field.value) {
			const name = allValues.filter((value) => value.id === field.value.user_id)
			setSearchText(name[0]?.name)
		}
	}, [field.value, searchText, allValues])

	const handleOutsideClick = useCallback((event) => {
		if (
			optionsRef.current &&
			!optionsRef.current.contains(event.target) &&
			!event.target.closest(".select-button")
		) {
			setIsMenuOpen(false)
		}
	}, [])

	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClick)
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick)
		}
	}, [handleOutsideClick])

	const clearInput = useCallback(() => {
		setSearchValue("")
		setOptions(values)
		if (searchValue === "") return
	}, [searchValue, values])

	const openMenu = () => {
		clearInput()
		setIsMenuOpen(true)
	}

	const focusSearchInput = () => {
		searchInputRef.current.focus()
	}

	const handleOpenSelect = (e) => {
		e.stopPropagation()
		if (isMenuOpen) {
			setIsMenuOpen(false)
		} else {
			openMenu()
			requestAnimationFrame(focusSearchInput)
		}
	}

	const handleSelect = useCallback(
		(option) => {
			setFieldValue(field.name, option.id)
			setSearchText(option.name)
			setSearchValue("")
			setIsMenuOpen(false)
			if (onChange) onChange(option.id)
		},
		[field.name, onChange, setFieldValue],
	)

	const handleSearching = useCallback(
		(event) => {
			setIsMenuOpen(true)
			setSearchValue(event.target.value)
			if (event.target.value === "") return setOptions(values)
			const filteredOptions = values.filter((option) =>
				option.name.toString().toLowerCase().includes(event.target.value.toLowerCase()),
			)
			setOptions(filteredOptions)
		},
		[values],
	)

	const handleEnterPress = (e) => {
		if (e.key !== "Enter") return

		e.preventDefault()
	}

	const getOptions = useMemo(() => {
		const searchOption = (
			<Input
				key='search'
				ref={searchInputRef}
				position='relative'
				autoComplete='off'
				value={searchValue}
				w='100%'
				h={["30px", "35px", "40px"]}
				fontSize={[13, 14, 15, 16]}
				placeholder={t("Order.search")}
				onKeyDown={handleEnterPress}
				onChange={handleSearching}
			/>
		)

		const optionsList = options?.map((option) => (
			<Button
				key={option.id}
				position='relative'
				zIndex={10}
				display='flex'
				className='select-button'
				p={0}
				justifyContent='flex-start'
				fontSize={[13, 14, 15, 16]}
				w='100%'
				h={["30px", "35px", "40px"]}
				bgColor={option.name === field.value && option.name !== "" ? "#F2F2EF" : "#FFF"}
				_hover={{ backgroundColor: "rgba(0,0,0,0.03)" }}
				onMouseDown={() => handleSelect(option)}
			>
				<Box my={2} mx={3}>
					{<Text mx={2}>{option.name}</Text>}
				</Box>
			</Button>
		))

		return [searchOption, ...optionsList]
	}, [searchValue, t, handleSearching, options, field.value, handleSelect])

	return (
		<FormControl {...props} isInvalid={meta.error && meta.touched}>
			<Flex h={["35px", "35px", "42px", "45px"]} position='relative' flexDirection='column'>
				<Button
					isDisabled={isDisabled}
					onMouseDown={handleOpenSelect}
					border='1px solid #e2e8f0'
					borderRadius='8px'
					bgColor={"#FFF"}
					position='relative'
					zIndex={1}
				>
					<Text alignItems={"center"} fontSize={[13, 14, 15, 16]} w='100%'>
						{searchText || props.placeholder}
					</Text>
					<Flex
						w={["12%", "10%", "6%", "5%", "3%"]}
						h={["35px", "35px", "42px", "45px"]}
						cursor='pointer'
						ref={chevronRef}
						zIndex={1}
						position='absolute'
						right={1}
						top={["0", "-1px", "-1px", "-3px"]}
						justifyContent='center'
						alignItems='center'
						style={iconStyles}
					>
						<ChevronDownIcon h={["35px", "35px", "42px", "45px"]} boxSize={[5, 5, 6]} />
					</Flex>
				</Button>
				<Box
					display={isMenuOpen ? "block" : "none"}
					ref={optionsRef}
					bgColor='#FFF'
					borderRadius='6px'
					position='absolute'
					border='1px solid #D4D4CD'
					top={["35px", "35px", "40px", "39px"]}
					w='100%'
					h={options?.length > 5 ? ["150px", "150px", "200px", "250px", "300px"] : "auto"}
					overflow={"auto"}
					zIndex={2}
				>
					<Box w='100%'>{getOptions}</Box>
				</Box>
			</Flex>
			<FormErrorMessage fontSize='15px' my='0'>
				{meta.error}
			</FormErrorMessage>
		</FormControl>
	)
}

export default memo(OrderSettingsSelect)
