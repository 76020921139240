import { Box } from "@chakra-ui/react"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import i18next from "i18next"

import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import GroupsList from "../../../../../components/Modules/Warehouse/Warehouses/Warehouse/GroupsList"
import ActionsBar from "../../../../../components/Modules/Warehouse/Warehouses/Warehouse/ActionsBar"
import { getMaterialGroups } from "../../../../../actions/modules/warehouse/warehouses/warehouses-actions"
import { clearWarehouseGroupMaterials } from "../../../../../store/modules/warehouse/warehouses/warehouses-slice"
import LoadingHOC from "../../../../../components/common/LoadingHOC"
import NoData from "../../../../../components/common/NoData"
import { useParams } from "react-router-dom"
import SummaryBar from "../../../../../components/Modules/Warehouse/Warehouses/Warehouse/SummaryBar"

const WarehouseManage = () => {
	const dispatch = useDispatch()
	const groupsList = useSelector((state) => state.warehouses.warehouseGroupMaterials)
	const { warehouseId } = useParams()
	const [groups, setGroups] = useState()
	const [groupsCopy, setGroupsCopy] = useState()
	const [recordsPerPage, setRecordsPerPage] = useState(5)
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		if (!groupsCopy) return
		setData(groupsCopy)
	}, [recordsPerPage])

	useEffect(() => {
		setIsLoading(true)
		dispatch(getMaterialGroups(warehouseId)).then((data) => {
			setData(data.payload.materialGroups, true)
			setIsLoading(false)
		})
		return () => dispatch(clearWarehouseGroupMaterials())
	}, [i18next.language])
	const getMaxLength = () => {
		if (!groupsCopy) return

		return getConcatenatedMaterials(groupsCopy).length
	}

	const getConcatenatedMaterials = (array) => {
		if (!array) return

		let materials = []
		array.forEach((group) => group.materials.forEach((material) => materials.push(material)))
		return materials
	}

	const getGroupsFromConcatenatedMaterial = useCallback(
		(array, init, initData) => {
			if (!array) return

			let prevGroupId
			let groups = []
			array.forEach((item) => {
				if (prevGroupId === item.group_id) {
					groups[groups.length - 1].materials = [...groups[groups.length - 1].materials, item]
					prevGroupId = item.group_id
					return
				}

				prevGroupId = item.group_id
				const group = init
					? { ...initData?.find((group) => group.id === item.group_id) }
					: { ...groupsList?.find((group) => group.id === item.group_id) }
				group.materials = [item]
				groups.push(group)
			})
			return groups
		},
		[groupsList],
	)

	const setData = (initData, init) => {
		const materials = getConcatenatedMaterials(initData).slice(0, recordsPerPage)
		const groups = getGroupsFromConcatenatedMaterial(materials, init, initData)
		setGroups(groups)
		setGroupsCopy(initData)
	}

	return (
		<Animation styles={{ display: "flex", flexDirection: "column", height: "80%" }}>
			<ActionsBar
				data={groups}
				groupsList={groupsList}
				setData={setData}
				setRecordsPerPage={setRecordsPerPage}
				getConcatenatedMaterials={getConcatenatedMaterials}
				getGroupsFromConcatenatedMaterial={getGroupsFromConcatenatedMaterial}
				maxLength={getMaxLength()}
			/>
			<LoadingHOC isLoading={!isLoading}>
				<SummaryBar mt={20} totalRecords={groupsList} />
				<Box my={10} pb={5}>
					{groups?.length ? (
						<GroupsList
							groupsList={groupsList}
							groups={groups}
							setGroups={setGroups}
							groupsCopy={groupsCopy}
							setGroupsCopy={setGroupsCopy}
							recordsPerPage={recordsPerPage}
							getConcatenatedMaterials={getConcatenatedMaterials}
							getGroupsFromConcatenatedMaterial={getGroupsFromConcatenatedMaterial}
						/>
					) : (
						<NoData />
					)}
				</Box>
			</LoadingHOC>
		</Animation>
	)
}

export default WarehouseManage
