import { Box, Container, Heading, Switch, Text } from "@chakra-ui/react"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import i18next from "i18next"

import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import MaterialGroupDetailsForm from "../../../../../components/Modules/Orders/Materials/MaterialGroupDetailsForm/MaterialGroupDetailsForm"
import { getMaterial } from "../../../../../actions/modules/orders/materials/materials-actions"
import { clearOpenMaterial } from "../../../../../store/modules/orders/materials/materials-slice"
import BackForwardButtons from "../../../../../components/common/BackForwardButtons"
import LoadingHOC from "../../../../../components/common/LoadingHOC"

const MaterialDetails = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const { t } = useTranslation("global")
	const [language, setLanguage] = useState(i18next.language)
	const [isEditPreview, setIsEditPreview] = useState(location.state?.isEditPreview)
	const openMaterial = useSelector((state) => state.materials.openMaterial)
	const [material, setMaterial] = useState(null)
	const [isGetted, setIsGetted] = useState(false)

	useEffect(() => {
		if (openMaterial) {
			const materialCopy = {
				id: openMaterial.id,
				name_pl: openMaterial.name_pl || "",
				name_en: openMaterial.name_en || "",
				name_nl: openMaterial.name_nl || "",
				description_pl: openMaterial.description_pl || "",
				description_en: openMaterial.description_en || "",
				description_nl: openMaterial.description_nl || "",
				parameters: openMaterial.parameters.map((parameter) => ({
					group_id: parameter.group_id,
					id: parameter.id,
					name_pl: parameter.name_pl || "",
					name_en: parameter.name_en || "",
					name_nl: parameter.name_nl || "",
					icon: parameter.icon,
					is_used: parameter.is_used,
					values: parameter.values.map((value) => ({
						id: value.id,
						name_pl: value.name_pl || "",
						name_en: value.name_en || "",
						name_nl: value.name_nl || "",
						is_used: value.is_used,
						group_parameter_id: value.group_parameter_id,
					})),
				})),
				image: openMaterial.image,
				index: openMaterial.index,
				is_out_of_warehouse: openMaterial.is_out_of_warehouse,
				materials: openMaterial.materials.map((material) => ({
					description_pl: material.description_pl || "",
					description_en: material.description_en || "",
					description_nl: material.description_nl || "",
					id: material.id,
					image: material.image,
					group_id: material.group_id,
					index: material.index,
					is_in_warehouse: material.is_in_warehouse,
					is_used: material.is_used,
					name_pl: material.name_pl || "",
					name_en: material.name_en || "",
					name_nl: material.name_nl || "",
					parameter_counter: material.parameter_counter.map((counter) => ({
						id: counter.id,
						name_pl: counter.name_pl || "",
						name_en: counter.name_en || "",
						name_nl: counter.name_nl || "",
						count: counter.count,
						icon: counter.icon,
					})),
				})),
				newParameter: {
					name_pl: "",
					name_en: "",
					name_nl: "",
					icon: null,
					values: [
						{
							name_pl: "",
							name_en: "",
							name_nl: "",
						},
					],
				},
			}
			setMaterial(materialCopy)
			setIsGetted(true)
		}
	}, [openMaterial])

	useEffect(() => {
		setLanguage(i18next.language)
	}, [i18next.language])

	useEffect(() => {
		dispatch(getMaterial(location.state?.materialId || Number(location.pathname.split("/")[3]), language))

		return () => dispatch(clearOpenMaterial())
	}, [dispatch, language, location.pathname, location.state?.materialId])

	const handleChangeMode = () => {
		setIsEditPreview((prevState) => !prevState)
	}

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Box display='flex' justifyContent='space-between' flexWrap='wrap' mt={2}>
					<Box display='inline-flex' alignItems='center' mb={[2, 2, 2, 0]}>
						<BackForwardButtons />
						<Heading fontSize={["15", "16", "17", "18", "19"]} ms={2}>
							{location.state?.materialName}
						</Heading>
					</Box>
					<Box display={"flex"} h={["30px", "35px", "auto"]} w={["100%", "auto"]} justifyContent={"end"}>
						<Box
							display='flex'
							alignItems='center'
							bgColor='#FFF'
							ms={[2, 2, 3, 4, 5]}
							borderRadius='4px'
							boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
						>
							<Text mx={3} fontSize={[13, 14, 15, 16]}>
								{t("Materials.editDataMode", { lng: language })}
							</Text>
							<Switch
								aria-label='Edit/Preview switch'
								size={["sm", "sm", "md"]}
								me={3}
								colorScheme='green'
								isChecked={isEditPreview}
								onChange={handleChangeMode}
							/>
						</Box>
					</Box>
				</Box>
				<LoadingHOC isLoading={isGetted}>
					<MaterialGroupDetailsForm
						isEditPreview={isEditPreview}
						initialTab={location.state?.initialTab || 0}
						material={material}
						language={language}
					/>
				</LoadingHOC>
			</Container>
		</Animation>
	)
}

export default MaterialDetails
