import "@fontsource/inter"

import { ChakraProvider } from "@chakra-ui/react"
import i18next from "i18next"
import { Provider } from "react-redux"
import React from "react"
import ReactDOM from "react-dom/client"
import { I18nextProvider } from "react-i18next"
import { ToastContainer } from "react-toastify"

import App from "./App"
import global_en from "./translations/en/global"
import global_pl from "./translations/pl/global"
import global_nl from "./translations/nl/global"
import store from "./store/store"
import theme from "./theme"

import "react-toastify/dist/ReactToastify.css"

i18next.init({
	interpolation: { escapeValue: false },
	lng: localStorage.getItem("language"),
	fallbackLng: "en",
	store: ["pl", "en", "nl"],
	resources: {
		pl: {
			global: global_pl,
		},
		en: {
			global: global_en,
		},
		nl: {
			global: global_nl,
		},
	},
})

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<ChakraProvider theme={theme}>
		<I18nextProvider i18n={i18next}>
			<Provider store={store}>
				<App />
				<ToastContainer />
			</Provider>
		</I18nextProvider>
	</ChakraProvider>,
)
