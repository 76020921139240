import React, { useState, useEffect } from "react"
import { Flex, Button, Text, Input } from "@chakra-ui/react"
import { ArrowLeftIcon, ChevronLeftIcon, ArrowRightIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { useTranslation } from "react-i18next"

const Pagination = ({ style, array, totalRecords, onPageChange, recordsPerPage = 10 }) => {
	const { t } = useTranslation("global")
	const [currentPage, setCurrentPage] = useState(1)
	const [currentPageValue, setCurrentPageValue] = useState(1)

	const totalPages = Math.ceil(totalRecords / recordsPerPage)

	useEffect(() => {
		setCurrentPage(1)
		setCurrentPageValue(1)
	}, [array])

	const handleChangeInputValue = (page) => {
		setCurrentPageValue(page)
		handlePageChange(page)
	}

	const handlePageChange = (page) => {
		if (page === 0 || page > totalPages) return
		setCurrentPage(page)
		setCurrentPageValue(page)
		onPageChange(page)
	}

	const renderPageNumbers = () => {
		const pages = []
		const visiblePageCount = 5

		if (totalPages <= visiblePageCount) {
			for (let i = 1; i <= totalPages; i++) {
				pages.push(
					<Button
						key={i}
						p={0}
						minW='25px'
						w={["25px", "30px", "40px"]}
						h={["25px", "30px", "40px"]}
						bgColor={currentPage === i ? "teal.500" : "transparent"}
						color={currentPage === i ? "white" : "black"}
						borderRadius='50%'
						_hover={{
							bgColor: currentPage === i ? "teal.600" : "#FFF",
						}}
						onClick={() => handlePageChange(i)}
					>
						{i}
					</Button>,
				)
			}
		} else {
			let leftBoundary = Math.max(1, currentPage - Math.floor(visiblePageCount / 2))
			let rightBoundary = leftBoundary + visiblePageCount - 1

			if (leftBoundary <= 2) {
				leftBoundary = 1
				rightBoundary = visiblePageCount + 1
			} else if (rightBoundary >= totalPages - 1) {
				leftBoundary = totalPages - visiblePageCount
				rightBoundary = totalPages
			}

			if (leftBoundary > 1) {
				pages.push(
					<Button
						key={1}
						p={0}
						minW='25px'
						w={["25px", "30px", "40px"]}
						h={["25px", "30px", "40px"]}
						bgColor={currentPage === 1 ? "teal.500" : "transparent"}
						color={currentPage === 1 ? "white" : "black"}
						_hover={{
							bgColor: currentPage === 1 ? "teal.600" : "#FFF",
						}}
						borderRadius='50%'
						onClick={() => handlePageChange(1)}
					>
						1
					</Button>,
				)
				if (leftBoundary > 1) {
					pages.push(
						<Text
							key='ellipsis-start'
							display='flex'
							justifyContent='center'
							alignItems='center'
							w={["25px", "30px", "40px"]}
							h={["25px", "30px", "40px"]}
							color='gray.500'
						>
							...
						</Text>,
					)
				}
			}

			rightBoundary = leftBoundary === 1 ? rightBoundary - 1 : rightBoundary
			leftBoundary = rightBoundary === totalPages ? leftBoundary + 1 : leftBoundary
			for (let i = leftBoundary; i <= rightBoundary; i++) {
				pages.push(
					<Button
						key={i}
						p={0}
						minW='25px'
						w={["25px", "30px", "40px"]}
						h={["25px", "30px", "40px"]}
						bgColor={currentPage === i ? "teal.500" : "transparent"}
						color={currentPage === i ? "white" : "black"}
						_hover={{
							bgColor: currentPage === i ? "teal.600" : "#FFF",
						}}
						borderRadius='50%'
						onClick={() => handlePageChange(i)}
					>
						{i}
					</Button>,
				)
			}

			if (rightBoundary < totalPages) {
				if (rightBoundary < totalPages - 1) {
					pages.push(
						<Text
							key='ellipsis-end'
							display='flex'
							justifyContent='center'
							alignItems='center'
							w={["25px", "30px", "40px"]}
							h={["25px", "30px", "40px"]}
							color='gray.500'
						>
							...
						</Text>,
					)
				}
				pages.push(
					<Button
						key={totalPages}
						p={0}
						minW='25px'
						w={["25px", "30px", "40px"]}
						h={["25px", "30px", "40px"]}
						bgColor={currentPage === totalPages ? "teal.500" : "transparent"}
						color={currentPage === totalPages ? "white" : "black"}
						_hover={{
							bgColor: currentPage === totalPages ? "teal.600" : "#FFF",
						}}
						borderRadius='50%'
						onClick={() => handlePageChange(totalPages)}
					>
						{totalPages}
					</Button>,
				)
			}
		}

		return pages
	}

	return (
		<Flex style={style} justifyContent='center' alignItems='center' flexWrap={"wrap"} rowGap={2}>
			<Button
				p={0}
				minW='25px'
				w={["25px", "30px", "40px"]}
				h={["15px", "20px", "30px"]}
				bgColor='transparent'
				aria-label='First item button'
				_hover={{
					bgColor: "#FFF",
				}}
				onClick={() => handlePageChange(1)}
				isDisabled={currentPage === 1}
			>
				<ArrowLeftIcon boxSize={3} />
			</Button>
			<Button
				p={0}
				minW='25px'
				w={["25px", "30px", "40px"]}
				h={["15px", "20px", "30px"]}
				bgColor='transparent'
				_hover={{
					bgColor: "#FFF",
				}}
				aria-label='Backward button'
				onClick={() => handlePageChange(currentPage - 1)}
				isDisabled={currentPage === 1}
			>
				<ChevronLeftIcon boxSize={6} />
			</Button>

			{renderPageNumbers()}

			<Button
				p={0}
				minW='25px'
				w={["25px", "30px", "40px"]}
				h={["15px", "20px", "30px"]}
				bgColor='transparent'
				aria-label='Forward button'
				_hover={{
					bgColor: "#FFF",
				}}
				onClick={() => handlePageChange(currentPage + 1)}
				isDisabled={currentPage === totalPages}
			>
				<ChevronRightIcon boxSize={6} />
			</Button>
			<Button
				p={0}
				minW='25px'
				w={["25px", "30px", "40px"]}
				h={["15px", "20px", "30px"]}
				bgColor='transparent'
				aria-label='Last item button'
				_hover={{
					bgColor: "#FFF",
				}}
				onClick={() => handlePageChange(totalPages)}
				isDisabled={currentPage === totalPages}
			>
				<ArrowRightIcon boxSize={3} />
			</Button>
			<Flex alignItems={"center"}>
				<Text ms={2} me={2} fontSize={[13, 14, 15, 16]}>
					{t("Pagination.text")}
				</Text>
				<Input
					aria-label='Change page input'
					w={["50px", "50px", "60px"]}
					fontSize={[13, 14, 15, 16]}
					h={["30px", "35px", "40px"]}
					value={currentPageValue}
					bgColor={"#FFF"}
					onChange={(e) => handleChangeInputValue(Number(e.target.value))}
				/>
				<Text ms={2} fontSize={[13, 14, 15, 16]}>{`${t("Pagination.of")} ${totalPages}`}</Text>
			</Flex>
		</Flex>
	)
}

export default Pagination
