import { Button, useDisclosure } from "@chakra-ui/react"
import { memo } from "react"
import { PDFViewer } from "@react-pdf/renderer"
import { useTranslation } from "react-i18next"
import { BrowserView } from "react-device-detect"
import { useSelector } from "react-redux"

import PDFModal from "../../../../common/Modal/PDFModal"
import DocumentPDF from "./DocumentPDF"

const DocumentsPrintButton = ({ data, isDisabled }) => {
	const { t } = useTranslation("global")
	const { isOpen, onOpen, onClose } = useDisclosure()
	const currentWarehouse = useSelector((state) => state.warehouses.currentWarehouse)

	return (
		<BrowserView>
			<Button
				isDisabled={isDisabled}
				fontSize={[13, 14, 15, 16]}
				fontWeight={"400"}
				h={["25px", "35px", "40px"]}
				bgColor={"#FFF"}
				borderRadius={"4px"}
				boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
				onClick={onOpen}
			>
				{t("Warehouses.print")}
			</Button>
			<PDFModal isOpen={isOpen} onClose={onClose}>
				<PDFViewer style={{ width: "100%", height: "75vh" }}>
					<DocumentPDF
						data={data}
						warehouseString={t("Warehouses.warehouse")}
						documentTypes={{ pz: t("Warehouses.pz"), rw: t("Warehouses.rw"), inv: t("Warehouses.inv") }}
						documentStatuses={[t("Warehouses.open"), t("Warehouses.closed")]}
						warehouseName={currentWarehouse?.name}
						tableHeaders={[
							t("Warehouses.number"),
							t("Warehouses.status"),
							t("Warehouses.description"),
							t("Warehouses.created"),
							t("Warehouses.createdDate"),
						]}
					/>
				</PDFViewer>
			</PDFModal>
		</BrowserView>
	)
}

export default memo(DocumentsPrintButton)
