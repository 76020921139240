import { Button, Flex, Text } from "@chakra-ui/react"
import { memo, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import i18next from "i18next"
import { useTranslation } from "react-i18next"

import EditIcon from "../../../../../assets/icons/materials/EditIcon"
import RecordsList from "../../../../common/RecordsList"
import Pagination from "../../../../common/Pagination"
import hasPermissions from "../../../../../utils/permissions/hasPermissions"
import DoubleBookmarkIcon from "../../../../../assets/icons/users/DoubleBookmarkIcon"

const EmployeesList = ({ employees, employeesCopy, paginationLength, handleChangePage, handleSorting, isFired }) => {
	const { t } = useTranslation("global")
	const location = useLocation()
	const navigate = useNavigate()
	const [isSortByEmployeeDesc, setIsSortByEmployeeDesc] = useState(true)
	const [isSortByGroupDesc, setIsSortByGroupDesc] = useState(true)
	const [isSortByPermissionsDesc, setIsSortByPermissionsDesc] = useState(true)
	const [isSortByFunctionDesc, setIsSortByFunctionDesc] = useState(true)
	const [headers, setHeaders] = useState([])
	const [records, setRecords] = useState([])
	const [hasEditPermission] = useState(hasPermissions("user", ["edit_users"]))

	const getEmployeesHeaders = () => {
		const headersArray = [
			{
				name: t("Employees.employee"),
				isDescSort: isSortByEmployeeDesc,
				sortingMethod: () => handleSorting(isSortByEmployeeDesc, setIsSortByEmployeeDesc, "name"),
			},
			{
				name: t("Employees.group"),
				isDescSort: isSortByGroupDesc,
				sortingMethod: () => handleSorting(isSortByGroupDesc, setIsSortByGroupDesc, "group"),
			},
			{
				name: t("Employees.permissionsForUsers"),
				isDescSort: isSortByPermissionsDesc,
				sortingMethod: () =>
					handleSorting(isSortByPermissionsDesc, setIsSortByPermissionsDesc, "permissions_for_users"),
			},
			{
				name: t("Employees.function"),
				isDescSort: isSortByFunctionDesc,
				sortingMethod: () => handleSorting(isSortByFunctionDesc, setIsSortByFunctionDesc, "function"),
			},
			{
				name: t("Employees.edit"),
				justifyContent: "flex-end",
			},
		]

		if (hasEditPermission) return headersArray

		headersArray.pop()
		return headersArray
	}

	const handleEditGroup = (employeeId) => {
		navigate(
			location.pathname.endsWith("/")
				? `${location.pathname}edit/${employeeId}`
				: `${location.pathname}/edit/${employeeId}`,
		)
	}

	const getRecord = (employee) => {
		const recordArray = [
			{
				body: (
					<Flex gap={[1, 2]} alignItems={"center"} my={2}>
						<DoubleBookmarkIcon
							color={employee.group_color}
							bgColor={employee.background_color}
							boxSize={[3, 4]}
						/>
						<Text wordBreak={"break-word"} fontWeight={600}>
							{employee.name}
						</Text>
					</Flex>
				),
			},
			{
				body: <Text wordBreak={"break-word"}>{employee.group}</Text>,
			},
			{
				body: <Text wordBreak={"break-word"}>{employee.permissions_for_users}</Text>,
			},
			{
				body: <Text wordBreak={"break-word"}>{employee.function}</Text>,
			},
			{
				body: (
					<Button
						bgColor={employee.is_fired ? "#FFFFFF" : "#F2F2EF"}
						p={0}
						minW='25px'
						boxSize={["25px", "30px", "35px"]}
						borderRadius='4px'
						onClick={() => handleEditGroup(employee.id)}
						aria-label='Edit employee button'
					>
						<EditIcon boxSize={[5, 5, 6]} />
					</Button>
				),
				justifyContent: "flex-end",
			},
		]

		if (hasEditPermission) return recordArray

		recordArray.pop()
		return recordArray
	}

	const getEmployeesRecords = (employees) =>
		employees.map((employee) => ({
			childrens: getRecord(employee),
		}))

	useEffect(() => {
		setHeaders(getEmployeesHeaders())
		setRecords(getEmployeesRecords(employees))
	}, [employees, i18next.language])

	return (
		<>
			<RecordsList headers={headers} records={records} bgColor={isFired ? "#F2F2EF" : "#FFFFFF"} />
			{!!headers.length && !!records.length && (
				<Pagination
					style={{
						marginTop: "10px",
						paddingBottom: "10px",
					}}
					array={employeesCopy}
					totalRecords={paginationLength}
					onPageChange={(page) => handleChangePage(page)}
					recordsPerPage={25}
				/>
			)}
		</>
	)
}

export default memo(EmployeesList)
