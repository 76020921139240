import { Heading } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useParams } from "react-router-dom"

import WarehouseInventoryForm from "../../../../../../components/Modules/Warehouse/Warehouses/WarehouseInventory/WarehouseInventoryForm"
import Animation from "../../../../../../utils/animations/Routes/AnimatedPage"
import LoadingHOC from "../../../../../../components/common/LoadingHOC"
import { getInventoryCreator } from "../../../../../../actions/modules/warehouse/warehouses/warehouses-actions"

const WarehouseInventory = () => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const [inventoryData, setInventoryData] = useState()
	const [isLoading, setIsLoading] = useState(true)
	const location = useLocation()
	const { warehouseId } = useParams()

	useEffect(() => {
		if (location.state) {
			setIsLoading(false)
			return
		}
		dispatch(getInventoryCreator(warehouseId)).then((res) => {
			setInventoryData({
				inventoryCreator: res.payload?.inventory_creator,
				minimumDate: res.payload?.minimum_date,
			})
			setIsLoading(false)
		})
	}, [])

	return (
		<Animation styles={{ display: "flex", flexDirection: "column", height: "80%" }}>
			<Heading as={"h3"} fontSize={[13, 14, 15, 16]} fontWeight={600}>
				{location.state ? t("Warehouses.editInventory") : t("Warehouses.inventory")}
			</Heading>
			<LoadingHOC isLoading={!isLoading}>
				<WarehouseInventoryForm inventoryData={inventoryData} />
			</LoadingHOC>
		</Animation>
	)
}

export default WarehouseInventory
