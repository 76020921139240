import { ArrowForwardIcon } from "@chakra-ui/icons"
import { Button, Flex } from "@chakra-ui/react"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"

const ActionButtons = ({ onOpen }) => {
	const { t } = useTranslation("global")
	const { isSubmitting } = useFormikContext()

	return (
		<Flex justifyContent='end' mt={4}>
			<Button
				aria-label='Cancel button'
				isDisabled={isSubmitting}
				bgColor='red.600'
				_hover={{ backgroundColor: "red.700" }}
				colorScheme='red'
				color='#FFF'
				me={2}
				h={["30px", "35px", "40px"]}
				fontSize={[13, 14, 15, 16]}
				fontWeight={400}
				onClick={onOpen}
			>
				{t("Warehouses.cancel")}
			</Button>
			<Button
				aria-label='Save button'
				isDisabled={isSubmitting}
				type='submit'
				bgColor='green.600'
				_hover={{ backgroundColor: "green.700" }}
				colorScheme='green'
				color='#FFF'
				h={["30px", "35px", "40px"]}
				fontSize={[13, 14, 15, 16]}
				fontWeight={400}
			>
				{t("Warehouses.saveAndGoForward")}
				<ArrowForwardIcon ms={1} boxSize={[4, 4, 5]} />
			</Button>
		</Flex>
	)
}

export default ActionButtons
