import { Icon } from "@chakra-ui/react"

const ExpandedIcon = (props) => (
	<Icon viewBox='0 0 22 22' {...props}>
		<path
			fill='currentColor'
			d='M12.75 3.75A.75.75 0 0113.5 3h5.25a.75.75 0 01.75.75V9A.75.75 0 0118 9V5.56L5.56 18H9a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75V13.5a.75.75 0 011.5 0v3.44L16.938 4.5H13.5a.75.75 0 01-.75-.75z'
		/>
	</Icon>
)

export default ExpandedIcon
