import { Slide, toast } from "react-toastify"

export const warningToast = (message) =>
	toast.warn(message, {
		isLoading: false,
		position: "bottom-center",
		autoClose: 1500,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: true,
		limit: 5,
		transition: Slide,
		theme: "colored",
		progressStyle: {
			backgroundColor: "#FFF",
		},
		style: {
			backgroundColor: "#FF9700",
		},
	})
