import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { auth, login, loginWithMicrosoft, logout } from "../../actions/auth/auth/auth-actions"
import i18next from "i18next"
import { changePassword } from "../../actions/auth/resetPassword/resetPassword-actions"
import { finishInventory } from "../../actions/modules/warehouse/warehouses/warehouses-actions"

const clearAuthFunction = (state) => {
	state.user = null
	state.email = null
	state.role = null
	state.modules = []
	state.order_module_permissions = []
	state.user_module_permissions = []
	state.permission_options = []
	state.warehouse_module.inventoried_warehouses = []
	state.warehouse_module_permissions = []
}

/**
 * Slice for auth
 */
const authSlice = createSlice({
	name: "auth",
	initialState: {
		user: null,
		email: null,
		role: null,
		order_module_permissions: [],
		user_module_permissions: [],
		warehouse_module_permissions: [],
		warehouse_module: {
			inventoried_warehouses: [],
		},
		permission_options: [],
		modules: [],
	},
	reducers: {
		setIsOpenModule(state, action) {
			state.modules.forEach((module) => {
				if (action.payload.id === module.id) {
					module.is_open = module.is_open ? 0 : 1
					return
				}
				module.is_open = 0
			})
		},
		setIsOpenModuleAtStart(state, action) {
			const url = `/${action.payload.url.split("/")[1]}`
			state.modules.forEach((module) => {
				if (url === module.url) {
					module.is_open = true
					return
				}
				module.is_open = false
			})
		},
		setLimitedWarehouses(state, action) {
			state.warehouse_module.inventoried_warehouses = [
				...state.warehouse_module.inventoried_warehouses,
				action.payload,
			]
		},
		removeLimitedWarehouse(state, action) {
			state.warehouse_module.inventoried_warehouses = state.warehouse_module.inventoried_warehouses.filter(
				(warehouse) => warehouse.id != action.payload,
			)
		},
		clearAuth(state) {
			clearAuthFunction(state)
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(logout.fulfilled, (state) => {
				clearAuthFunction(state)
			})
			.addCase(loginWithMicrosoft.fulfilled, (state, action) => {
				window.location.href = action.payload.redirectUrl
			})
			.addCase(changePassword.fulfilled, (_state, action) => {
				action.meta.arg.navigate("/")
			})
			.addCase(finishInventory.fulfilled, (state, action) => {
				state.warehouse_module.inventoried_warehouses = state.warehouse_module.inventoried_warehouses.filter(
					(warehouse) => warehouse.id != action.meta.arg,
				)
			})
			.addMatcher(isAnyOf(login.fulfilled, auth.fulfilled), (state, action) => {
				if (typeof action.payload !== "object") {
					localStorage.getItem("language") && i18next.changeLanguage(localStorage.getItem("language"))
					return
				}
				state.user = action.payload.user.name
				state.order_module_permissions = action.payload.order_module_permissions || []
				state.user_module_permissions = action.payload.user_module_permissions || []
				state.warehouse_module.inventoried_warehouses =
					action.payload?.warehouse_module?.inventoried_warehouses || []
				state.warehouse_module_permissions = action.payload.warehouse_module_permissions || []
				state.permission_options = action.payload.permission_options
				state.email = action.payload.user.email
				state.role = action.payload.role
				state.modules = action.payload.permissions

				localStorage.setItem("language", action.payload.user.language)
				if (!i18next.isChangedLanguage) {
					i18next.changeLanguage(action.payload.user.language)
				}
			})
	},
})

export const { setIsOpenModule, setLimitedWarehouses, removeLimitedWarehouse, setIsOpenModuleAtStart, clearAuth } =
	authSlice.actions

export default authSlice
