import { configureStore } from "@reduxjs/toolkit"

import authSlice, { clearAuth } from "./auth/auth-slice"
import materialsSlice from "./modules/orders/materials/materials-slice"
import permissionsSlice from "./permissions/permissions.slice"
import shopSlice from "./modules/orders/shop/shop-slice"
import orderListSlice from "./modules/orders/order-list/order-list-slice"
import historySlice from "./modules/orders/history/history-slice"
import ordersSettingsSlice from "./modules/orders/orders-settings/order-settings-slice"
import groupsSlice from "./modules/users/groups/groups-slice"
import employeesSlice from "./modules/users/employees/employees-slice"
import settingsSlice from "./modules/users/settings/settings-slice"
import systemSlice, { clearDateTime } from "./system/system-slice"
import warehousesSlice from "./modules/warehouse/warehouses/warehouses-slice"
import warehouseMaterialsSlice from "./modules/warehouse/materials/materials-slice"
import warehouseSettingsSlice from "./modules/warehouse/settings/warehouseSettings-slice"

/**
 * Auth middleware function
 */
const authMiddleware = (store) => (next) => (action) => {
	if (action.type.endsWith("/rejected") && action.error && action.error.message.includes(401)) {
		store.dispatch(clearAuth())
		store.dispatch(clearDateTime())
	}
	return next(action)
}

/**
 * Variable which fusion all slices
 * @param reducer - Object with all reducers
 * @return store - Object with all reducers
 */
const store = configureStore({
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authMiddleware),
	reducer: {
		//System
		system: systemSlice.reducer,
		auth: authSlice.reducer,
		permissions: permissionsSlice.reducer,

		//Orders
		materials: materialsSlice.reducer,
		shop: shopSlice.reducer,
		orderList: orderListSlice.reducer,
		history: historySlice.reducer,
		ordersSettings: ordersSettingsSlice.reducer,

		//Users
		groups: groupsSlice.reducer,
		employees: employeesSlice.reducer,
		usersSettings: settingsSlice.reducer,

		//Warehouse
		warehouses: warehousesSlice.reducer,
		warehouseMaterials: warehouseMaterialsSlice.reducer,
		warehouseSettings: warehouseSettingsSlice.reducer,
	},
})

export default store
