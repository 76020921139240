import { createSlice } from "@reduxjs/toolkit"
import {
	getEmployeeById,
	getEmployeeFormInitialDates,
	getEmployees,
} from "../../../../actions/modules/users/employees/employees-actions"
import i18next from "i18next"

/**
 * Slice for employees
 */
const employeesSlice = createSlice({
	name: "employees",
	initialState: {
		employees: null,
		initialDates: null,
		downloadedEmployee: null,
	},
	reducers: {
		clearEmployees(state) {
			state.employees = null
		},
		clearInitialDates(state) {
			state.initialDates = null
		},
		clearDownloadedEmployee(state) {
			state.downloadedEmployee = null
		},
		sorting(state, action) {
			state.employees.employees = action.payload
		},
		changeInitDataLanguage(state) {
			if (!state.initialDates) return
			const languages = state.initialDates?.language.map((item) => {
				item.name = item[`name_${i18next.language}`]
				return item
			})
			state.initialDates.language = languages
		},
		changeDownloadEmployeeLanguage(state) {
			if (!state.downloadedEmployee) return
			const languages = state.downloadedEmployee?.dropdowns.language.map((item) => {
				item.name = item[`name_${i18next.language}`]
				return item
			})
			state.downloadedEmployee.dropdowns.language = languages
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getEmployees.fulfilled, (state, action) => {
				state.employees = action.payload
			})
			.addCase(getEmployeeFormInitialDates.fulfilled, (state, action) => {
				state.initialDates = action.payload
			})
			.addCase(getEmployeeById.fulfilled, (state, action) => {
				state.downloadedEmployee = action.payload
			})
	},
})

export const {
	clearEmployees,
	clearInitialDates,
	clearDownloadedEmployee,
	sorting,
	changeInitDataLanguage,
	changeDownloadEmployeeLanguage,
} = employeesSlice.actions

export default employeesSlice
