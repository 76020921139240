import { Button, Flex } from "@chakra-ui/react"
import { memo } from "react"
import { useTranslation } from "react-i18next"
import { AddIcon } from "@chakra-ui/icons"

import InventoryExcelButton from "./InventoryExcelButton"
import InventoryPDFButton from "./InventoryPDFButton"
import InventoryCopyButton from "./InventoryCopyButton"
import InventoryPrintButton from "./InventoryPrintButton"
import { useSelector } from "react-redux"

const InventoryActionButtons = ({ data, onToggle }) => {
	const { t } = useTranslation("global")
	const currentDocument = useSelector((state) => state.warehouses.currentDocument)

	return (
		<Flex gap={2} w={["100%", "auto"]} flexWrap={["wrap", "wrap", "nowrap"]} justifyContent={"end"}>
			<InventoryExcelButton isDisabled={!data?.length} jsonData={data} />
			<InventoryPDFButton isDisabled={!data?.length} data={data} />
			<InventoryCopyButton isDisabled={!data?.length} data={data} />
			<InventoryPrintButton isDisabled={!data?.length} data={data} />
			{currentDocument?.status === 0 && (
				<Button
					fontSize={[13, 14, 15, 16]}
					fontWeight={"400"}
					h={["25px", "35px", "40px"]}
					borderRadius={"4px"}
					boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
					colorScheme='green'
					gap={2}
					onClick={onToggle}
				>
					<AddIcon boxSize={[3, 3.5, 4]} />
					{t("Common.add")}
				</Button>
			)}
		</Flex>
	)
}

export default memo(InventoryActionButtons)
