import { Button, Flex, Heading, useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { ArrowForwardIcon } from "@chakra-ui/icons"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import i18next from "i18next"
import { useNavigate, useParams } from "react-router-dom"

import Animation from "../../../../../../utils/animations/Routes/AnimatedPage"
import InventoryDetails from "../../../../../../components/Modules/Warehouse/Warehouses/WarehouseInventory/InventoryDetails"
import InventoryActionBar from "../../../../../../components/Modules/Warehouse/Warehouses/WarehouseInventory/InventoryActionBar"
import { clearCurrentDocument } from "../../../../../../store/modules/warehouse/warehouses/warehouses-slice"
import {
	finishInventory,
	getInventoryDocument,
} from "../../../../../../actions/modules/warehouse/warehouses/warehouses-actions"
import LoadingHOC from "../../../../../../components/common/LoadingHOC"
import InventoryGroupsList from "../../../../../../components/Modules/Warehouse/Warehouses/WarehouseInventory/InventoryGroupsList"
import NoData from "../../../../../../components/common/NoData"
import FinishInventoryModal from "../../../../../../components/Modules/Warehouse/Warehouses/WarehouseInventory/FinishInventoryModal"

const WarehouseInventoryDocument = () => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const currentDocument = useSelector((state) => state.warehouses.currentDocument)
	const userWarehousePermissions = useSelector((state) => state.auth["warehouse_module_permissions"])
	const [disabledForm, setDisabledForm] = useState(false)
	const [groups, setGroups] = useState()
	const [groupsCopy, setGroupsCopy] = useState()
	const [recordsPerPage, setRecordsPerPage] = useState(5)
	const [isLoading, setIsLoading] = useState(true)
	const { documentId, warehouseId } = useParams()
	const { isOpen, onClose, onOpen } = useDisclosure()
	const navigate = useNavigate()

	useEffect(() => {
		if (!groupsCopy) return
		setData(groupsCopy)
	}, [recordsPerPage])

	useEffect(() => {
		if (!currentDocument) return

		setData(currentDocument.groups)
		setIsLoading(false)
	}, [currentDocument])

	useEffect(() => {
		setIsLoading(true)
		dispatch(getInventoryDocument(documentId)).then(({ payload }) => {
			const hasPermission = userWarehousePermissions.includes(
				payload.document?.status ? "document_preview" : "inventory",
			)
			if (!hasPermission) {
				navigate("/")
				return
			}
		})

		return () => dispatch(clearCurrentDocument())
	}, [i18next.language])

	const getMaxLength = () => {
		if (!groupsCopy) return

		return getConcatenatedMaterials(groupsCopy).length
	}

	const getConcatenatedMaterials = (array) => {
		if (!array) return

		let materials = []
		array.forEach((group) => group.materials.forEach((material) => materials.push(material)))
		return materials
	}

	const getGroupsFromConcatenatedMaterial = useCallback(
		(array, init, initData) => {
			if (!array) return

			let prevGroupId
			let groups = []
			array.forEach((item) => {
				if (prevGroupId === item.group_id) {
					groups[groups.length - 1].materials = [...groups[groups.length - 1].materials, item]
					prevGroupId = item.group_id
					return
				}

				prevGroupId = item.group_id
				const group = init
					? { ...initData?.find((group) => group.id === item.group_id) }
					: { ...currentDocument.groups?.find((group) => group.id === item.group_id) }
				group.materials = [item]
				groups.push(group)
			})

			return groups
		},
		[currentDocument],
	)

	const getIsDisabledFinishInventory = useMemo(() =>
		currentDocument?.groups?.every((group) => group.materials.every((material) => material.is_confirmed === 2)),
	)

	const setData = (initData, init) => {
		const materials = getConcatenatedMaterials(initData).slice(0, recordsPerPage)
		const groups = getGroupsFromConcatenatedMaterial(materials, init, initData)
		setGroups(groups)
		setGroupsCopy(initData)
	}

	const handleFinishInventory = () => {
		dispatch(finishInventory(warehouseId)).then((res) => {
			if (res.error) return
			navigate(`/warehouse/warehouses/${warehouseId}/documents`)
		})
	}

	return (
		<Animation>
			<Flex flexDir={"column"} gap={7}>
				<Flex alignItems={"center"} justifyContent={"space-between"}>
					<Heading as={"h3"} fontSize={[13, 14, 15, 16]} fontWeight={600}>
						{t("Warehouses.inventory")}
					</Heading>
					{currentDocument?.status === 0 && (
						<>
							<Button
								aria-label='Save button'
								isDisabled={!getIsDisabledFinishInventory || disabledForm}
								type='submit'
								bgColor='teal.600'
								_hover={{ backgroundColor: "teal.700" }}
								colorScheme='green'
								color='#FFF'
								h={["30px", "35px", "40px"]}
								fontSize={[13, 14, 15, 16]}
								fontWeight={400}
								onClick={onOpen}
							>
								{t("Warehouses.finishInventory")}
								<ArrowForwardIcon ms={1} boxSize={[4, 4, 5]} />
							</Button>
							<FinishInventoryModal
								isOpen={isOpen}
								onClose={onClose}
								callbackFn={handleFinishInventory}
							/>
						</>
					)}
				</Flex>
				<InventoryDetails
					id={currentDocument?.id}
					documentNumber={currentDocument?.document_number}
					inventoryCreator={currentDocument?.inventory_creator}
					comments={currentDocument?.comments}
					documentDate={currentDocument?.document_date}
					status={currentDocument?.status}
				/>
				<InventoryActionBar
					setDisabledForm={setDisabledForm}
					isDisabled={disabledForm}
					data={groups}
					groupsList={currentDocument?.groups}
					setData={setData}
					setRecordsPerPage={setRecordsPerPage}
					maxLength={getMaxLength()}
					getConcatenatedMaterials={getConcatenatedMaterials}
					getGroupsFromConcatenatedMaterial={getGroupsFromConcatenatedMaterial}
				/>
				<LoadingHOC isLoading={!isLoading}>
					{groups?.length ? (
						<InventoryGroupsList
							groups={groups}
							setGroups={setGroups}
							groupsCopy={groupsCopy}
							setGroupsCopy={setGroupsCopy}
							status={currentDocument?.status}
							recordsPerPage={recordsPerPage}
							getConcatenatedMaterials={getConcatenatedMaterials}
							getGroupsFromConcatenatedMaterial={getGroupsFromConcatenatedMaterial}
						/>
					) : (
						<NoData />
					)}
				</LoadingHOC>
			</Flex>
		</Animation>
	)
}

export default WarehouseInventoryDocument
