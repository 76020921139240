import { Navigate, Outlet } from "react-router-dom"
import { useSelector } from "react-redux"

/**
 *
 * @returns Navigate to allowed routes if user logout
 */
const LoginRoutes = () => {
	const user = useSelector((state) => state.auth.user)

	return !user ? <Outlet /> : <Navigate to='/home' />
}

export default LoginRoutes
