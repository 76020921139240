import { useTranslation } from "react-i18next"
import { Flex, Text } from "@chakra-ui/react"
import { memo, useCallback, useEffect, useState } from "react"

import SelectWithFilteration from "../../../../common/SelectWithFilteration"

const GroupFilterSelect = ({
	arrayToFilter,
	searchingTerm,
	handleSetResults,
	filterGroupValue,
	setFilterGroupValue,
	getConcatenatedMaterials,
	getGroupsFromConcatenatedMaterial,
}) => {
	const { t } = useTranslation("global")
	const [groupArray, setGroupArray] = useState()

	useEffect(() => {
		setGroupArray(arrayToFilter?.filter((obj, index, self) => index === self.findIndex((t) => t.id === obj.id)))
	}, [arrayToFilter])

	const filterResultsBySearchingTerm = useCallback(
		(results) => {
			const materials = getConcatenatedMaterials(results)
			const filteredResults = materials.filter((material) =>
				material.name.toString().toLowerCase().includes(searchingTerm.toLowerCase()),
			)
			const groups = getGroupsFromConcatenatedMaterial(filteredResults)

			return groups
		},
		[searchingTerm],
	)

	const handleFilterResults = (results) => {
		const filterGroups = filterResultsBySearchingTerm(results)
		handleSetResults(filterGroups)
	}

	return (
		<Flex alignItems={"center"} gap={2}>
			<Text fontSize={[13, 14, 15, 16]} color={"#2C7A7B"}>
				{t("Warehouses.group")}
			</Text>
			<SelectWithFilteration
				isDisabled={!arrayToFilter?.length}
				name='name'
				value={filterGroupValue}
				setFilterValue={setFilterGroupValue}
				filterFields={[{ name: "name", value: filterGroupValue }]}
				values={groupArray}
				array={arrayToFilter}
				placeholder={t("Common.select")}
				handleSetResults={handleFilterResults}
			/>
		</Flex>
	)
}

export default memo(GroupFilterSelect)
