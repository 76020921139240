import header from "./Header/header.json"
import login from "./Login/login.json"
import sendEmail from "./RememberPassword/sendEmail.json"
import changePassword from "./RememberPassword/changePassword.json"
import footer from "./Footer/footer.json"
import permissions from "./Permissions/permissions.json"
import materials from "./Materials/materials.json"
import materialDetails from "./MaterialDetails/materialDetails.json"
import shop from "./Shop/shop.json"
import orderList from "./OrderList/orderList.json"
import history from "./History/history.json"
import order from "./Order/order.json"
import alertCancelChanges from "./AlertCancelChanges/alertCancelChanges.json"
import materialComment from "./MaterialComment/materialComment.json"
import removeAlert from "./RemoveAlert/removeAlert.json"
import ordersSettings from "./OrdersSettings/ordersSettings.json"
import languageInputs from "./LanguageInputs/languageInputs.json"
import groups from "./Users/Groups/groups.json"
import searchBar from "./SearchBar/searchBar.json"
import multiselect from "./MultiSelect/multiselect.json"
import employees from "./Users/Employees/employees.json"
import pagination from "./Pagination/pagination.json"
import common from "./Common/common.json"
import usersSettings from "./Users/UsersSettings/usersSettings.json"
import warehouses from "./Warehouse/Warehouses/warehouses.json"
import warehouseSettings from "./Warehouse/WarehouseSettings/warehouseSettings.json"

const global_en = {
	Login: {
		...login,
	},
	Header: {
		...header,
	},
	RememberPassword: {
		...sendEmail,
		...changePassword,
	},
	Footer: {
		...footer,
	},
	Common: {
		...common,
	},
	Permissions: {
		...permissions,
	},
	Materials: {
		...materials,
		...materialDetails,
	},
	Shop: {
		...shop,
	},
	OrderList: {
		...orderList,
	},
	History: {
		...history,
	},
	Order: {
		...order,
	},
	AlertCancelChanges: {
		...alertCancelChanges,
	},
	MaterialComment: {
		...materialComment,
	},
	RemoveAlert: {
		...removeAlert,
	},
	OrdersSettings: {
		...ordersSettings,
	},
	LanguageInputs: {
		...languageInputs,
	},
	Groups: {
		...groups,
	},
	SearchBar: {
		...searchBar,
	},
	MultiSelect: {
		...multiselect,
	},
	Employees: {
		...employees,
	},
	Pagination: {
		...pagination,
	},
	UsersSettings: {
		...usersSettings,
	},
	Warehouses: {
		...warehouses,
	},
	WarehouseSettings: {
		...warehouseSettings,
	},
}

export default global_en
