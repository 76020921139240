import { createAsyncThunk } from "@reduxjs/toolkit"

import { handleRequest } from "../../../../utils/api/api"

/**
 * Get orders settings
 */
export const getOrdersSettings = createAsyncThunk("orderSettings/getOrderSettings", async () => {
	const responseData = await handleRequest({
		endPoint: "/api/orders/allConfig",
	})
	return responseData
})

/**
 * Save orders settings
 */
export const saveAutoRemoveTime = createAsyncThunk("orderSettings/saveAutoRemoveTime", async (value) => {
	const responseData = await handleRequest({
		endPoint: "/api/orders/deleteOrdersAfterTime",
		method: "POST",
		body: value,
	})
	return responseData
})

/**
 * Set automatic emails function
 */
export const setAutomaticEmails = createAsyncThunk("orderSettings/setAutomaticEmails", async (values) => {
	const responseData = await handleRequest({
		endPoint: "/api/orders/automaticMails",
		method: "POST",
		body: values,
	})
	return responseData
})

/**
 * Delete user from automatic emails function
 */
export const removeAutoEmailUser = createAsyncThunk("orderSettings/removeAutoEmailUser", async (id) => {
	const responseData = await handleRequest({
		endPoint: `/api/orders/deleteAutomaticMails/${id}`,
		method: "DELETE",
	})
	return responseData
})

/**
 * Add role to settings
 */
export const addRole = createAsyncThunk("orderSettings/addRole", async (value) => {
	const responseData = await handleRequest({
		endPoint: "/api/orders/orderGroupPermissions",
		method: "POST",
		body: value,
	})
	return responseData
})

/**
 * Set user role
 */
export const setUsersRoles = createAsyncThunk("orderSettings/setUsersRoles", async (values) => {
	const responseData = await handleRequest({
		endPoint: "/api/orders/userOrderGroupPermissions",
		method: "POST",
		body: values,
	})
	return responseData
})
