import { keyframes } from "@emotion/react"

export const stateOffline = keyframes`
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
`

export const stateIndefinite = keyframes`
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(50%);
    }
`

export const stateOnline = keyframes`
    0% {
        transform: translateX(50%);
    }
    100% {
        transform: translateX(100%);
    }
`

export const stateOnlineByClick = keyframes`
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
`
