import { useParams } from "react-router-dom"
import { Box, Flex, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import i18next from "i18next"

import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import { getMaterials } from "../../../../../actions/modules/warehouse/warehouses/warehouses-actions"
import { clearWarehouseMaterials } from "../../../../../store/modules/warehouse/warehouses/warehouses-slice"
import LoadingHOC from "../../../../../components/common/LoadingHOC"
import StaticSearch from "../../../../../components/common/StaticSearch"
import MaterialsList from "../../../../../components/Modules/Warehouse/Warehouses/Warehouse/MaterialsList"
import NoData from "../../../../../components/common/NoData"
import QuantitySelect from "../../../../../components/Modules/Warehouse/Warehouses/Warehouse/QuantitySelect"
import MaterialWarehouseActionButtons from "../../../../../components/Modules/Warehouse/Warehouses/Warehouse/MaterialWarehouseActionButtons"

const WarehouseMaterial = () => {
	const dispatch = useDispatch()
	const materialsList = useSelector((state) => state.warehouses.warehouseMaterials)
	const { warehouseId, materialId } = useParams()
	const [materials, setMaterials] = useState()
	const [materialsCopy, setMaterialsCopy] = useState()
	const [isLoading, setIsLoading] = useState(true)
	const [searchingTerm, setSearchingTerm] = useState("")
	const [recordsPerPage, setRecordsPerPage] = useState(5)

	useEffect(() => {
		if (!materialsCopy) return
		setData(materialsCopy)
	}, [recordsPerPage])

	const setData = (initData) => {
		setMaterials(initData.slice(0, recordsPerPage))
		setMaterialsCopy(initData)
	}

	useEffect(() => {
		setIsLoading(true)
		dispatch(getMaterials({ warehouseId, materialId })).then((data) => {
			setData(data.payload?.materialGroups[0].materials)
			setIsLoading(false)
		})

		return () => dispatch(clearWarehouseMaterials())
	}, [i18next.language])

	const handleSetResults = (results) => {
		setData(results)
	}

	return (
		<Animation styles={{ display: "flex", flexDirection: "column", height: "80%" }}>
			<Flex
				w='100%'
				alignItems={"center"}
				gap={5}
				justifyContent={"space-between"}
				flexWrap={{ base: "wrap", lg: "nowrap" }}
			>
				{materialsList && (
					<Text fontSize={[13, 14, 15, 16]} fontWeight={600}>
						{materialsList.name}
					</Text>
				)}
				<Flex w={"100%"} justifyContent={"end"} gap={2} flexWrap={{ base: "wrap", md: "nowrap" }}>
					<QuantitySelect
						setRecordsPerPage={setRecordsPerPage}
						maxLength={materialsCopy?.length}
						isDisabled={!materialsList?.materials?.length}
					/>
					<Flex>
						<StaticSearch
							fieldsToFilter={["name"]}
							isDisabled={!materialsList?.materials?.length}
							searchText={searchingTerm}
							setSearchText={setSearchingTerm}
							handleSetResults={handleSetResults}
							originalArray={materialsList?.materials}
						/>
					</Flex>
					<MaterialWarehouseActionButtons
						groupName={materialsList?.name}
						data={materials}
						parameterNames={materialsList?.parameter_names}
					/>
				</Flex>
			</Flex>
			<LoadingHOC isLoading={!isLoading}>
				<Box my={10} pb={5}>
					{materials?.length ? (
						<MaterialsList
							parameterNames={materialsList?.parameter_names}
							materials={materials}
							setMaterials={setMaterials}
							materialsList={materialsList?.materials}
							materialsCopy={materialsCopy}
							setMaterialsCopy={setMaterialsCopy}
							recordsPerPage={recordsPerPage}
						/>
					) : (
						<NoData />
					)}
				</Box>
			</LoadingHOC>
		</Animation>
	)
}

export default WarehouseMaterial
