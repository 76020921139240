import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useState } from "react"

import Login from "./layout/Auth/Login/Login"
import LoginRoutes from "./routes/LoginRoutes"
import ProtectedRoutes from "./routes/ProtectedRoutes"
import { auth } from "./actions/auth/auth/auth-actions"
import SendEmail from "./layout/Auth/RememberPassword/SendEmail"
import ResetPassword from "./layout/Auth/RememberPassword/ResetPassword"
import Permissions from "./layout/Permissions/Permissions"
import Home from "./layout/Home/Home"
import AvailablePermissions from "./layout/Permissions/AvailablePermissions"
import Materials from "./layout/Modules/Orders/Materials/Materials"
import Shop from "./layout/Modules/Orders/Shop/Shop"
import OrderList from "./layout/Modules/Orders/OrderList/OrderList"
import Warehouses from "./layout/Modules/Warehouse/Warehouses/Warehouses"
import History from "./layout/Modules/Orders/History/History"
import HasPermissionsRoutes from "./routes/HasPermissionsRoutes"
import { PERMISSIONS } from "./utils/permissions/permissions"
import HasFunctionalityRoutes from "./routes/HasFunctionalityRoutes"
import { FUNCTIONALITIES } from "./utils/permissions/functionalities"
import { PERMISSIONS_OPTIONS } from "./utils/permissions/permissionOptions"
import MaterialDetails from "./layout/Modules/Orders/Materials/EditMaterial/MaterialDetails"
import AddMaterialGroup from "./layout/Modules/Orders/Materials/AddMaterial/AddMaterialGroup"
import Order from "./layout/Modules/Orders/Shop/Order/Order"
import OrdersSettings from "./layout/Modules/Orders/OrdersSettings/OrdersSettings"
import Groups from "./layout/Modules/Users/Groups/Groups"
import Employees from "./layout/Modules/Users/Employees/Employees"
import Contractors from "./layout/Modules/Users/Contractors/Contractors"
import UsersSettings from "./layout/Modules/Users/UsersSettings/UsersSettings"
import AddGroup from "./layout/Modules/Users/Groups/AddGroup/AddGroup"
import EditGroup from "./layout/Modules/Users/Groups/EditGroup/EditGroup"
import AddEmployee from "./layout/Modules/Users/Employees/AddEmployee/AddEmployee"
import EditEmployee from "./layout/Modules/Users/Employees/EditEmployee/EditEmployee"
import HasModulePermissionsRoutes from "./routes/HasModulePermissionsRoutes"
import WarehouseManage from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehouseManage"
import WarehouseAdd from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehouseAdd/WarehouseAdd"
import WarehouseInventory from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehouseInventory/WarehouseInventory"
import WarehouseMaterial from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehouseMaterial"
import WarehouseSettings from "./layout/Modules/Warehouse/WarehouseSettings/WarehouseSettings"
import WarehouseTakeDocument from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehouseTake/WarehouseTakeDocument"
import WarehouseAddDocument from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehouseAdd/WarehouseAddDocument"
import HasPermisssionOptionsRoutes from "./routes/HasPermisssionOptionsRoutes"
import WarehouseTakePage from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehouseTake/WarehouseTakePage"
import WarehouseDocumentsPage from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehouseDocuments/WarehouseDocumentsPage"
import ProtectedWarehouseRoutes from "./routes/ProtectedWarehouseRoutes"
import WarehousesNavigation from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehousesNavigation"
import WarehouseInventoryDocument from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehouseInventory/WarehouseInventoryDocument"
import WarehouseInventoryGroup from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehouseInventory/WarehouseInventoryGroup"
import AddNewWarehouse from "./layout/Modules/Warehouse/Warehouses/Add/AddNewWarehouse"
import EditWarehouse from "./layout/Modules/Warehouse/Warehouses/Edit/EditWarehouse"

function App() {
	const dispatch = useDispatch()
	const [isAuthChecked, setIsAuthChecked] = useState(false)

	if (!isAuthChecked) {
		dispatch(auth()).then(() => {
			setIsAuthChecked(true)
		})
	}

	return (
		isAuthChecked && (
			<Router>
				<Routes>
					<Route element={<LoginRoutes />}>
						<Route path='/' element={<Login />} />
						<Route path='/reset-password' element={<SendEmail />} />
						<Route path='/reset-password/:token' element={<ResetPassword />} />
						<Route path='*' element={<Navigate to='/' />} />
					</Route>
					<Route element={<ProtectedRoutes />}>
						<Route path='/home' element={<Home />} />
						<Route
							element={<HasPermisssionOptionsRoutes allowedOptions={PERMISSIONS_OPTIONS.PERMISSIONS} />}
						>
							<Route path='/permissions' element={<Permissions />} />
						</Route>
						<Route
							element={
								<HasPermisssionOptionsRoutes allowedOptions={PERMISSIONS_OPTIONS.APP_PERMISSIONS} />
							}
						>
							<Route path='/appPermissions' element={<AvailablePermissions />} />
						</Route>
						<Route element={<HasPermissionsRoutes allowedPermissions={[PERMISSIONS.ORDERS]} />}>
							<Route
								element={
									<HasFunctionalityRoutes
										allowedFunctionality={[FUNCTIONALITIES.ORDERS.MATERIALS]}
										moduleUrl={PERMISSIONS.ORDERS}
									/>
								}
							>
								<Route path='/orders/materials'>
									<Route index={true} element={<Materials />} />
									<Route path='add' element={<AddMaterialGroup />} />
									<Route path=':materialId' element={<MaterialDetails />} />
								</Route>
							</Route>
							<Route
								element={
									<HasFunctionalityRoutes
										allowedFunctionality={[FUNCTIONALITIES.ORDERS.SHOP]}
										moduleUrl={PERMISSIONS.ORDERS}
									/>
								}
							>
								<Route path='/orders/shop'>
									<Route index={true} element={<Shop />} />
									<Route path=':orderId' element={<Order />} />
									<Route path=':orderId/edit' element={<Shop />} />
								</Route>
							</Route>
							<Route
								element={
									<HasFunctionalityRoutes
										allowedFunctionality={[FUNCTIONALITIES.ORDERS.ORDERLIST]}
										moduleUrl={PERMISSIONS.ORDERS}
									/>
								}
							>
								<Route path='/orders/order-list'>
									<Route index={true} element={<OrderList />} />
									<Route path=':orderId' element={<Order />} />
									<Route path=':orderId/edit' element={<Shop />} />
								</Route>
							</Route>
							<Route
								element={
									<HasFunctionalityRoutes
										allowedFunctionality={[FUNCTIONALITIES.ORDERS.HISTORY]}
										moduleUrl={PERMISSIONS.ORDERS}
									/>
								}
							>
								<Route path='/orders/history' element={<History />} />
							</Route>
							<Route
								element={
									<HasFunctionalityRoutes
										allowedFunctionality={[FUNCTIONALITIES.ORDERS.SETTINGS]}
										moduleUrl={PERMISSIONS.ORDERS}
									/>
								}
							>
								<Route path='/orders/settings' element={<OrdersSettings />} />
							</Route>
						</Route>
						<Route element={<HasPermissionsRoutes allowedPermissions={[PERMISSIONS.USERS]} />}>
							<Route
								element={
									<HasFunctionalityRoutes
										allowedFunctionality={[FUNCTIONALITIES.USERS.GROUPS]}
										moduleUrl={PERMISSIONS.USERS}
									/>
								}
							>
								<Route path='/users/groups'>
									<Route index={true} element={<Groups />} />
									<Route
										element={
											<HasModulePermissionsRoutes
												permissions={"user"}
												allowedPermissions={["add_group"]}
											/>
										}
									>
										<Route path='add' element={<AddGroup />} />
									</Route>
									<Route
										element={
											<HasModulePermissionsRoutes
												permissions={"user"}
												allowedPermissions={["edit_groups"]}
											/>
										}
									>
										<Route path='edit/:groupId' element={<EditGroup />} />
									</Route>
								</Route>
							</Route>
							<Route
								element={
									<HasFunctionalityRoutes
										allowedFunctionality={[FUNCTIONALITIES.USERS.EMPLOYEES]}
										moduleUrl={PERMISSIONS.USERS}
									/>
								}
							>
								<Route path='/users/employees'>
									<Route index={true} element={<Employees />} />
									<Route
										element={
											<HasModulePermissionsRoutes
												permissions={"user"}
												allowedPermissions={["add_user"]}
											/>
										}
									>
										<Route path='add' element={<AddEmployee />} />
									</Route>
									<Route
										element={
											<HasModulePermissionsRoutes
												permissions={"user"}
												allowedPermissions={["edit_users"]}
											/>
										}
									>
										<Route path='edit/:employeeId' element={<EditEmployee />} />
									</Route>
								</Route>
							</Route>
							<Route
								element={
									<HasFunctionalityRoutes
										allowedFunctionality={[FUNCTIONALITIES.USERS.CONTRACTORS]}
										moduleUrl={PERMISSIONS.USERS}
									/>
								}
							>
								<Route path='/users/contractors'>
									<Route index={true} element={<Contractors />} />
								</Route>
							</Route>
							<Route
								element={
									<HasFunctionalityRoutes
										allowedFunctionality={[FUNCTIONALITIES.USERS.SETTINGS]}
										moduleUrl={PERMISSIONS.USERS}
									/>
								}
							>
								<Route path='/users/settings' element={<UsersSettings />} />
							</Route>
						</Route>
						<Route element={<HasPermissionsRoutes allowedPermissions={[PERMISSIONS.WAREHOUSE]} />}>
							<Route
								element={
									<HasFunctionalityRoutes
										allowedFunctionality={[FUNCTIONALITIES.WAREHOUSE.MATERIALS]}
										moduleUrl={PERMISSIONS.WAREHOUSE}
									/>
								}
							>
								<Route path='/warehouse/materials'>
									<Route index={true} element={<Materials />} />
									<Route path='add' element={<AddMaterialGroup />} />
									<Route path=':materialId' element={<MaterialDetails />} />
								</Route>
							</Route>
							<Route
								element={
									<HasFunctionalityRoutes
										allowedFunctionality={[FUNCTIONALITIES.WAREHOUSE.WAREHOUSES]}
										moduleUrl={PERMISSIONS.WAREHOUSE}
									/>
								}
							>
								<Route path='/warehouse/warehouses'>
									<Route index={true} element={<Warehouses />} />
									<Route
										element={
											<HasModulePermissionsRoutes
												permissions={"warehouse"}
												allowedPermissions={["add_warehouse"]}
											/>
										}
									>
										<Route path='add' element={<AddNewWarehouse />} />
									</Route>
									<Route path='edit/:warehouseId' element={<EditWarehouse />} />
									<Route element={<WarehousesNavigation />}>
										<Route
											element={
												<HasModulePermissionsRoutes
													permissions={"warehouse"}
													allowedPermissions={["warehouse_preview"]}
												/>
											}
										>
											<Route path=':warehouseId' element={<WarehouseManage />} />
											<Route path=':warehouseId/:materialId' element={<WarehouseMaterial />} />
										</Route>
										<Route path=':warehouseId/documents' element={<WarehouseDocumentsPage />} />
										<Route
											path=':warehouseId/documents/pz/:documentId'
											element={<WarehouseAddDocument />}
										/>
										<Route
											path=':warehouseId/documents/rw/:documentId'
											element={<WarehouseTakeDocument />}
										/>
										<Route path=':warehouseId/inventory' element={<WarehouseInventory />} />
										<Route path=':warehouseId/documents/inv/:documentId'>
											<Route index={true} element={<WarehouseInventoryDocument />} />
											<Route
												element={
													<HasModulePermissionsRoutes
														permissions={"warehouse"}
														allowedPermissions={["inventory"]}
													/>
												}
											>
												<Route path='edit' element={<WarehouseInventory />} />
											</Route>
										</Route>
										<Route
											path=':warehouseId/documents/inv/:documentId/:groupId'
											element={<WarehouseInventoryGroup />}
										/>
										<Route element={<ProtectedWarehouseRoutes />}>
											<Route path=':warehouseId/add' element={<WarehouseAdd />} />
											<Route path=':warehouseId/remove' element={<WarehouseTakePage />} />
											<Route
												element={
													<HasModulePermissionsRoutes
														permissions={"warehouse"}
														allowedPermissions={["document_edit"]}
													/>
												}
											>
												<Route
													path=':warehouseId/documents/rw/:documentId/edit'
													element={<WarehouseTakePage />}
												/>
												<Route
													path=':warehouseId/documents/pz/:documentId/edit'
													element={<WarehouseAdd />}
												/>
											</Route>
										</Route>
									</Route>
								</Route>
							</Route>
							<Route
								element={
									<HasFunctionalityRoutes
										allowedFunctionality={[FUNCTIONALITIES.WAREHOUSE.SETTINGS]}
										moduleUrl={PERMISSIONS.WAREHOUSE}
									/>
								}
							>
								<Route path='/warehouse/settings' element={<WarehouseSettings />} />
							</Route>
						</Route>
					</Route>
					<Route path='*' element={<Navigate to='/home' />} />
				</Routes>
			</Router>
		)
	)
}

export default App
