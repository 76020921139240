import { Flex, Text, useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import * as Yup from "yup"
import { useDispatch } from "react-redux"

import FormikForm from "../../../../common/FormikForm"
import WhiteBackground from "../../../../common/WhiteBackground"
import TextFieldWithDebounce from "../../../../common/TextFieldWithDebounce"
import TextAreaWithDebounce from "../../../../common/TextAreaWithDebounce"
import TextField from "../../../../common/TextField"
import ActionButtons from "./ActionButtons"
import AlertCancelChanges from "../../../../common/AlertCancelChanges"
import {
	createInventoryDocument,
	updateInventoryDocument,
} from "../../../../../actions/modules/warehouse/warehouses/warehouses-actions"

const WarehouseInventoryForm = ({ inventoryData }) => {
	const { t } = useTranslation("global")
	const { isOpen, onOpen, onClose } = useDisclosure()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const { warehouseId } = useParams()

	const handleConfirmCancel = () => {
		if (location.state) {
			navigate("..")
			return
		}
		const splittedPath = location.pathname.split("/")
		navigate(`/${splittedPath[1]}/${splittedPath[2]}/${splittedPath[3]}`)
	}

	const row = (textProp, field) => (
		<Flex
			alignItems='center'
			gap={[1, 5, 7, 10, 16]}
			justifyContent={"space-between"}
			flexWrap={{ base: "wrap", sm: "nowrap" }}
		>
			<Text
				w={{ base: "100%", sm: "150px", md: "175px", lg: "200px" }}
				minW={{ base: "100%", sm: "150px", md: "175px", lg: "200px" }}
				fontSize={[13, 14, 15, 15, 16]}
				color='#2C7A7B'
				wordBreak={"break-word"}
			>
				{t(`Warehouses.${textProp}`)}
			</Text>
			{field}
		</Flex>
	)

	return (
		<FormikForm
			isPatchMethod={!!location.state}
			shouldNavigate={false}
			enableReinitialize
			initialValues={
				location.state || {
					warehouse_id: warehouseId,
					document_number: "",
					comments: "",
					document_date: "",
				}
			}
			validationSchema={Yup.object({
				document_number: Yup.string().required(t("Warehouses.documentNameRequired")),
				document_date: location.state
					? Yup.date().required(t("Warehouses.documentDateIsRequired"))
					: Yup.date()
							.min(inventoryData?.minimumDate, t("Warehouses.documentDateIsIncorrect"))
							.required(t("Warehouses.documentDateIsRequired")),
			})}
			onSubmit={location.state ? updateInventoryDocument(navigate) : createInventoryDocument(navigate, dispatch)}
		>
			<WhiteBackground my={5}>
				<Flex flexDirection={"column"} gap={2}>
					{row(
						"documentName",
						<TextFieldWithDebounce
							mt={["1", "1", "1", "2"]}
							name='document_number'
							fontSize={[13, 14, 15, 15, 16]}
							h={["30px", "35px", "40px"]}
							type='text'
							placeholder={t("Warehouses.addDocumentNumber")}
						/>,
					)}
					{row(
						"inventoryCreator",
						<Text w='100%' fontSize={[13, 14, 15, 15, 16]} color='gray.500'>
							{location.state?.inventory_creator || inventoryData?.inventoryCreator}
						</Text>,
					)}
					{row(
						"comments",
						<TextAreaWithDebounce
							mt={["1", "1", "1", "2"]}
							name='comments'
							fontSize={[13, 14, 15, 15, 16]}
							placeholder={t("Warehouses.addComments")}
						/>,
					)}
					{row(
						"documentDate",
						<TextField
							isDisabled={location.state}
							mt={["1", "1", "1", "2"]}
							cursor='pointer'
							fontSize={[13, 14, 15, 16]}
							h={["30px", "35px", "40px"]}
							name='document_date'
							min={inventoryData?.minimumDate}
							type='date'
						/>,
					)}
				</Flex>
			</WhiteBackground>
			<ActionButtons onOpen={onOpen} />
			<AlertCancelChanges isOpen={isOpen} onClose={onClose} handleConfirmCancel={handleConfirmCancel} />
		</FormikForm>
	)
}

export default WarehouseInventoryForm
