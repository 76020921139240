import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

export const useGetIsInventory = () => {
	const limitedWarehouses = useSelector((state) => state.auth.warehouse_module.inventoried_warehouses)
	const { warehouseId } = useParams()
	const [isInventory, setIsInventory] = useState(
		limitedWarehouses?.some((limitedWarehouse) => limitedWarehouse.id == warehouseId),
	)

	useEffect(() => {
		const isLimited = limitedWarehouses?.some((limitedWarehouse) => limitedWarehouse.id == warehouseId)
		setIsInventory(isLimited)
	}, [limitedWarehouses])

	return { isInventory }
}
