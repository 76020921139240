import { Button, Heading, Image, Text, VStack } from "@chakra-ui/react"
import { Formik } from "formik"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

import TextField from "../../../common/TextField"
import { sendEmail } from "../../../../actions/auth/resetPassword/resetPassword-actions"

const SendResetPasswordEmailForm = () => {
	const [t] = useTranslation("global")
	const dispatch = useDispatch()

	const handleSendEmail = (values, actions) => {
		actions.setSubmitting(true)
		dispatch(sendEmail(values))
			.then(() => {
				actions.resetForm()
				actions.setSubmitting(false)
			})
			.catch(() => actions.setSubmitting(false))
	}

	return (
		<Formik
			initialValues={{
				email: "",
			}}
			validationSchema={Yup.object({
				email: Yup.string()
					.email(t("RememberPassword.sendEmail.main.emailInvalid"))
					.required(t("RememberPassword.sendEmail.main.emailRequired")),
			})}
			onSubmit={(values, actions) => {
				handleSendEmail(values, actions)
			}}
		>
			{(formik) => (
				<VStack
					as='form'
					w='70%'
					gap='0'
					position='relative'
					left='50%'
					transform='translateX(-50%)'
					onSubmit={formik.handleSubmit}
				>
					<Heading
						as='h1'
						w='90%'
						fontSize={["10", "14", "14", "16", "20"]}
						textAlign='center'
						pt={["1", "1", "1", "4", "5"]}
					>
						{t("RememberPassword.sendEmail.main.heading")}
					</Heading>
					<Text
						w='100%'
						fontSize={["10", "10", "12", "12", "14"]}
						textAlign='center'
						pt={["1", "1", "1", "2", "3"]}
						pb={["1", "3", "4", "5", "6"]}
					>
						{t("RememberPassword.sendEmail.main.instructionParagraph")}
					</Text>
					<TextField
						fontSize={["10", "14", "14", "14", "16"]}
						name='email'
						h={["30px", "35px", "45px"]}
						placeholder={t("RememberPassword.sendEmail.main.addressInput")}
						disabled={formik.isSubmitting}
					/>

					<Button
						fontSize={["10", "14", "14", "14", "16"]}
						type='submit'
						w='100%'
						h={["30px", "35px", "45px"]}
						bg='black'
						color='white'
						colorScheme='teal'
					>
						{t("RememberPassword.sendEmail.main.sendButton")}
						<Image
							src={require("../../../../assets/icons/login/Vector.png")}
							loading='lazy'
							alt='right-arrow'
							ml={2}
						/>
					</Button>
				</VStack>
			)}
		</Formik>
	)
}

export default SendResetPasswordEmailForm
