/**
 * Remove special characters from string
 * @param {string} str
 * @returns {string}
 */
function removeSpecialCharacters(str) {
	return str.replace(/[^\w\s]/gi, "")
}

export default removeSpecialCharacters
