import { createIcon } from "@chakra-ui/react"

const TriangleIcon = createIcon({
	displayName: "TriangleIcon",
	viewBox: "0 0 22 22",
	path: (
		<g fill='#FFF'>
			<path strokeWidth='1' stroke='gray' d='M1 25l10-18 10 22H1z' />
		</g>
	),
})

export default TriangleIcon
