import { Button, Menu, MenuButton, MenuList } from "@chakra-ui/react"
import { useSelector } from "react-redux"

import FiSettings from "../../../../assets/icons/header/FiSettings"
import PermissionsOptions from "../../PermissionsOptions/PermissionsOptions"

const AppPermissionsMobile = ({ onToogle }) => {
	const permissionsOptions = useSelector((state) => state.auth.permission_options)

	return (
		permissionsOptions?.length > 0 && (
			<Menu>
				<MenuButton
					as={Button}
					aria-label='Settings button'
					position='relative'
					borderRadius='50%'
					bgColor='#E5E5DF'
					h='30px'
					minW='30px'
					p={0}
				>
					<FiSettings
						position='absolute'
						top='50%'
						left='50%'
						transform='translate(-50%, -50%)'
						boxSize={5}
					/>
				</MenuButton>
				<MenuList color='#000' fontSize={["13", "15", "15"]}>
					<PermissionsOptions aria-label='Navigate to permissions' onClick={onToogle} />
				</MenuList>
			</Menu>
		)
	)
}

export default AppPermissionsMobile
