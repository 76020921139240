import { Button, Flex, Grid, GridItem, List, ListItem, Text, useDisclosure } from "@chakra-ui/react"
import { memo, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import i18next from "i18next"

import EditIcon from "../../../../../assets/icons/materials/EditIcon"
import BookmarkIcon from "../../../../../assets/icons/users/BookmarkIcon"
import BinIcon from "../../../../../assets/icons/materials/BinIcon"
import { removeGroup } from "../../../../../actions/modules/users/groups/groups-actions"
import RemoveAlert from "../../../../common/RemoveAlert"
import PermissionsChecked from "../../../../common/PermissionsChecked"
import AccordionList from "../../../../common/AccordionList"
import Pagination from "../../../../common/Pagination"
import hasPermissions from "../../../../../utils/permissions/hasPermissions"

const GroupsList = ({ groups, isDescSort, handleSorting, groupsCopy, paginationLength, handleChangePage }) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()
	const [disabledForm, setDisabledForm] = useState(false)
	const [removeGroupId, setRemoveGroupId] = useState()
	const [headers, setHeaders] = useState([])
	const [records, setRecords] = useState([])
	const [hasRequiredPermission] = useState(hasPermissions("user", ["edit_groups", "delete_groups"]))
	const { isOpen, onOpen, onClose } = useDisclosure()

	const handleEditGroup = (e, groupId) => {
		e.stopPropagation()
		setDisabledForm(true)
		navigate(
			location.pathname.endsWith("/")
				? `${location.pathname}edit/${groupId}`
				: `${location.pathname}/edit/${groupId}`,
		)
	}

	const handleRemoveGroup = (e, id) => {
		e.stopPropagation()
		setRemoveGroupId(id)
		onOpen()
	}

	const handleConfirmRemove = () => {
		setDisabledForm(true)
		dispatch(removeGroup(removeGroupId)).then(() => {
			setDisabledForm(false)
			setRemoveGroupId()
			onClose()
		})
	}

	const handleCloseAlert = () => {
		setRemoveGroupId()
		onClose()
	}

	const getEmployees = (employees) =>
		employees?.map((employee, index) => (
			<ListItem key={index}>
				<Text fontSize={{ base: "12px", md: "13px", lg: "14px" }}>{employee.name}</Text>
			</ListItem>
		))

	const getTools = (tools) =>
		tools?.map((tool, index) => (
			<ListItem key={index}>
				<Text fontSize={{ base: "12px", md: "13px", lg: "14px" }}>{tool.name}</Text>
			</ListItem>
		))

	const getHeaders = () => {
		const headersArray = [
			{
				name: t("Employees.group"),
				isDescSort: isDescSort,
				sortingMethod: handleSorting,
			},
			{
				name: t("Groups.edit"),
				justifyContent: "flex-end",
			},
		]

		if (hasRequiredPermission) return headersArray

		headersArray.pop()
		return headersArray
	}

	const getRecord = (group) => {
		const recordArray = [
			{
				body: (
					<Flex alignItems={"center"} gap={2}>
						<BookmarkIcon color={group.color} boxSize={[4, 5]} />
						<Text
							wordBreak={"break-word"}
							fontSize={{ base: "12px", md: "13px", lg: "14px" }}
							fontWeight={600}
						>
							{group.name}
						</Text>
					</Flex>
				),
			},
			{
				body: (
					<Flex gap={2} alignItems={"center"} flexWrap={"wrap"}>
						<PermissionsChecked permissions={"user"} allowedPermissions={["delete_groups"]}>
							<Button
								aria-label='Remove button'
								minW='25px'
								boxSize={["25px", "30px", "30px", "35px"]}
								borderRadius='4px'
								isDisabled={disabledForm || group.is_used}
								bgColor='#F2F2EF'
								p={0}
								onClick={(e) => handleRemoveGroup(e, group.id)}
							>
								<BinIcon
									color={"red.500"}
									w={["19px", "20px", "22px", "24px"]}
									h={["21px", "22px", "24px", "26px"]}
								/>
							</Button>
						</PermissionsChecked>
						<PermissionsChecked permissions={"user"} allowedPermissions={["edit_groups"]}>
							<Button
								isDisabled={disabledForm}
								bgColor='#F2F2EF'
								minW='25px'
								p={0}
								boxSize={["25px", "30px", "30px", "35px"]}
								borderRadius='4px'
								onClick={(e) => handleEditGroup(e, group.id)}
								aria-label='Edit group button'
							>
								<EditIcon boxSize={[4, 5, 5, 6]} />
							</Button>
						</PermissionsChecked>
					</Flex>
				),
				justifyContent: "flex-end",
			},
		]

		if (hasRequiredPermission) return recordArray

		recordArray.pop()
		return recordArray
	}

	const getRecords = (groups) =>
		groups.map((group) => ({
			childrens: getRecord(group),
			panel: (
				<Grid mt={4} templateColumns={"repeat(2, 1fr)"}>
					<GridItem>
						<Text fontSize={{ base: "12px", md: "13px", lg: "14px" }} fontWeight={600} color='#2C7A7B'>
							{t("Groups.employees")}
						</Text>
					</GridItem>
					<GridItem>
						<Text fontSize={{ base: "12px", md: "13px", lg: "14px" }} fontWeight={600} color='#2C7A7B'>
							{t("Groups.tools")}
						</Text>
					</GridItem>
					<GridItem mt={2}>
						<List maxW={"35vw"}>{getEmployees(group.employees)}</List>
					</GridItem>
					<GridItem mt={2}>
						<List maxW={"35vw"}>{getTools(group.tools)}</List>
					</GridItem>
				</Grid>
			),
		}))

	useEffect(() => {
		setHeaders(getHeaders())
		setRecords(getRecords(groups))
	}, [groups, i18next.language])

	return (
		<>
			<AccordionList headers={headers} records={records} />
			{!!headers.length && !!records.length && (
				<Pagination
					style={{
						marginTop: "10px",
						paddingBottom: "10px",
					}}
					array={groupsCopy}
					totalRecords={paginationLength}
					onPageChange={(page) => handleChangePage(page)}
					recordsPerPage={25}
				/>
			)}
			<RemoveAlert isOpen={isOpen} handleConfirmRemove={handleConfirmRemove} onClose={handleCloseAlert} />
		</>
	)
}

export default memo(GroupsList)
