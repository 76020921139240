import { AddIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, Grid, GridItem, Text, useMediaQuery } from "@chakra-ui/react"
import { FastField, FieldArray, useFormikContext } from "formik"
import i18next from "i18next"
import { useTranslation } from "react-i18next"

import BinIcon from "../../../../../assets/icons/materials/BinIcon"
import SelectField from "../../../../common/SelectField"
import LanguageInputs from "../../../../common/LanguageInputs"
import TextFieldWithDebounce from "../../../../common/TextFieldWithDebounce"
import { memo } from "react"

const WarehouseRoles = () => {
	const { t } = useTranslation("global")
	const { isSubmitting } = useFormikContext()
	const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)", {
		ssr: false,
	})

	const biggerRoles = (
		<FieldArray key={i18next.language} name='warehouseRoles'>
			{({ push, remove, form }) => (
				<>
					{form.values.warehouseRoles?.map((role, index) => (
						<Flex
							key={index}
							my={3}
							px={[3]}
							py={2}
							w='100%'
							bgColor='#F2F2EF'
							borderRadius='4px'
							alignItems='center'
						>
							<Grid w='100%' templateColumns={"repeat(7, 1fr)"} gridGap={14}>
								<FastField name={`warehouseRoles[${index}][name_${i18next.language}]`}>
									{() => (
										<GridItem colSpan={2}>
											<Flex w={["100%"]} gap={[2]} mt={"2"}>
												<TextFieldWithDebounce
													disabled={form.isSubmitting}
													bgColor='#FFF'
													fontSize={[16]}
													h={["40px"]}
													name={`warehouseRoles[${index}][name_${i18next.language}]`}
													placeholder={t("UsersSettings.roleName")}
												/>
												<LanguageInputs
													name={`usersRoles[${index}].name`}
													title={t("UsersSettings.roleName")}
												/>
											</Flex>
										</GridItem>
									)}
								</FastField>
								<GridItem colSpan={5}>
									<Grid templateColumns={"repeat(19, 1fr)"}>
										<FastField name={`warehouseRoles[${index}][add_warehouse]`}>
											{() => (
												<GridItem colSpan={3}>
													<SelectField
														disabled={form.isSubmitting}
														bgColor='#FFF'
														w={["90%"]}
														fontSize={[16]}
														h={["40px"]}
														name={`warehouseRoles[${index}].add_warehouse`}
														cursor='pointer'
														placeholder={t("UsersSettings.select")}
														aria-label='Select setting role'
														options={[
															{
																id: 1,
																value: 1,
																name: t("UsersSettings.yes"),
															},
															{ id: 0, value: 0, name: t("UsersSettings.no") },
														]}
													/>
												</GridItem>
											)}
										</FastField>
										<FastField name={`warehouseRoles[${index}][warehouse_preview]`}>
											{() => (
												<GridItem colSpan={3}>
													<SelectField
														disabled={form.isSubmitting}
														bgColor='#FFF'
														w={["90%"]}
														fontSize={[16]}
														h={["40px"]}
														name={`warehouseRoles[${index}][warehouse_preview]`}
														cursor='pointer'
														placeholder={t("UsersSettings.select")}
														aria-label='Select setting role'
														options={[
															{
																id: 1,
																value: 1,
																name: t("UsersSettings.yes"),
															},
															{ id: 0, value: 0, name: t("UsersSettings.no") },
														]}
													/>
												</GridItem>
											)}
										</FastField>
										<FastField name={`warehouseRoles[${index}][document_preview]`}>
											{() => (
												<GridItem colSpan={3}>
													<SelectField
														disabled={form.isSubmitting}
														bgColor='#FFF'
														w={["90%"]}
														fontSize={[16]}
														h={["40px"]}
														name={`warehouseRoles[${index}][document_preview]`}
														cursor='pointer'
														placeholder={t("UsersSettings.select")}
														aria-label='Select setting role'
														options={[
															{
																id: 1,
																value: 1,
																name: t("UsersSettings.yes"),
															},
															{ id: 0, value: 0, name: t("UsersSettings.no") },
														]}
													/>
												</GridItem>
											)}
										</FastField>
										<FastField name={`warehouseRoles[${index}][document_edit]`}>
											{() => (
												<GridItem colSpan={3}>
													<SelectField
														disabled={form.isSubmitting}
														bgColor='#FFF'
														w={["90%"]}
														fontSize={[16]}
														h={["40px"]}
														name={`warehouseRoles[${index}][document_edit]`}
														cursor='pointer'
														placeholder={t("UsersSettings.select")}
														aria-label='Select setting role'
														options={[
															{
																id: 1,
																value: 1,
																name: t("UsersSettings.yes"),
															},
															{ id: 0, value: 0, name: t("UsersSettings.no") },
														]}
													/>
												</GridItem>
											)}
										</FastField>
										<FastField name={`warehouseRoles[${index}][document_remove]`}>
											{() => (
												<GridItem colSpan={3}>
													<SelectField
														disabled={form.isSubmitting}
														bgColor='#FFF'
														w={["90%"]}
														fontSize={[16]}
														h={["40px"]}
														name={`warehouseRoles[${index}][document_remove]`}
														cursor='pointer'
														placeholder={t("UsersSettings.select")}
														aria-label='Select setting role'
														options={[
															{
																id: 1,
																value: 1,
																name: t("UsersSettings.yes"),
															},
															{ id: 0, value: 0, name: t("UsersSettings.no") },
														]}
													/>
												</GridItem>
											)}
										</FastField>
										<GridItem colSpan={3}>
											<Flex display={["flex"]} gap={[2]}>
												<FastField name={`warehouseRoles[${index}][inventory]`}>
													{() => (
														<SelectField
															disabled={form.isSubmitting}
															bgColor='#FFF'
															w={["90%"]}
															fontSize={[16]}
															h={["40px"]}
															name={`warehouseRoles[${index}][inventory]`}
															cursor='pointer'
															placeholder={t("UsersSettings.select")}
															aria-label='Select setting role'
															options={[
																{
																	id: 1,
																	value: 1,
																	name: t("UsersSettings.yes"),
																},
																{
																	id: 0,
																	value: 0,
																	name: t("UsersSettings.no"),
																},
															]}
														/>
													)}
												</FastField>
											</Flex>
										</GridItem>
										<GridItem>
											<Flex justifyContent='center' mt={[2]}>
												<Button
													aria-label='Remove button'
													isDisabled={role.is_used || form.isSubmitting}
													bgColor='transparent'
													p={0}
													minW='30px'
													boxSize={["40px"]}
													color='red.500'
													onClick={() =>
														form.values.warehouseRoles.length > 1 && remove(index)
													}
												>
													<BinIcon boxSize={[6]} />
												</Button>
											</Flex>
										</GridItem>
									</Grid>
								</GridItem>
							</Grid>
						</Flex>
					))}
					<Button
						aria-label='Add new role button'
						isDisabled={form.isSubmitting}
						_hover={{ backgroundColor: "teal.700" }}
						bgColor='#2B807E'
						p={2}
						h={["35px"]}
						fontSize={[16]}
						my={2}
						colorScheme='teal'
						color='#FFF'
						onClick={() => push({ name_pl: "", name_en: "", name_nl: "" })}
					>
						<AddIcon boxSize={3} me={2} />
						<Text aria-label='Add new role button'>{t("UsersSettings.addNew")}</Text>
					</Button>
				</>
			)}
		</FieldArray>
	)

	const smallerRoles = (
		<FieldArray key={i18next.language} name='warehouseRoles'>
			{({ push, remove, form }) => (
				<>
					{form.values.warehouseRoles?.map((role, index) => (
						<Flex
							key={index}
							gap={[2, 2, 4]}
							w={["712px", "758px", "1015px"]}
							bgColor='#F2F2EF'
							py={2}
							px={3}
							my={[2, 2, 3]}
							borderRadius={"4px"}
						>
							<FastField name={`warehouseRoles[${index}][name_${i18next.language}]`}>
								{() => (
									<Flex gap={[1, 2]} mt={"2"}>
										<TextFieldWithDebounce
											disabled={form.isSubmitting}
											bgColor='#FFF'
											w={["100px", "130px", "150px"]}
											fontSize={[13, 14, 15, 16]}
											h={["30px", "35px", "40px"]}
											name={`warehouseRoles[${index}][name_${i18next.language}]`}
											cursor='pointer'
											placeholder={t("UsersSettings.roleName")}
										/>
										<LanguageInputs
											name={`usersRoles[${index}].name`}
											title={t("UsersSettings.roleName")}
										/>
									</Flex>
								)}
							</FastField>
							<FastField name={`warehouseRoles[${index}][add_warehouse]`}>
								{() => (
									<SelectField
										disabled={form.isSubmitting}
										bgColor='#FFF'
										w={["80px", "80px", "110px"]}
										fontSize={[13, 14, 15, 16]}
										h={["30px", "35px", "40px"]}
										name={`warehouseRoles[${index}].add_warehouse`}
										cursor='pointer'
										placeholder={t("UsersSettings.select")}
										options={[
											{ id: 1, value: 1, name: t("UsersSettings.yes") },
											{ id: 0, value: 0, name: t("UsersSettings.no") },
										]}
									/>
								)}
							</FastField>
							<FastField name={`warehouseRoles[${index}][warehouse_preview]`}>
								{() => (
									<SelectField
										disabled={form.isSubmitting}
										bgColor='#FFF'
										w={["80px", "80px", "110px"]}
										fontSize={[13, 14, 15, 16]}
										h={["30px", "35px", "40px"]}
										name={`warehouseRoles[${index}][warehouse_preview]`}
										cursor='pointer'
										placeholder={t("UsersSettings.select")}
										options={[
											{ id: 1, value: 1, name: t("UsersSettings.yes") },
											{ id: 0, value: 0, name: t("UsersSettings.no") },
										]}
									/>
								)}
							</FastField>
							<FastField name={`warehouseRoles[${index}][document_preview]`}>
								{() => (
									<SelectField
										disabled={form.isSubmitting}
										bgColor='#FFF'
										w={["80px", "80px", "110px"]}
										fontSize={[13, 14, 15, 16]}
										h={["30px", "35px", "40px"]}
										name={`warehouseRoles[${index}][document_preview]`}
										cursor='pointer'
										placeholder={t("UsersSettings.select")}
										options={[
											{ id: 1, value: 1, name: t("UsersSettings.yes") },
											{ id: 0, value: 0, name: t("UsersSettings.no") },
										]}
									/>
								)}
							</FastField>
							<FastField name={`warehouseRoles[${index}][document_edit]`}>
								{() => (
									<SelectField
										disabled={form.isSubmitting}
										bgColor='#FFF'
										w={["80px", "80px", "110px"]}
										fontSize={[13, 14, 15, 16]}
										h={["30px", "35px", "40px"]}
										name={`warehouseRoles[${index}][document_edit]`}
										cursor='pointer'
										placeholder={t("UsersSettings.select")}
										options={[
											{ id: 1, value: 1, name: t("UsersSettings.yes") },
											{ id: 0, value: 0, name: t("UsersSettings.no") },
										]}
									/>
								)}
							</FastField>
							<FastField name={`warehouseRoles[${index}][document_remove]`}>
								{() => (
									<SelectField
										disabled={form.isSubmitting}
										bgColor='#FFF'
										w={["80px", "80px", "110px"]}
										fontSize={[13, 14, 15, 16]}
										h={["30px", "35px", "40px"]}
										name={`warehouseRoles[${index}][document_remove]`}
										cursor='pointer'
										placeholder={t("UsersSettings.select")}
										options={[
											{ id: 1, value: 1, name: t("UsersSettings.yes") },
											{ id: 0, value: 0, name: t("UsersSettings.no") },
										]}
									/>
								)}
							</FastField>
							<Flex justifyContent='center' gap={2}>
								<FastField name={`warehouseRoles[${index}][inventory]`}>
									{() => (
										<SelectField
											disabled={form.isSubmitting}
											bgColor='#FFF'
											w={["80px", "80px", "110px"]}
											fontSize={[13, 14, 15, 16]}
											h={["30px", "35px", "40px"]}
											name={`warehouseRoles[${index}][inventory]`}
											cursor='pointer'
											placeholder={t("UsersSettings.select")}
											options={[
												{ id: 1, value: 1, name: t("UsersSettings.yes") },
												{ id: 0, value: 0, name: t("UsersSettings.no") },
											]}
										/>
									)}
								</FastField>
								<Flex alignItems={"center"} mt={[1]}>
									<Button
										aria-label='Remove button'
										isDisabled={role.is_used || form.isSubmitting}
										bgColor='transparent'
										p={0}
										minW='30px'
										boxSize={["30px", "35px", "40px"]}
										color='red.500'
										onClick={() => form.values.warehouseRoles?.length > 1 && remove(index)}
									>
										<BinIcon boxSize={[5, 6, 6]} />
									</Button>
								</Flex>
							</Flex>
						</Flex>
					))}
					<Button
						aria-label='Add new role button'
						isDisabled={form.isSubmitting}
						_hover={{ backgroundColor: "teal.700" }}
						bgColor='#2B807E'
						p={2}
						h={["25px", "30px", "35px"]}
						fontSize={[13, 14, 15, 16]}
						my={2}
						colorScheme='teal'
						color='#FFF'
						onClick={() => push({ name_pl: "", name_en: "", name_nl: "" })}
					>
						<AddIcon boxSize={3} me={2} />
						<Text aria-label='Add new role button'>{t("UsersSettings.addNew")}</Text>
					</Button>
				</>
			)}
		</FieldArray>
	)

	return (
		<>
			{isLargerThan1280 ? (
				<>
					<Grid mt={[4, 5]} templateColumns={"repeat(7, 1fr)"} color='teal.600' gridGap={14}>
						<GridItem colSpan={2}>
							<Text ms={4} fontSize={[13, 14, 15, 16]}>
								{t("UsersSettings.name")}
							</Text>
						</GridItem>
						<GridItem colSpan={5}>
							<Grid templateColumns={"repeat(19, 1fr)"}>
								<GridItem colSpan={3}>
									<Text maxW={"120px"} ms={2} fontSize={[13, 14, 15, 16]}>
										{t("Warehouses.warehouseAdd")}
									</Text>
								</GridItem>
								<GridItem colSpan={3}>
									<Text maxW={"120px"} ms={2} fontSize={[13, 14, 15, 16]}>
										{t("Warehouses.warehousePreview")}
									</Text>
								</GridItem>
								<GridItem colSpan={3}>
									<Text maxW={"120px"} ms={1} fontSize={[13, 14, 15, 16]}>
										{t("Warehouses.documentPreview")}
									</Text>
								</GridItem>
								<GridItem colSpan={3}>
									<Text maxW={"120px"} ms={0} fontSize={[13, 14, 15, 16]}>
										{t("Warehouses.documentEditAndCreate")}
									</Text>
								</GridItem>
								<GridItem colSpan={3}>
									<Text maxW={"120px"} fontSize={[13, 14, 15, 16]}>
										{t("Warehouses.documentRemove")}
									</Text>
								</GridItem>
								<GridItem colSpan={3}>
									<Text maxW={"120px"} fontSize={[13, 14, 15, 16]}>
										{t("Warehouses.inventory")}
									</Text>
								</GridItem>
							</Grid>
						</GridItem>
					</Grid>
					{biggerRoles}
				</>
			) : (
				<Box overflowX={"auto"} maxW={{ base: "100%", lg: "79vw" }} mx='auto'>
					<Flex gap={[2, 2, 4]} mb={[2, 2, 3]} color='teal.600'>
						<Flex minW={["140px", "180px", "200px"]}>
							<Text ms={4} fontSize={[13, 14, 15, 16]}>
								{t("Warehouses.warehouseAdd")}
							</Text>
						</Flex>
						<Flex minW={["80px", "80px", "110px"]}>
							<Text maxW={["80px", "80px", "110px"]} fontSize={[13, 14, 15, 16]}>
								{t("Warehouses.warehousePreview")}
							</Text>
						</Flex>
						<Flex minW={["80px", "80px", "110px"]}>
							<Text maxW={["80px", "80px", "110px"]} fontSize={[13, 14, 15, 16]}>
								{t("Warehouses.warehousePreview")}
							</Text>
						</Flex>
						<Flex minW={["80px", "80px", "110px"]}>
							<Text maxW={["80px", "80px", "110px"]} fontSize={[13, 14, 15, 16]}>
								{t("Warehouses.documentPreview")}
							</Text>
						</Flex>
						<Flex minW={["80px", "80px", "110px"]}>
							<Text maxW={["80px", "80px", "110px"]} fontSize={[13, 14, 15, 16]}>
								{t("Warehouses.documentEditAndCreate")}
							</Text>
						</Flex>
						<Flex minW={["80px", "80px", "110px"]}>
							<Text maxW={["80px", "80px", "110px"]} fontSize={[13, 14, 15, 16]}>
								{t("Warehouses.documentRemove")}
							</Text>
						</Flex>
						<Flex minW={["80px", "80px", "110px"]}>
							<Text maxW={["80px", "80px", "110px"]} fontSize={[13, 14, 15, 16]}>
								{t("Warehouses.inventory")}
							</Text>
						</Flex>
					</Flex>
					{smallerRoles}
				</Box>
			)}
			<Flex h='100%' minH={["100px", "150px", "200px", "150px"]} alignItems='end' justifyContent='end'>
				<Button
					aria-label='Save button'
					isDisabled={isSubmitting}
					type='submit'
					fontSize={[13, 14, 15, 16]}
					bgColor='green.600'
					_hover={{ backgroundColor: "green.700" }}
					colorScheme='green'
					h={["30px", "35px", "40px"]}
				>
					<Text aria-label='Save button'>{t("UsersSettings.save")}</Text>
				</Button>
			</Flex>
		</>
	)
}

export default memo(WarehouseRoles)
