import { Box } from "@chakra-ui/react"

const WhiteBackground = ({ children, ...props }) => {
	return (
		<Box
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			bgColor='#FFF'
			px={4}
			py={6}
			{...props}
		>
			{children}
		</Box>
	)
}

export default WhiteBackground
