import { Button } from "@chakra-ui/react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const InventoryCopyButton = ({ data, isDisabled }) => {
	const { t } = useTranslation("global")
	const currentWarehouse = useSelector((state) => state.warehouses.currentWarehouse)

	const copyToClipboard = (text) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {})
			.catch((error) => {
				console.error("Błąd podczas kopiowania do schowka: ", error)
			})
	}

	const formattedData = useMemo(() => {
		if (!data || !data[0]?.name) return
		const dataKeys = [
			t("Warehouses.materialGroup"),
			t("Warehouses.material"),
			t("Warehouses.warehouseState"),
			t("Warehouses.factState"),
		]

		const columnWidths = {}

		const materialGroupMaxWidth = Math.max(...data.map((item) => item.name?.length))
		columnWidths[t("Warehouses.materialGroup")] = Math.max(
			t("Warehouses.materialGroup").length,
			materialGroupMaxWidth,
		)

		let materialNamesLength = []
		data.forEach((item) => item.materials.forEach((material) => materialNamesLength.push(material.name.length)))
		const materialMaxWidth = Math.max(...materialNamesLength)
		columnWidths[t("Warehouses.material")] = Math.max(t("Warehouses.material").length, materialMaxWidth)

		let warehouseStateLength = []
		data.forEach((item) =>
			item.materials.forEach((material) => warehouseStateLength.push(material.warehouse_state.toString().length)),
		)
		const warehouseStateMaxWidth = Math.max(...warehouseStateLength)
		columnWidths[t("Warehouses.warehouseState")] = Math.max(
			t("Warehouses.warehouseState").length,
			warehouseStateMaxWidth,
		)

		let factStateLength = []
		data.forEach((item) =>
			item.materials.forEach((material) => factStateLength.push(material.fact_state.toString().length)),
		)
		const factStateMaxWidth = Math.max(...factStateLength)
		columnWidths[t("Warehouses.factState")] = Math.max(t("Warehouses.factState").length, factStateMaxWidth)

		let formattedText = `${currentWarehouse?.name}\n\n`

		formattedText += dataKeys.map((header) => header.padEnd(columnWidths[header])).join("\t") + "\n"

		data.forEach((item) => {
			let body = ""
			body += String(item.name).padEnd(columnWidths[t("Warehouses.materialGroup")])
			body += "\t"
			item.materials.forEach((material, index) => {
				body += String(material.name).padEnd(columnWidths[t("Warehouses.material")])
				body += "\t"
				body += String(material.warehouse_state).padEnd(columnWidths[t("Warehouses.warehouseState")])
				body += "\t"
				body += String(material.fact_state).padEnd(columnWidths[t("Warehouses.factState")])
				body += "\n"
				if (index === item.materials.length - 1) return
				body += "".padEnd(columnWidths[t("Warehouses.materialGroup")])
				body += "\t"
			})

			body += "\n"

			formattedText += body
		})

		return formattedText
	}, [data])

	const handleCopyClick = () => {
		copyToClipboard(formattedData)
	}

	return (
		<Button
			isDisabled={isDisabled}
			fontSize={[13, 14, 15, 16]}
			fontWeight={"400"}
			h={["25px", "35px", "40px"]}
			bgColor={"#FFF"}
			borderRadius={"4px"}
			boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
			onClick={handleCopyClick}
		>
			{t("Warehouses.copy")}
		</Button>
	)
}

export default InventoryCopyButton
