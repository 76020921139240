import { Box, Container, Heading } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"

import Animation from "../../../../utils/animations/Routes/AnimatedPage"
import ExampleIcon from "../../../../assets/icons/header/ExampleIcon"
import { getNewOrderData } from "../../../../actions/modules/orders/shop/shop-actions"
import { clearNewOrderData } from "../../../../store/modules/orders/shop/shop-slice"
import NewOrderForm from "../../../../components/Modules/Orders/Shop/NewOrderForm/NewOrderForm"
import BackForwardButtons from "../../../../components/common/BackForwardButtons"
import LoadingHOC from "../../../../components/common/LoadingHOC"

const Shop = () => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const location = useLocation()
	const modules = useSelector((state) => state.auth.modules)
	const newOrderData = useSelector((state) => state.shop.newOrderData)
	const [initialValues, setInitialValues] = useState()

	const getReversedDate = (date) => {
		const dateArray = date.split("-")
		return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`
	}

	useEffect(() => {
		if (location.location !== location.pathname) {
			setInitialValues(null)
		}
	}, [location.location, location.pathname])

	useEffect(() => {
		if (newOrderData && !initialValues) {
			if (location.state) {
				const initValue = {
					id: location.state.orderDetails.id,
					project_id: location.state.orderDetails.project_id,
					part: location.state.orderDetails.part !== null ? location.state.orderDetails.part : "",
					building_address: location.state.orderDetails.building_address,
					order_for: location.state.orderDetails.order_for_id,
					contact_person:
						location.state.orderDetails.contact_person !== null
							? location.state.orderDetails.contact_person.map((contact) => contact.id)
							: [],
					comments: location.state.orderDetails.comments !== null ? location.state.orderDetails.comments : "",
					delivery_date: getReversedDate(location.state.orderDetails.delivery_date),
					priority: location.state.orderDetails.priority,
					attachments: location.state.orderDetails.attachments,
				}

				location.state.isCopy && delete initValue.id
				setInitialValues(initValue)
			} else {
				setInitialValues({
					project_id: "",
					part: "",
					building_address: "",
					order_for: "",
					contact_person: [],
					comments: "",
					delivery_date: "",
					priority: 2,
					attachments: [],
				})
			}
		}
	}, [initialValues, location, location.copy, location.state, newOrderData])

	useEffect(() => {
		dispatch(getNewOrderData())

		return () => dispatch(clearNewOrderData())
	}, [dispatch])

	const getHeadingIcon = useCallback((iconName) => {
		const boxSize = [5]
		const me = [2]
		if (!iconName) return <ExampleIcon boxSize={boxSize} me={me} />
		const Image = require(`../../../../assets/icons/header/${iconName}`).default
		return <Image boxSize={boxSize} me={me} />
	}, [])

	const getHeadingColor = useCallback(() => {
		const color = modules.find((module) => location.pathname.includes(module.url)).primary_color
		return color ? color : "#FFF"
	}, [modules, location.pathname])

	const getHeadingFontColor = useCallback(() => {
		const color = modules.find((module) => location.pathname.includes(module.url))?.font_color
		return color ? color : "#333"
	}, [modules, location.pathname])

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Box display='inline-flex' mt={2} alignItems='center' mb={[2, 2, 2, 0]}>
					<BackForwardButtons />
					<Heading
						display='flex'
						alignItems='center'
						bgColor={getHeadingColor}
						color={getHeadingFontColor}
						py={2}
						px={3}
						borderRadius='24px'
						fontSize={["14", "14", "16"]}
						boxShadow={"0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)"}
						ms={2}
						fontWeight={600}
					>
						{getHeadingIcon("BasketCart")}
						{location.state && !location.state.isCopy
							? `${t("Shop.editHeading")} - ${location.state.orderDetails.number}`
							: t("Shop.heading")}
					</Heading>
				</Box>
				<LoadingHOC isLoading={newOrderData && initialValues}>
					<NewOrderForm gettedData={newOrderData} initialValues={initialValues} />
				</LoadingHOC>
			</Container>
		</Animation>
	)
}

export default Shop
