import {
	Button,
	FormControl,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Textarea,
} from "@chakra-ui/react"
import { Field, Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { saveComment } from "../../../../../actions/modules/orders/shop/shop-actions"

const MaterialComment = ({ isOpen, onClose, isMyOrder, setDisabledForm, comment, orderId, orderState }) => {
	const hasAccess = useSelector((state) => state.auth.order_module_permissions)?.includes("edit_order")
	const { t } = useTranslation("global")
	const dispatch = useDispatch()

	return (
		<Formik
			initialValues={{
				comment: comment || "",
			}}
			onSubmit={(values, actions) => {
				if (comment ? values.comment !== comment : values.comment !== "") {
					actions.setSubmitting(true)
					setDisabledForm(true)
					dispatch(saveComment({ id: orderId, comment: values.comment })).then(() => {
						setDisabledForm(false)
						actions.setSubmitting(false)
						onClose()
					})
				} else {
					actions.setSubmitting(false)
				}
			}}
		>
			{(formik) => (
				<Form>
					<Modal motionPreset='slideInBottom' autoFocus isOpen={isOpen} onClose={onClose} isCentered>
						<ModalOverlay />
						<ModalContent>
							<ModalHeader fontSize={[14, 15, 16, 17]}>{t("MaterialComment.header")}</ModalHeader>
							<ModalCloseButton size={["sm", "sm", "md"]} />
							<ModalBody pb={6}>
								<FormControl>
									<Field
										disabled={formik.isSubmitting || orderState === 5 || (!hasAccess && !isMyOrder)}
										fontSize={[13, 14, 15, 16]}
										minH='60px'
										h={["60px", "70px", "90px"]}
										as={Textarea}
										my='0'
										name='comment'
										placeholder={t("MaterialComment.commentPlaceholder")}
									/>
								</FormControl>
							</ModalBody>
							<ModalFooter>
								<Button
									isDisabled={formik.isSubmitting}
									h={["25px", "30px", "35px", "40px"]}
									fontSize={[13, 14, 15, 16]}
									colorScheme='red'
									mr={3}
									onClick={onClose}
								>
									{t("MaterialComment.cancel")}
								</Button>
								<Button
									h={["25px", "30px", "35px", "40px"]}
									fontSize={[13, 14, 15, 16]}
									isDisabled={formik.isSubmitting || orderState === 5 || (!hasAccess && !isMyOrder)}
									colorScheme='green'
									onClick={formik.handleSubmit}
								>
									{t("MaterialComment.save")}
								</Button>
							</ModalFooter>
						</ModalContent>
					</Modal>
				</Form>
			)}
		</Formik>
	)
}

export default MaterialComment
