import { Button, useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { BrowserView } from "react-device-detect"
import { PDFViewer } from "@react-pdf/renderer"
import { useEffect, useState } from "react"

import PDFModal from "../../../../common/Modal/PDFModal"
import MaterialPDF from "./MaterialPDF"

const MaterialPrintButton = ({ groupName, data, parameterNames }) => {
	const { t } = useTranslation("global")
	const [parameters, setParameters] = useState([])
	const { isOpen, onOpen, onClose } = useDisclosure()
	const currentWarehouse = useSelector((state) => state.warehouses.currentWarehouse)

	useEffect(() => {
		setParameters(parameterNames?.map((parameter) => parameter.name) || [])
	}, [parameterNames])

	return (
		<BrowserView>
			<Button
				isDisabled={!data?.length}
				fontSize={[13, 14, 15, 16]}
				fontWeight={"400"}
				h={["25px", "35px", "40px"]}
				bgColor={"#FFF"}
				borderRadius={"4px"}
				boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
				onClick={onOpen}
			>
				{t("Warehouses.print")}
			</Button>
			<PDFModal isOpen={isOpen} onClose={onClose}>
				<PDFViewer style={{ width: "100%", height: "75vh" }}>
					<MaterialPDF
						data={data}
						warehouseString={t("Warehouses.warehouse")}
						warehouseName={currentWarehouse?.name}
						tableHeaders={[
							t("Warehouses.material"),
							...parameters,
							t("Warehouses.quantity"),
							t("Warehouses.rack"),
							t("Warehouses.spaceOnTheRack"),
						]}
						tableFields={["name", ...parameters, "quantity", "rack", "space_on_the_rack"]}
						materialGroupString={t("Warehouses.materialGroup")}
						materialGroup={groupName}
					/>
				</PDFViewer>
			</PDFModal>
		</BrowserView>
	)
}

export default MaterialPrintButton
