import { Outlet, useParams } from "react-router-dom"
import { Box, Container, Divider, Flex } from "@chakra-ui/react"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import i18next from "i18next"

import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import WarehouseNavigation from "../../../../../components/Modules/Warehouse/Warehouses/WarehouseNavigation"
import WarehouseNavigationButtons from "../../../../../components/Modules/Warehouse/Warehouses/WarehouseNavigationButtons"
import { getCurrentWarehouse } from "../../../../../actions/modules/warehouse/warehouses/warehouses-actions"
import { clearCurrentWarehouse } from "../../../../../store/modules/warehouse/warehouses/warehouses-slice"
import LoadingHOC from "../../../../../components/common/LoadingHOC"

const WarehousesNavigation = () => {
	const dispatch = useDispatch()
	const { warehouseId } = useParams()
	const currentWarehouse = useSelector((state) => state.warehouses.currentWarehouse)

	useEffect(() => {
		dispatch(getCurrentWarehouse(warehouseId))

		return () => dispatch(clearCurrentWarehouse())
	}, [i18next.language])

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<LoadingHOC isLoading={currentWarehouse}>
					<Flex
						justifyContent='space-between'
						flexWrap={{ base: "wrap", md: "nowrap" }}
						mt={2}
						gap={{ md: 2 }}
					>
						<Box display='inline-flex' alignItems='flex-start' mb={[2, 2, 2, 0]}>
							<WarehouseNavigation currentWarehouse={currentWarehouse} />
						</Box>
						<WarehouseNavigationButtons />
					</Flex>
					<Divider borderColor='#FFF' borderWidth='2px' my={4} />
					<Outlet />
				</LoadingHOC>
			</Container>
		</Animation>
	)
}

export default WarehousesNavigation
