import { Button } from "@chakra-ui/react"
import { memo } from "react"
import { PDFDownloadLink } from "@react-pdf/renderer"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { saveAs } from "file-saver"

import DocumentPDF from "./DocumentPDF"
import { getDateForFiles } from "../../../../../actions/modules/warehouse/warehouses/warehouses-actions"

const DocumentPDFButton = ({ data, isDisabled }) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const currentWarehouse = useSelector((state) => state.warehouses.currentWarehouse)

	const handleExportPDF = (e, blob) => {
		e.preventDefault()
		dispatch(getDateForFiles()).then((res) => {
			saveAs(blob, `${currentWarehouse?.name}_${res.payload?.date}`)
		})
	}

	return (
		<PDFDownloadLink
			document={
				<DocumentPDF
					data={data}
					warehouseString={t("Warehouses.warehouse")}
					documentTypes={{ pz: t("Warehouses.pz"), rw: t("Warehouses.rw"), inv: t("Warehouses.inv") }}
					documentStatuses={[t("Warehouses.open"), t("Warehouses.closed")]}
					warehouseName={currentWarehouse?.name}
					tableHeaders={[
						t("Warehouses.number"),
						t("Warehouses.status"),
						t("Warehouses.description"),
						t("Warehouses.created"),
						t("Warehouses.createdDate"),
					]}
				/>
			}
			fileName='YOUR_FILE_NAME'
		>
			{({ blob }) => (
				<Button
					isDisabled={isDisabled}
					fontSize={[13, 14, 15, 16]}
					fontWeight={"400"}
					h={["25px", "35px", "40px"]}
					bgColor={"#FFF"}
					borderRadius={"4px"}
					boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
					onClick={(e) => handleExportPDF(e, blob)}
				>
					PDF
				</Button>
			)}
		</PDFDownloadLink>
	)
}

export default memo(DocumentPDFButton)
