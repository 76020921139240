import { createSlice } from "@reduxjs/toolkit"

import removeSpecialCharacters from "../../utils/sorting/removeSpecialCharacters"
import {
	getAvailableModules,
	getPermissions,
	setAllUsersFunctionalitie,
	setApplicationFunctionalitie,
	setApplicationModules,
	setRoleFunctionalitie,
	setUserFunctionalitie,
} from "../../actions/permissions/permissions-actions"

/**
 * Slice for permissions
 */
const permissionsSlice = createSlice({
	name: "auth",
	initialState: {
		modules: [],
		permissions: [],
		permissionsCopy: [],
		availableModules: [],
		availableModulesCopy: [],
	},
	reducers: {
		clearAvailableModules(state) {
			state.availableModules = []
			state.availableModulesCopy = []
		},
		clearPermissions(state) {
			state.modules = []
			state.permissions = []
			state.permissionsCopy = []
			state.availableModules = []
			state.availableModulesCopy = []
		},
		sortRoles(state, action) {
			state.permissions.sort((a, b) => {
				const nameA = removeSpecialCharacters(a.name)
				const nameB = removeSpecialCharacters(b.name)

				if (action.payload === "asc") {
					return nameA.localeCompare(nameB, undefined, { sensitivity: "base" })
				} else {
					return nameB.localeCompare(nameA, undefined, { sensitivity: "base" })
				}
			})
		},
		sortAvailableModules(state, action) {
			state.availableModules.sort((a, b) => {
				if (action.payload === "asc") {
					return a.name.localeCompare(b.name)
				} else {
					return b.name.localeCompare(a.name)
				}
			})
		},
		filterRolesAndUsers(state, action) {
			if (action.payload.length === 0) {
				state.permissions = state.permissionsCopy
				return
			}
			state.permissions = state.permissionsCopy.filter(
				(role) =>
					role.name.toLowerCase().includes(action.payload.toLowerCase()) ||
					role.users.some((user) => user.name.toLowerCase().includes(action.payload.toLowerCase())),
			)
		},
		filterAvailableModules(state, action) {
			if (action.payload.length === 0) {
				state.availableModules = state.availableModulesCopy
				return
			}
			state.availableModules = state.availableModulesCopy.filter(
				(role) =>
					role.name.toLowerCase().includes(action.payload.toLowerCase()) ||
					role.module_functionalities.some((functionality) =>
						functionality.name.toLowerCase().includes(action.payload.toLowerCase()),
					),
			)
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPermissions.fulfilled, (state, action) => {
				state.modules = action.payload.modules
				state.permissions = action.payload.permissions
				state.permissionsCopy = action.payload.permissions
			})
			.addCase(setUserFunctionalitie.fulfilled, (state, action) => {
				state.permissions = state.permissions.map((role) => {
					if (role.id === action.meta.arg.roleId) {
						role.users.forEach((user) => {
							if (user.id === action.meta.arg.userId) {
								user.modules.forEach((module) => {
									module.module_functionalities.forEach((moduleFunctionality) => {
										if (moduleFunctionality.id === action.meta.arg.moduleFunctionalitieId) {
											moduleFunctionality.is_set = action.meta.arg.isSet === 1 ? 0 : 1
										}
									})
								})
							}
						})

						const allUsersIsSet = role.users.every((user) =>
							user.modules.some(
								(functionality) =>
									functionality.id === action.meta.arg.moduleId &&
									functionality.module_functionalities.some(
										(moduleFunctionality) =>
											moduleFunctionality.id === action.meta.arg.moduleFunctionalitieId &&
											moduleFunctionality.is_set === 1,
									),
							),
						)

						const allUsersIsUnSet = role.users.every((user) =>
							user.modules.some(
								(functionality) =>
									functionality.id === action.meta.arg.moduleId &&
									functionality.module_functionalities.some(
										(moduleFunctionality) =>
											moduleFunctionality.id === action.meta.arg.moduleFunctionalitieId &&
											moduleFunctionality.is_set === 0,
									),
							),
						)

						if (allUsersIsSet) {
							role.states.forEach((state) =>
								state.module_functionalities.forEach((moduleFunctionality) => {
									if (
										moduleFunctionality.module_functionality_id ===
										action.meta.arg.moduleFunctionalitieId
									) {
										moduleFunctionality.state = 3
									}
								}),
							)
						} else if (allUsersIsUnSet) {
							role.states.forEach((state) =>
								state.module_functionalities.forEach((moduleFunctionality) => {
									if (
										moduleFunctionality.module_functionality_id ===
										action.meta.arg.moduleFunctionalitieId
									) {
										moduleFunctionality.state = 1
									}
								}),
							)
						} else {
							role.states.forEach((state) =>
								state.module_functionalities.forEach((moduleFunctionality) => {
									if (
										moduleFunctionality.module_functionality_id ===
										action.meta.arg.moduleFunctionalitieId
									) {
										moduleFunctionality.state = 2
									}
								}),
							)
						}
					}
					return role
				})

				state.permissionsCopy = state.permissionsCopy.map((role) => {
					if (role.id === action.meta.arg.roleId) {
						role.users.forEach((user) => {
							if (user.id === action.meta.arg.userId) {
								user.modules.forEach((module) => {
									module.module_functionalities.forEach((moduleFunctionality) => {
										if (moduleFunctionality.id === action.meta.arg.moduleFunctionalitieId) {
											moduleFunctionality.is_set = action.meta.arg.isSet === 1 ? 0 : 1
										}
									})
								})
							}
						})

						const allUsersIsSet = role.users.every((user) =>
							user.modules.some(
								(functionality) =>
									functionality.id === action.meta.arg.moduleId &&
									functionality.module_functionalities.some(
										(moduleFunctionality) =>
											moduleFunctionality.id === action.meta.arg.moduleFunctionalitieId &&
											moduleFunctionality.is_set === 1,
									),
							),
						)

						const allUsersIsUnSet = role.users.every((user) =>
							user.modules.some(
								(functionality) =>
									functionality.id === action.meta.arg.moduleId &&
									functionality.module_functionalities.some(
										(moduleFunctionality) =>
											moduleFunctionality.id === action.meta.arg.moduleFunctionalitieId &&
											moduleFunctionality.is_set === 0,
									),
							),
						)

						if (allUsersIsSet) {
							role.states.forEach((state) =>
								state.module_functionalities.forEach((moduleFunctionality) => {
									if (
										moduleFunctionality.module_functionality_id ===
										action.meta.arg.moduleFunctionalitieId
									) {
										moduleFunctionality.state = 3
									}
								}),
							)
						} else if (allUsersIsUnSet) {
							role.states.forEach((state) =>
								state.module_functionalities.forEach((moduleFunctionality) => {
									if (
										moduleFunctionality.module_functionality_id ===
										action.meta.arg.moduleFunctionalitieId
									) {
										moduleFunctionality.state = 1
									}
								}),
							)
						} else {
							role.states.forEach((state) =>
								state.module_functionalities.forEach((moduleFunctionality) => {
									if (
										moduleFunctionality.module_functionality_id ===
										action.meta.arg.moduleFunctionalitieId
									) {
										moduleFunctionality.state = 2
									}
								}),
							)
						}
					}
					return role
				})

				const allFunctionalitiesState3 = state.permissionsCopy.every((role) =>
					role.states.some(
						(state) =>
							state.id === action.meta.arg.moduleId &&
							state.module_functionalities.some(
								(functionality) =>
									functionality.module_functionality_id === action.meta.arg.moduleFunctionalitieId &&
									functionality.state === 3,
							),
					),
				)

				const allFunctionalitiesState1 = state.permissionsCopy.every((role) =>
					role.states.some(
						(state) =>
							state.id === action.meta.arg.moduleId &&
							state.module_functionalities.some(
								(functionality) =>
									functionality.module_functionality_id === action.meta.arg.moduleFunctionalitieId &&
									functionality.state === 1,
							),
					),
				)

				state.modules = state.modules.map((module) => {
					if (allFunctionalitiesState3) {
						module.module_functionalities.forEach((moduleFunctionality) => {
							if (moduleFunctionality.id === action.meta.arg.moduleFunctionalitieId) {
								moduleFunctionality.state = 3
							}
						})
					} else if (allFunctionalitiesState1) {
						module.module_functionalities.forEach((moduleFunctionality) => {
							if (moduleFunctionality.id === action.meta.arg.moduleFunctionalitieId) {
								moduleFunctionality.state = 1
							}
						})
					} else {
						module.module_functionalities.forEach((moduleFunctionality) => {
							if (moduleFunctionality.id === action.meta.arg.moduleFunctionalitieId) {
								moduleFunctionality.state = 2
							}
						})
					}
					return module
				})
			})
			.addCase(setRoleFunctionalitie.fulfilled, (state, action) => {
				state.permissions = state.permissions.map((role) => {
					if (role.id === action.meta.arg.roleId) {
						role.states.forEach((state) =>
							state.module_functionalities.forEach((moduleFunctionality) => {
								if (moduleFunctionality.module_functionality_id === action.meta.arg.functionalityId) {
									moduleFunctionality.state = action.meta.arg.state !== 3 ? 3 : 1
								}
							}),
						)
						role.users.forEach((user) =>
							user.modules.forEach((module) =>
								module.module_functionalities.forEach((moduleFunctionality) => {
									if (moduleFunctionality.id === action.meta.arg.functionalityId) {
										moduleFunctionality.is_set = action.meta.arg.state !== 3 ? 1 : 0
									}
								}),
							),
						)
					}
					return role
				})

				state.permissionsCopy = state.permissionsCopy.map((role) => {
					if (role.id === action.meta.arg.roleId) {
						role.states.forEach((state) =>
							state.module_functionalities.forEach((moduleFunctionality) => {
								if (moduleFunctionality.module_functionality_id === action.meta.arg.functionalityId) {
									moduleFunctionality.state = action.meta.arg.state !== 3 ? 3 : 1
								}
							}),
						)
						role.users.forEach((user) =>
							user.modules.forEach((module) =>
								module.module_functionalities.forEach((moduleFunctionality) => {
									if (moduleFunctionality.id === action.meta.arg.functionalityId) {
										moduleFunctionality.is_set = action.meta.arg.state !== 3 ? 1 : 0
									}
								}),
							),
						)
					}
					return role
				})

				const allFunctionalitiesState3 = state.permissionsCopy.every((role) =>
					role.states.some(
						(state) =>
							state.id === action.meta.arg.stateId &&
							state.module_functionalities.some(
								(functionality) =>
									functionality.module_functionality_id === action.meta.arg.functionalityId &&
									functionality.state === 3,
							),
					),
				)

				const allFunctionalitiesState1 = state.permissionsCopy.every((role) =>
					role.states.some(
						(state) =>
							state.id === action.meta.arg.stateId &&
							state.module_functionalities.some(
								(functionality) =>
									functionality.module_functionality_id === action.meta.arg.functionalityId &&
									functionality.state === 1,
							),
					),
				)

				state.modules = state.modules.map((module) => {
					if (allFunctionalitiesState3) {
						module.module_functionalities.forEach((moduleFunctionality) => {
							if (moduleFunctionality.id === action.meta.arg.functionalityId) {
								moduleFunctionality.state = 3
							}
						})
					} else if (allFunctionalitiesState1) {
						module.module_functionalities.forEach((moduleFunctionality) => {
							if (moduleFunctionality.id === action.meta.arg.functionalityId) {
								moduleFunctionality.state = 1
							}
						})
					} else {
						module.module_functionalities.forEach((moduleFunctionality) => {
							if (moduleFunctionality.id === action.meta.arg.functionalityId) {
								moduleFunctionality.state = 2
							}
						})
					}
					return module
				})
			})
			.addCase(setAllUsersFunctionalitie.fulfilled, (state, action) => {
				state.modules = state.modules.map((module) => {
					module.module_functionalities.forEach((moduleFunctionality) => {
						if (moduleFunctionality.id === action.meta.arg.functionalityId) {
							moduleFunctionality.state = action.meta.arg.state !== 3 ? 3 : 1
						}
					})
					return module
				})

				state.permissions = state.permissions.map((role) => {
					role.states.forEach((state) =>
						state.module_functionalities.forEach((moduleFunctionality) => {
							if (moduleFunctionality.module_functionality_id === action.meta.arg.functionalityId) {
								moduleFunctionality.state = action.meta.arg.state !== 3 ? 3 : 1
							}
						}),
					)
					role.users.forEach((user) =>
						user.modules.forEach((module) =>
							module.module_functionalities.forEach((moduleFunctionality) => {
								if (moduleFunctionality.id === action.meta.arg.functionalityId) {
									moduleFunctionality.is_set = action.meta.arg.state !== 3 ? 1 : 0
								}
							}),
						),
					)
					return role
				})

				state.permissionsCopy = state.permissionsCopy.map((role) => {
					role.states.forEach((state) =>
						state.module_functionalities.forEach((moduleFunctionality) => {
							if (moduleFunctionality.module_functionality_id === action.meta.arg.functionalityId) {
								moduleFunctionality.state = action.meta.arg.state !== 3 ? 3 : 1
							}
						}),
					)
					role.users.forEach((user) =>
						user.modules.forEach((module) =>
							module.module_functionalities.forEach((moduleFunctionality) => {
								if (moduleFunctionality.id === action.meta.arg.functionalityId) {
									moduleFunctionality.is_set = action.meta.arg.state !== 3 ? 1 : 0
								}
							}),
						),
					)
					return role
				})
			})
			.addCase(getAvailableModules.fulfilled, (state, action) => {
				state.availableModules = action.payload.data
				state.availableModulesCopy = action.payload.data
			})
			.addCase(setApplicationFunctionalitie.fulfilled, (state, action) => {
				state.availableModules = state.availableModules.map((module) => {
					if (module.id === action.meta.arg.moduleId) {
						module.module_functionalities.forEach((moduleFunctionality) => {
							if (moduleFunctionality.id === action.meta.arg.moduleFunctionalityId) {
								moduleFunctionality.visibility_state = action.meta.arg.state === 3 ? 1 : 3
							}
						})

						const allFunctionalitiesState3 = module.module_functionalities.every(
							(moduleFunctionality) => moduleFunctionality.visibility_state === 3,
						)

						const allFunctionalitiesState1 = module.module_functionalities.every(
							(moduleFunctionality) => moduleFunctionality.visibility_state === 1,
						)

						if (allFunctionalitiesState3) {
							module.visibility_state = 3
						} else if (allFunctionalitiesState1) {
							module.visibility_state = 1
						} else {
							module.visibility_state = 2
						}
					}
					return module
				})

				state.availableModulesCopy = state.availableModulesCopy.map((module) => {
					if (module.id === action.meta.arg.moduleId) {
						module.module_functionalities.forEach((moduleFunctionality) => {
							if (moduleFunctionality.id === action.meta.arg.moduleFunctionalityId) {
								moduleFunctionality.visibility_state = action.meta.arg.state === 3 ? 1 : 3
							}
						})

						const allFunctionalitiesState3 = module.module_functionalities.every(
							(moduleFunctionality) => moduleFunctionality.visibility_state === 3,
						)

						const allFunctionalitiesState1 = module.module_functionalities.every(
							(moduleFunctionality) => moduleFunctionality.visibility_state === 1,
						)

						if (allFunctionalitiesState3) {
							module.visibility_state = 3
						} else if (allFunctionalitiesState1) {
							module.visibility_state = 1
						} else {
							module.visibility_state = 2
						}
					}
					return module
				})
			})
			.addCase(setApplicationModules.fulfilled, (state, action) => {
				state.availableModules = state.availableModules.map((module) => {
					if (module.id === action.meta.arg.moduleId) {
						module.module_functionalities.forEach((moduleFunctionality) => {
							if (moduleFunctionality.module_id === action.meta.arg.moduleId) {
								moduleFunctionality.visibility_state = action.meta.arg.state !== 3 ? 3 : 1
							}
						})
						module.visibility_state = action.meta.arg.state !== 3 ? 3 : 1
					}
					return module
				})

				state.availableModulesCopy = state.availableModulesCopy.map((module) => {
					if (module.id === action.meta.arg.moduleId) {
						module.module_functionalities.forEach((moduleFunctionality) => {
							if (moduleFunctionality.module_id === action.meta.arg.moduleId) {
								moduleFunctionality.visibility_state = action.meta.arg.state !== 3 ? 3 : 1
							}
						})
						module.visibility_state = action.meta.arg.state !== 3 ? 3 : 1
					}
					return module
				})
			})
	},
})

export const {
	clearAvailableModules,
	clearPermissions,
	sortRoles,
	sortAvailableModules,
	filterRolesAndUsers,
	filterAvailableModules,
} = permissionsSlice.actions

export default permissionsSlice
