import { createSlice } from "@reduxjs/toolkit"
import { addRoles, getUsersSettings, setUsersRoles } from "../../../../actions/modules/users/settings/settings-actions"

const settingsSlice = createSlice({
	name: "usersSettings",
	initialState: {
		config: null,
	},
	reducers: {
		clearConfig(state) {
			state.config = null
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUsersSettings.fulfilled, (state, action) => {
				state.config = action.payload
			})
			.addCase(addRoles.fulfilled, (state, action) => {
				state.config.user_group_permissions.config = action.payload.config
				state.config.user_user_group_permissions.config = action.payload.users.config
			})
			.addCase(setUsersRoles.fulfilled, (state, action) => {
				const userGroupsPermissions = state.config.user_user_group_permissions.config.map((user) => {
					const matchingItem = action.payload.config.find((item) => user.id === item.id)

					if (matchingItem) {
						user.user_group_permission_id = matchingItem.user_group_permission_id
					}

					return user
				})

				state.config.user_user_group_permissions.config = userGroupsPermissions
				state.config.user_group_permissions.config = action.payload.roles.config
			})
	},
})

export const { clearConfig } = settingsSlice.actions

export default settingsSlice
