import React, { memo, useCallback, useEffect, useRef, useState } from "react"
import { Button, Flex, Input } from "@chakra-ui/react"
import { motion, AnimatePresence } from "framer-motion"
import { SearchIcon } from "@chakra-ui/icons"
import { useTranslation } from "react-i18next"

const SearchBar = ({
	buttonStyles,
	isDisabled,
	searchText,
	iconBoxSize = [3, 3, 4],
	setSearchText,
	originalArray,
	handleSetResults,
	fieldsToFilter,
	dependencies,
	...props
}) => {
	const { t } = useTranslation("global")
	const [isExpanded, setIsExpanded] = useState(false)
	const inputRef = useRef()

	useEffect(() => {
		if (!isExpanded) return

		inputRef.current.focus()
	}, [isExpanded])

	const handleButtonClick = () => {
		setIsExpanded(!isExpanded)
	}

	const handleFilterByDependencies = (item) =>
		dependencies.every((field) => {
			const fieldValue = item[field.name]?.toString().toLowerCase()
			return (
				typeof fieldValue && (field.value === "" || fieldValue.includes(field.value.toString().toLowerCase()))
			)
		})

	const handleSearching = useCallback(
		(searchValue) => {
			if (!originalArray) return
			setSearchText(searchValue)
			if (searchValue === "") {
				if (!dependencies) handleSetResults(originalArray)
				else {
					const filteredGroups = originalArray.filter((item) => handleFilterByDependencies(item))
					handleSetResults(filteredGroups)
				}
			} else {
				const filteredGroups = originalArray.filter((item) => {
					const searchingResults = fieldsToFilter.some((field) => {
						const fieldValue = item[field]?.toString().toLowerCase()
						return (
							typeof fieldValue && (searchValue === "" || fieldValue?.includes(searchValue.toLowerCase()))
						)
					})
					if (!dependencies) return searchingResults

					return searchingResults && handleFilterByDependencies(item)
				})
				handleSetResults(filteredGroups)
			}
		},
		[setSearchText, originalArray, fieldsToFilter, handleSetResults],
	)

	return (
		<Flex>
			<AnimatePresence>
				{isExpanded && (
					<motion.div
						initial={{ width: "0px" }}
						animate={{ width: "auto" }}
						exit={{ width: "0px" }}
						transition={{ duration: 0.3 }}
					>
						<Input
							ref={inputRef}
							isDisabled={isDisabled}
							value={searchText}
							h={["35px", "35px", "40px"]}
							bgColor={"#FFF"}
							borderRight={isExpanded && "none"}
							borderRightRadius={isExpanded && 0}
							fontSize={[13, 14, 15, 16]}
							placeholder={t("Order.search")}
							type='text'
							onChange={(e) => handleSearching(e.target.value)}
							{...props}
						/>
					</motion.div>
				)}
			</AnimatePresence>
			<Button
				isDisabled={isDisabled}
				bgColor='#FFF'
				p={0}
				minW='30px'
				boxSize={["35px", "35px", "40px"]}
				border='1px solid #D4D4CD'
				borderRadius='4px'
				borderLeftRadius={isExpanded && 0}
				aria-label='Expand search input button'
				style={buttonStyles}
				onClick={handleButtonClick}
			>
				<SearchIcon boxSize={iconBoxSize} />
			</Button>
		</Flex>
	)
}

export default memo(SearchBar)
