import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react"
import { Field, useField } from "formik"

const TextField = ({ label, ...props }) => {
	const [field, meta] = useField(props)

	const fieldProps = {
		...field,
		...props,
	}

	return (
		<FormControl mb={["1", "1", "1", "2"]} isInvalid={meta.error && meta.touched}>
			<FormLabel my='0'>{label}</FormLabel>
			<Field as={Input} my='0' autoComplete='true' {...fieldProps} />
			<FormErrorMessage my='0'>{meta.error}</FormErrorMessage>
		</FormControl>
	)
}

export default TextField
