import { createIcon } from "@chakra-ui/react"

const PdfIcon = createIcon({
	displayName: "PdfIcon",
	viewBox: "0 0 22 22",
	path: (
		<>
			<path
				fill='currentColor'
				d='M19 18.5V6.625L13.375 1H6.5A2.5 2.5 0 004 3.5v15A2.5 2.5 0 006.5 21h10a2.5 2.5 0 002.5-2.5zM13.375 4.75a1.875 1.875 0 001.875 1.875h2.5V18.5a1.25 1.25 0 01-1.25 1.25h-10a1.25 1.25 0 01-1.25-1.25v-15A1.25 1.25 0 016.5 2.25h6.875v2.5z'
			/>
			<path
				fill='currentColor'
				d='M7.63 17.947a.98.98 0 01-.498-.54c-.221-.497-.147-.995.091-1.414.225-.394.598-.729 1.019-1.01a8.238 8.238 0 011.683-.828c.454-.923.857-1.878 1.206-2.858a10.194 10.194 0 01-.488-1.662c-.098-.513-.136-1.022-.053-1.458.086-.454.312-.862.739-1.056.218-.1.454-.154.683-.1a.756.756 0 01.318.166c.093.081.17.185.224.303.1.21.136.457.144.69.008.242-.014.51-.053.789-.096.654-.307 1.455-.59 2.302.313.758.685 1.482 1.112 2.164a5.788 5.788 0 011.515.064c.413.085.833.25 1.09.597.136.185.22.411.227.665.008.247-.053.49-.156.723-.09.215-.229.4-.402.534a.89.89 0 01-.58.177c-.376-.018-.742-.252-1.06-.535a7.024 7.024 0 01-1.034-1.22c-.768.099-1.526.273-2.267.521-.34.68-.727 1.329-1.159 1.938-.331.45-.691.842-1.052 1.01a.805.805 0 01-.66.038zm1.566-2.44c-.189.097-.364.2-.521.305-.373.25-.615.492-.735.702-.107.187-.11.321-.046.464a.3.3 0 00.03.056.278.278 0 00.04-.015c.155-.072.403-.302.72-.734.182-.25.352-.51.512-.778zm1.862-1.707c.38-.1.762-.183 1.147-.248-.207-.357-.4-.725-.579-1.101-.178.455-.367.904-.568 1.348zm2.778.577c.17.21.336.385.494.527.272.244.462.324.566.328a.11.11 0 00.079-.019.394.394 0 00.107-.16.613.613 0 00.067-.257.13.13 0 00-.03-.081c-.059-.08-.227-.195-.588-.268a3.914 3.914 0 00-.695-.068v-.002zm-2.26-4.5c.096-.348.172-.703.227-1.062.035-.241.05-.44.043-.597a.884.884 0 00-.036-.254.535.535 0 00-.165.051c-.098.045-.179.136-.222.364-.046.246-.034.601.052 1.055.027.142.061.29.102.444z'
			/>
		</>
	),
})

export default PdfIcon
