import { Container, Flex, Heading } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useState } from "react"

import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import BackForwardButtons from "../../../../../components/common/BackForwardButtons"
import GroupForm from "../../../../../components/Modules/Users/Groups/GroupForm/GroupForm"
import { createGroup } from "../../../../../actions/modules/users/groups/groups-actions"

const AddGroup = () => {
	const { t } = useTranslation("global")
	const [initialValues] = useState({
		name: "",
		name_pl: "",
		name_en: "",
		name_nl: "",
		background_color: "",
		text_color: "",
	})

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3} mt={2}>
				<Flex alignItems={"center"} gap={3}>
					<BackForwardButtons />
					<Heading fontWeight={600} fontSize={["15", "16", "17", "18", "19"]}>
						{t("Groups.addHeading")}
					</Heading>
				</Flex>
				<GroupForm initialValues={initialValues} onSubmit={createGroup} />
			</Container>
		</Animation>
	)
}

export default AddGroup
