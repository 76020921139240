import { createSlice } from "@reduxjs/toolkit"
import { getOrderList, removeOrderFromOrderList } from "../../../../actions/modules/orders/orderList/order-list-actions"

/**
 * Slice for order list
 */
const orderListSlice = createSlice({
	name: "orderList",
	initialState: {
		orderList: null,
		initialValues: null,
	},
	reducers: {
		clearOrderList(state) {
			state.orderList = null
			state.initialValues = null
		},
		sortOrderList(state, action) {
			state.orderList = action.payload.orderList
		},
		removeOrderFromList(state, action) {
			state.orderList = state.orderList.filter((order) => order.id !== action.payload.orderId)
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getOrderList.fulfilled, (state, action) => {
				state.orderList = action.payload.orders
				if (!action.payload.projects && !action.payload.created_by) return
				state.initialValues = {
					projects: [...action.payload.projects],
					created_by: [...action.payload.created_by],
				}
			})
			.addCase(removeOrderFromOrderList.fulfilled, (state, action) => {
				state.orderList = state.orderList.filter((order) => order.id !== action.meta.arg)
			})
	},
})

export const { clearOrderList, sortOrderList, removeOrderFromList } = orderListSlice.actions

export default orderListSlice
