import { Slide, toast } from "react-toastify"
import i18next from "i18next"

import pl from "../../translations/pl/Notifications/notifications.json"
import en from "../../translations/en/Notifications/notifications.json"
import nl from "../../translations/nl/Notifications/notifications.json"

const loadingToast = () => {
	let message
	switch (i18next.language) {
		case "pl":
			message = pl.loadingMessage
			break
		case "en":
			message = en.loadingMessage
			break
		case "nl":
			message = nl.loadingMessage
			break
		default:
			message = "Unknown language"
	}

	return toast.loading(message, {
		position: "bottom-center",
		closeOnClick: false,
		limit: 5,
		theme: "colored",
		transition: Slide,
	})
}

export default loadingToast
