import React, { memo, useCallback } from "react"
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import { Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import { useDispatch, useSelector } from "react-redux"

import { getDateForFiles } from "../../../../../actions/modules/warehouse/warehouses/warehouses-actions"

const DocumentsExcelButton = ({ jsonData, isDisabled }) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const currentWarehouse = useSelector((state) => state.warehouses.currentWarehouse)

	const handleExportExcel = () => {
		dispatch(getDateForFiles()).then((res) => {
			exportToExcel(res.payload?.date)
		})
	}

	const getCurrentStatus = (status) => {
		switch (status) {
			case 0:
				return t("Warehouses.open")
			case 1:
				return t("Warehouses.closed")
			default:
				return t("Warehouses.open")
		}
	}

	const exportToExcel = useCallback(
		(date) => {
			const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
			const fileExtension = ".xlsx"

			const wsData = XLSX.utils.aoa_to_sheet([
				[currentWarehouse?.name],
				[
					"",
					t("Warehouses.document_number"),
					t("Warehouses.status"),
					t("Warehouses.comments"),
					t("Warehouses.created"),
					t("Warehouses.document_date"),
				],
			])

			Object.keys(jsonData).forEach((item) => {
				const record = [t(`Warehouses.${item}`)]
				XLSX.utils.sheet_add_aoa(wsData, [record], {
					origin: "A",
				})
				jsonData[item].forEach((document) =>
					XLSX.utils.sheet_add_aoa(
						wsData,
						[
							[
								document.document_number,
								getCurrentStatus(document.status),
								document.comments,
								document.created,
								document.document_date,
							],
						],
						{
							origin: "B",
						},
					),
				)
			})

			const getColumnWidths = (data, fields, headers) => {
				const columnWidths = {}

				headers.forEach((header, index) => {
					columnWidths[fields[index]] = header.length
				})

				fields.forEach((field) => {
					Object.values(data).forEach((array) => {
						array.forEach((item) => {
							if (Object.prototype.hasOwnProperty.call(item, field)) {
								const length =
									field === "status"
										? getCurrentStatus(item[field]).toString().length
										: item[field].toString().length
								columnWidths[field] = Math.max(columnWidths[field], length + 2, 20)
							}
						})
					})
				})

				return columnWidths
			}

			const fieldsToCheck = ["document_number", "status", "comments", "created", "document_date"]
			const headers = [
				t("Warehouses.document_number"),
				t("Warehouses.status"),
				t("Warehouses.comments"),
				t("Warehouses.created"),
				t("Warehouses.document_date"),
			]
			const columnWidths = getColumnWidths(jsonData, fieldsToCheck, headers)
			const documentTypeWidth = [t("Warehouses.pz"), t("Warehouses.rw"), t("Warehouses.inv")]
			const maxDocumentTypeWidth = Math.max(...documentTypeWidth.map((str) => str.toString().length + 2))

			wsData["!cols"] = [
				{ wch: maxDocumentTypeWidth },
				{ wch: columnWidths.document_number },
				{ wch: columnWidths.status },
				{ wch: columnWidths.comments },
				{ wch: columnWidths.created },
				{ wch: columnWidths.document_date },
			]

			const wb = XLSX.utils.book_new()
			XLSX.utils.book_append_sheet(wb, wsData, "data")
			const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
			const dataBlob = new Blob([excelBuffer], { type: fileType })
			FileSaver.saveAs(dataBlob, `${currentWarehouse?.name}_${date}` + fileExtension)
		},
		[jsonData, i18next.language],
	)

	return (
		<Button
			isDisabled={isDisabled}
			fontSize={[13, 14, 15, 16]}
			fontWeight={"400"}
			h={["25px", "35px", "40px"]}
			bgColor={"#FFF"}
			borderRadius={"4px"}
			boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
			onClick={handleExportExcel}
		>
			Excel
		</Button>
	)
}

export default memo(DocumentsExcelButton)
