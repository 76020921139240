import { Button, Checkbox, Flex, Image, Text, useDisclosure } from "@chakra-ui/react"
import LackImageIcon from "../../../../assets/icons/materials/LackImageIcon"
import BinIcon from "../../../../assets/icons/materials/BinIcon"
import EditIcon from "../../../../assets/icons/materials/EditIcon"
import { ArrowForwardIcon } from "@chakra-ui/icons"
import { memo, useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import MaterialsIcon from "../../../../assets/icons/materials/Materials"
import { removeMaterialGroup } from "../../../../actions/modules/orders/materials/materials-actions"
import { useDispatch } from "react-redux"
import RemoveAlert from "../../../common/RemoveAlert"
import Pagination from "../../../common/Pagination"
import RecordsList from "../../../common/RecordsList"
import { useTranslation } from "react-i18next"
import i18next from "i18next"

const MaterialsGroups = ({
	materials,
	materialsCopy,
	isDisabled,
	setIsDisabled,
	paginationLength,
	handleChangePage,
	isSortDescByName,
	handleSortingByName,
	isSortDescByTurnOff,
	handleSortingByTurnOff,
}) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [itemIdToRemove, setItemIdToRemove] = useState(null)
	const [headers, setHeaders] = useState([])
	const [records, setRecords] = useState([])

	const handleNavigate = (id, name, isEditPreview = false, initialTab = 0) => {
		navigate(`${location.pathname}/${id}`, {
			state: {
				isEditPreview: isEditPreview,
				materialId: id,
				materialName: name,
				initialTab: initialTab,
			},
		})
	}

	const handleRemoveGroup = useCallback(() => {
		setIsDisabled(true)
		dispatch(removeMaterialGroup(itemIdToRemove)).then(() => {
			setItemIdToRemove(null)
			setIsDisabled(false)
			onClose()
		})
	}, [dispatch, itemIdToRemove, onClose])

	const handleCloseDialog = useCallback(() => {
		setItemIdToRemove(null)
		onClose()
	}, [onClose])

	const handleOpenDialog = (id) => {
		setItemIdToRemove(id)
		onOpen()
	}

	const getParameters = useCallback(
		(material) =>
			material.parameters.map((parameter, index) => (
				<Button
					key={parameter.id}
					display='flex'
					justifyContent='center'
					alignItems='center'
					p={0}
					minW={"25px"}
					boxSize={["25px", "27px", "29px", "30px"]}
					aria-label='Navigate parameter button'
					bgColor='#C7F4ED'
					onClick={() => handleNavigate(material.id, material.name, false, index + 1)}
				>
					{parameter.icon === null ? (
						<LackImageIcon alt='Lack of image' p={1} w='100%' h='100%' />
					) : (
						<Image
							src={parameter.icon}
							p={0}
							w='100%'
							h='100%'
							objectFit={"contain"}
							alt='Parameter icon'
							boxSize={[4, 4, 5]}
						/>
					)}
				</Button>
			)),
		[navigate, location.pathname],
	)

	const materialsHeaders = [
		{
			name: t("Materials.materialsGroup"),
			isDescSort: isSortDescByName,
			sortingMethod: handleSortingByName,
		},
		{
			name: t("Materials.image"),
		},
		{
			name: t("Materials.turnOffFromWarehouse"),
			isDescSort: isSortDescByTurnOff,
			sortingMethod: handleSortingByTurnOff,
		},
		{
			name: t("Materials.materialsGroupsDetails"),
		},
		{},
	]

	const getRecord = (material) => [
		{
			body: <Text wordBreak={"break-word"}>{material.name}</Text>,
		},
		{
			body: (
				<Flex h={["25px", "30px", "35px", "40px"]} w={["45px", "50px", "55px", "60px"]}>
					{material.image === null ? (
						<LackImageIcon alt='Lack of image' w='auto' h='100%' />
					) : (
						<Image src={material.image} alt='Material image' w='100%' h='100%' objectFit={"contain"} />
					)}
				</Flex>
			),
		},
		{
			body: (
				<Checkbox
					className='material-checkbox'
					isChecked={material.is_out_of_warehouse}
					boxSize={["20px", "22px", "25px", "27px"]}
					display='flex'
					justifyContent='center'
					iconSize={["10px", "12px", "14px", "17px"]}
					size='xs'
					borderRadius='4px'
					colorScheme='#E5E5DF'
					iconColor='#000'
					aria-label='Is material out of warehouse'
					cursor={"default"}
					bgColor={"#E5E5DF"}
				/>
			),
		},
		{
			body: (
				<Flex flexWrap='wrap' my={1} gap={2}>
					<Button
						isDisabled={isDisabled}
						display='flex'
						justifyContent='center'
						alignItems='center'
						p={0}
						minW={"25px"}
						boxSize={["25px", "27px", "29px", "30px"]}
						bgColor='#C7F4ED'
						_hover={{ backgroundColor: "teal.200" }}
						aria-label='Material icon'
						onClick={() => handleNavigate(material.id, material.name)}
					>
						<MaterialsIcon alt={"Material icon"} boxSize={[4, 4, 5]} />
					</Button>
					{getParameters(material)}
				</Flex>
			),
		},
		{
			body: (
				<Flex gap={2}>
					<Button
						isDisabled={isDisabled || material.is_used}
						bgColor='#E5E5DF'
						minW={"25px"}
						p={0}
						boxSize={["25px", "27px", "29px", "30px"]}
						aria-label='Remove button'
						onClick={() => handleOpenDialog(material.id)}
					>
						<BinIcon boxSize={[4, 4, 5]} color='red.500' />
					</Button>
					<Button
						isDisabled={isDisabled}
						bgColor='#E5E5DF'
						p={0}
						minW={"25px"}
						boxSize={["25px", "27px", "29px", "30px"]}
						aria-label='Edit button'
						onClick={() => handleNavigate(material.id, material.name, true)}
					>
						<EditIcon w={["16px", "18px", "19px", "20px"]} h={["18px", "23px", "21px", "22px"]} />
					</Button>
					<Button
						isDisabled={isDisabled}
						bgColor='#319795'
						colorScheme='teal'
						p={0}
						minW={"25px"}
						boxSize={["25px", "27px", "29px", "30px"]}
						aria-label='Preview button'
						onClick={() => handleNavigate(material.id, material.name)}
					>
						<ArrowForwardIcon
							w={["16px", "18px", "19px", "20px"]}
							h={["18px", "19px", "21px", "22px"]}
							color='#FFF'
						/>
					</Button>
				</Flex>
			),
			justifyContent: "flex-end",
		},
	]

	const getMaterialsRecords = (materials) =>
		materials?.map((material) => ({
			childrens: getRecord(material),
		}))

	useEffect(() => {
		setHeaders(materialsHeaders)
		setRecords(getMaterialsRecords(materials))
	}, [materials, i18next.language])

	return (
		<>
			<RecordsList headers={headers} records={records} />
			{!!headers.length && !!records.length && (
				<Pagination
					style={{
						marginTop: "10px",
						paddingBottom: "10px",
					}}
					totalRecords={paginationLength}
					array={materialsCopy}
					onPageChange={(page) => handleChangePage(page)}
				/>
			)}
			<RemoveAlert isOpen={isOpen} onClose={handleCloseDialog} handleConfirmRemove={handleRemoveGroup} />
		</>
	)
}

export default memo(MaterialsGroups)
