import { keyframes } from "@emotion/react"

export const circleAnimation = (size) => keyframes`
  0% {
    top: ${size === "sm" ? "30px" : size === "md" ? "40px" : "50px"};
    height: ${size === "sm" ? "5px" : size === "md" ? "6px" : "7px"};
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  ${size === "sm" ? "40%" : size === "md" ? "50%" : "40%"} {
    height: ${size === "sm" ? "10px" : size === "md" ? "15px" : "17px"};
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
`

export const shadowAnimation = (size) => keyframes`
  0% {
    transform: scaleX(1.5);
  }

  ${size === "sm" ? "39%" : size === "md" ? "46%" : "54%"} {
    transform: scaleX(1);
    opacity: .7;
  }

  100% {
    transform: scaleX(.2);
    opacity: .4;
  }
`
