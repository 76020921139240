import { createAsyncThunk } from "@reduxjs/toolkit"
import { handleRequest } from "../../../../utils/api/api"

/**
 * Get groups function with dispatch action
 */
export const getGroups = createAsyncThunk("groups/getGroups", async () => {
	const responseData = await handleRequest({
		endPoint: "/api/users/groups",
	})
	return responseData
})

/**
 * Get group function from id
 */
export const getGroup = createAsyncThunk("groups/getGroup", async (id) => {
	const responseData = await handleRequest({
		endPoint: `/api/users/groups/${id}`,
	})
	return responseData
})

/**
 * Remove group function by id
 */
export const removeGroup = createAsyncThunk("groups/removeGroup", async (id) => {
	await handleRequest({
		endPoint: `/api/users/groups/${id}`,
		method: "DELETE",
	})
})

/**
 * Create group function
 */
export const createGroup = createAsyncThunk("groups/createGroup", async (values) => {
	await handleRequest({
		endPoint: "/api/users/groups",
		method: "POST",
		body: values,
	})
})

/**
 * Edit group function by id
 */
export const editGroup = createAsyncThunk("groups/createGroup", async (values) => {
	await handleRequest({
		endPoint: `/api/users/groups/${values.id}`,
		method: "PATCH",
		body: values,
	})
})
