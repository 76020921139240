import { createIcon } from "@chakra-ui/react"

const IoPower = createIcon({
	displayName: "IoPower",
	viewBox: "0 0 24 24",
	path: (
		<>
			<path
				fill='currentColor'
				d='M12 21.75c-5.376 0-9.75-4.381-9.75-9.767 0-2.927 1.277-5.671 3.504-7.525a1.031 1.031 0 111.32 1.584 7.702 7.702 0 00-2.761 5.941c0 4.248 3.448 7.704 7.687 7.704 4.239 0 7.688-3.456 7.688-7.704a7.687 7.687 0 00-2.805-5.936 1.03 1.03 0 111.313-1.59 9.745 9.745 0 013.554 7.526c0 5.386-4.374 9.767-9.75 9.767z'
			/>
			<path
				fill='currentColor'
				d='M12 12.75a1.031 1.031 0 01-1.031-1.031V3.28a1.031 1.031 0 112.062 0v8.438A1.031 1.031 0 0112 12.75z'
			/>
		</>
	),
})

export default IoPower
