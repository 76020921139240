import { createIcon } from "@chakra-ui/react"

const BsCardList = createIcon({
	displayName: "BsCardList",
	viewBox: "0 0 20 21",
	path: (
		<>
			<path
				fill='currentColor'
				d='M18.125 4.5a.625.625 0 01.625.625v11.25a.624.624 0 01-.625.625H1.875a.625.625 0 01-.625-.625V5.125a.625.625 0 01.625-.625h16.25zM1.875 3.25A1.875 1.875 0 000 5.125v11.25a1.875 1.875 0 001.875 1.875h16.25A1.875 1.875 0 0020 16.375V5.125a1.875 1.875 0 00-1.875-1.875H1.875z'
			/>
			<path
				fill='currentColor'
				d='M6.667 11.166a.625.625 0 01.625-.625h8.75a.625.625 0 010 1.25h-8.75a.625.625 0 01-.625-.625zm0-3.125a.625.625 0 01.625-.625h8.75a.625.625 0 110 1.25h-8.75a.625.625 0 01-.625-.625zm0 6.25a.625.625 0 01.625-.625h8.75a.625.625 0 010 1.25h-8.75a.625.625 0 01-.625-.625zm-1.25-6.25a.625.625 0 11-1.25 0 .625.625 0 011.25 0zm0 3.125a.625.625 0 11-1.25 0 .625.625 0 011.25 0zm0 3.125a.625.625 0 11-1.25 0 .625.625 0 011.25 0z'
			/>
		</>
	),
})

export default BsCardList
