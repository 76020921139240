import { Box, Button, useColorModeValue } from "@chakra-ui/react"
import { useCallback, useEffect, useState } from "react"

import {
	stateIndefinite,
	stateOffline,
	stateOnline,
	stateOnlineByClick,
} from "../../utils/animations/Switch/switchAnimation"

const Switch = ({ style, state, onChange }) => {
	const [currentState, setCurrentState] = useState(state)
	const [currentStateAnimation, setCurrentStateAnimation] = useState(state % 3)

	const getState = useCallback((prevState) => {
		switch (prevState) {
			case 1:
				return 4
			case 2:
				return 3
			case 3:
				return 1
			case 4:
				return 1
			default:
				return 1
		}
	}, [])

	const handleSwitchClick = () => {
		setCurrentState((prevState) => getState(prevState))
		setCurrentStateAnimation((prevState) => getState(prevState))
		onChange()
	}

	useEffect(() => {
		setCurrentState(state)
		setCurrentStateAnimation(state % 3)
	}, [state])

	const getSwitchColor = useCallback(() => {
		switch (currentState) {
			case 1:
				return "#D4D4CD"
			case 2:
				return "#F6AD55"
			case 3:
				return "#48BB78"
			case 4:
				return "#48BB78"
			default:
				return "#D4D4CD"
		}
	}, [currentState])

	const getSwitchPosition = useCallback(() => {
		switch (currentState) {
			case 1:
				return "translateX(0)"
			case 2:
				return "translateX(50%)"
			case 3:
				return "translateX(100%)"
			case 4:
				return "translateX(100%)"
			default:
				return null
		}
	}, [currentState])

	const getSwitchAnimation = useCallback(() => {
		switch (currentStateAnimation) {
			case 1:
				return `${stateOffline} 0.3s ease-in-out forwards `
			case 2:
				return `${stateIndefinite} 0.3s ease-in-out forwards `
			case 3:
				return `${stateOnline} 0.3s ease-in-out forwards `
			case 4:
				return `${stateOnlineByClick} 0.3s ease-in-out forwards `
			default:
				return null
		}
	}, [currentStateAnimation])

	return (
		<Button
			aria-label='Switch button'
			bgColor={getSwitchColor}
			borderRadius='300'
			w='38px'
			h='22px'
			p={1}
			justifyContent='start'
			onClick={handleSwitchClick}
			_hover={{
				bgColor: useColorModeValue(
					`rgba(${parseInt(getSwitchColor().slice(1, 3), 16)}, ${parseInt(
						getSwitchColor().slice(3, 5),
						14,
					)}, ${parseInt(getSwitchColor().slice(5, 7), 16)}, 1)`,
					`rgba(${parseInt(getSwitchColor().slice(1, 3), 16)}, ${parseInt(
						getSwitchColor().slice(3, 5),
						14,
					)}, ${parseInt(getSwitchColor().slice(5, 7), 16)}, 0.8)`,
				),
			}}
			style={style}
		>
			<Box
				w='16px'
				h='16px'
				minW='16px'
				borderRadius={24}
				bgColor='#fff'
				transform={getSwitchPosition}
				animation={getSwitchAnimation}
			></Box>
		</Button>
	)
}

export default Switch
