import { Badge, Box, Button, Container, Divider, Flex, Text } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import Animation from "../../../../utils/animations/Routes/AnimatedPage"
import IoEyeOutline from "../../../../assets/icons/history/IoEyeOutline"
import { getOrdersHistory } from "../../../../actions/modules/orders/history/history-actions"
import { clearOrdersHistory, sortHistory } from "../../../../store/modules/orders/history/history-slice"
import Pagination from "../../../../components/common/Pagination"
import BackForwardButtons from "../../../../components/common/BackForwardButtons"
import ModuleHeading from "../../../../components/common/ModuleHeading"
import SelectWithFilteration from "../../../../components/common/SelectWithFilteration"
import LoadingHOC from "../../../../components/common/LoadingHOC"
import i18next from "i18next"
import StaticSearch from "../../../../components/common/StaticSearch"
import RecordsList from "../../../../components/common/RecordsList"

const History = () => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const ordersHistory = useSelector((state) => state.history.ordersHistory)
	const initialValues = useSelector((state) => state.history.initialValues)
	const [isProjectSortDesc, setIsProjectSortDesc] = useState(true)
	const [isCreatedSortDesc, setIsCreatedSortDesc] = useState(true)
	const [isPrioritySortDesc, setIsPrioritySortDesc] = useState(true)
	const [isNumberSortDesc, setIsNumberSortDesc] = useState(true)
	const [isDeliverySortDesc, setIsDeliverySortDesc] = useState(true)
	const [history, setHistory] = useState()
	const [historyCopy, setHistoryCopy] = useState()
	const [paginationLength, setPaginationLength] = useState(0)
	const [projectFilterValue, setProjectFilterValue] = useState("")
	const [createdFilterValue, setCreatedFilterValue] = useState("")
	const [priorityFilterValue, setPriorityFilterValue] = useState("")
	const [isLoading, setIsLoading] = useState(true)
	const [searchingTerm, setSearchingTerm] = useState("")
	const [historyListLength, setHistoryListLength] = useState()
	const [headers, setHeaders] = useState([])
	const [records, setRecords] = useState([])
	const navigate = useNavigate()

	const resetFields = useCallback(() => {
		setIsProjectSortDesc(true)
		setIsCreatedSortDesc(true)
		setIsPrioritySortDesc(true)
		setIsNumberSortDesc(true)
		setIsDeliverySortDesc(true)
		setHistory(null)
		setHistoryCopy(null)
		setPaginationLength(0)
		setSearchingTerm("")
		setProjectFilterValue("")
		setCreatedFilterValue("")
		setPriorityFilterValue("")
		setHistoryListLength()
		setIsLoading(true)
	}, [])

	useEffect(() => {
		resetFields()
		dispatch(getOrdersHistory()).then(() => setIsLoading(false))
	}, [i18next.language])

	useEffect(() => {
		if (ordersHistory && ordersHistory.length !== historyListLength) {
			resetFields()
			setHistoryListLength(ordersHistory.length)
			setHistory(ordersHistory.slice(0, 10))
			setHistoryCopy(ordersHistory)
			setPaginationLength(ordersHistory.length || 0)
			setIsLoading(false)
		}
	}, [ordersHistory])

	useEffect(() => {
		return () => dispatch(clearOrdersHistory())
	}, [dispatch])

	const handleChangePage = (page) => {
		const start = (page - 1) * 10
		const end = start + 10
		const newOrdersHistory = historyCopy.slice(start, end)
		setHistory(newOrdersHistory)
	}

	const handleEditOrder = useCallback(
		(id) => {
			navigate(`/${location.pathname.split("/")[1]}/shop/${id}`)
		},
		[location.pathname, navigate],
	)

	const handleSettingSort = useCallback(
		(setSortDesc, isSortDesc, sortingItem) => {
			if (!ordersHistory) return
			let sortedOrders
			let sortedOrdersList
			if (isSortDesc) {
				sortedOrders = [...historyCopy].sort((a, b) => {
					if (a[sortingItem].toString() > b[sortingItem].toString()) return -1
					if (a[sortingItem].toString() < b[sortingItem].toString()) return 1
					return 0
				})
				sortedOrdersList = [...ordersHistory].sort((a, b) => {
					if (a[sortingItem].toString() > b[sortingItem].toString()) return -1
					if (a[sortingItem].toString() < b[sortingItem].toString()) return 1
					return 0
				})
			} else {
				sortedOrders = [...historyCopy].sort((a, b) => {
					if (a[sortingItem].toString() < b[sortingItem].toString()) return -1
					if (a[sortingItem].toString() > b[sortingItem].toString()) return 1
					return 0
				})
				sortedOrdersList = [...ordersHistory].sort((a, b) => {
					if (a[sortingItem].toString() < b[sortingItem].toString()) return -1
					if (a[sortingItem].toString() > b[sortingItem].toString()) return 1
					return 0
				})
			}
			setHistoryCopy(sortedOrders)
			setHistory(sortedOrders.slice(0, 10))
			setSortDesc((prevState) => !prevState)
			dispatch(sortHistory({ ordersHistory: sortedOrdersList }))
		},
		[dispatch, historyCopy, ordersHistory],
	)

	const handleSortingByProject = () => {
		handleSettingSort(setIsProjectSortDesc, isProjectSortDesc, "project")
	}

	const handleSortingByCreated = useCallback(() => {
		if (!ordersHistory) return
		let sortedOrders
		let sortedOrdersList

		const dateExtractor = (string) => {
			const parts = string.split(" ")
			const dateString = parts[0]
			const timeString = parts[1].slice(1, -1)
			return new Date(dateString + "T" + timeString)
		}

		if (isCreatedSortDesc) {
			sortedOrders = [...historyCopy].sort((a, b) => dateExtractor(a.created) - dateExtractor(b.created))
			sortedOrdersList = [...ordersHistory].sort((a, b) => dateExtractor(a.created) - dateExtractor(b.created))
		} else {
			sortedOrders = [...historyCopy].sort((a, b) => dateExtractor(b.created) - dateExtractor(a.created))
			sortedOrdersList = [...ordersHistory].sort((a, b) => dateExtractor(b.created) - dateExtractor(a.created))
		}
		setHistoryCopy(sortedOrders)
		setHistory(sortedOrders.slice(0, 10))
		setIsCreatedSortDesc((prevState) => !prevState)
		dispatch(sortHistory({ ordersHistory: sortedOrdersList }))
	}, [dispatch, historyCopy, isCreatedSortDesc, ordersHistory])

	const handleSortingByPriority = () => {
		handleSettingSort(setIsPrioritySortDesc, isPrioritySortDesc, "priority")
	}

	const handleSortingByNumber = () => {
		handleSettingSort(setIsNumberSortDesc, isNumberSortDesc, "number")
	}

	const handleSortingByDelivery = useCallback(() => {
		if (!ordersHistory) return
		let sortedOrders
		let sortedOrdersList
		if (isDeliverySortDesc) {
			sortedOrders = [...historyCopy].sort((a, b) => a.delivery_date.localeCompare(b.delivery_date))
			sortedOrdersList = [...ordersHistory].sort((a, b) => a.delivery_date.localeCompare(b.delivery_date))
		} else {
			sortedOrders = [...historyCopy].sort((a, b) => b.delivery_date.localeCompare(a.delivery_date))
			sortedOrdersList = [...ordersHistory].sort((a, b) => b.delivery_date.localeCompare(a.delivery_date))
		}
		setHistoryCopy(sortedOrders)
		setHistory(sortedOrders.slice(0, 10))
		setIsDeliverySortDesc((prevState) => !prevState)
		dispatch(sortHistory({ ordersHistory: sortedOrdersList }))
	}, [dispatch, historyCopy, isDeliverySortDesc, ordersHistory])

	const handleSetResults = (filteredArray) => {
		setHistory(filteredArray.slice(0, 10))
		setHistoryCopy(filteredArray)
		setPaginationLength(filteredArray.length)
	}

	const historyHeaders = [
		{
			name: t("OrderList.project"),
			isDescSort: isProjectSortDesc,
			sortingMethod: handleSortingByProject,
		},
		{
			name: t("OrderList.created"),
			isDescSort: isCreatedSortDesc,
			sortingMethod: handleSortingByCreated,
		},
		{
			name: t("OrderList.priority"),
			isDescSort: isPrioritySortDesc,
			sortingMethod: handleSortingByPriority,
		},
		{
			name: t("OrderList.number"),
			isDescSort: isNumberSortDesc,
			sortingMethod: handleSortingByNumber,
		},
		{
			name: t("OrderList.delivery"),
			isDescSort: isDeliverySortDesc,
			sortingMethod: handleSortingByDelivery,
		},
		{
			name: t("OrderList.status"),
		},
	]

	const getRecord = (order) => [
		{
			body: <Text wordBreak={"break-word"}>{order.project}</Text>,
		},
		{
			body: <Text wordBreak={"break-word"}>{order.created}</Text>,
		},
		{
			body: (
				<Badge fontSize={[9, 10, 11, 12]} color='#FFF' bgColor={order.priority_color}>
					{order.priority}
				</Badge>
			),
		},
		{
			body: <Text wordBreak={"break-word"}>{order.number}</Text>,
		},
		{
			body: (
				<Badge fontSize={[9, 10, 11, 12]} bgColor='#F2F2EF'>
					{order.delivery_date}
				</Badge>
			),
		},
		{
			body: (
				<Flex justifyContent={"space-between"} w='100%' alignItems='center' py={2}>
					<Flex
						bgColor='green.50'
						borderLeft={"4px solid #48BB78"}
						borderRadius='4px'
						w='100%'
						justifyContent={["center", "space-between"]}
						alignItems='center'
						flexWrap={["wrap", "nowrap"]}
						gap={1}
						py={1}
						px={{ base: 1, md: 2 }}
					>
						<Text
							fontSize={{ base: "12px", md: "13px", lg: "14px" }}
							wordBreak={"break-word"}
							textAlign={"center"}
						>
							{t("History.realized")}
						</Text>
						<Flex w={["100%", "auto"]} justifyContent={["center", "center", "end"]}>
							<Button
								aria-label='Preview button'
								bgColor='green.300'
								minW='25px'
								p={0}
								boxSize={[5, 6, 7]}
								onClick={() => handleEditOrder(order.id)}
							>
								<IoEyeOutline w={["17px", "19px", "21px"]} h={["19px", "21px", "23px"]} />
							</Button>
						</Flex>
					</Flex>
				</Flex>
			),
		},
	]

	const getHistoryRecords = (history) =>
		history?.map((order) => ({
			childrens: getRecord(order),
		}))

	useEffect(() => {
		setHeaders(historyHeaders)
		setRecords(getHistoryRecords(history))
	}, [history, i18next.language])

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Box
					display='flex'
					justifyContent='space-between'
					flexWrap={{ base: "wrap", md: "nowrap" }}
					mt={2}
					gap={{ md: 2 }}
				>
					<Box display='inline-flex' alignItems='flex-start' mb={[2, 2, 2, 0]}>
						<Flex alignItems={"center"}>
							<BackForwardButtons />
							<ModuleHeading heading={t("History.heading")} />
						</Flex>
					</Box>
					<Box
						display='inline-flex'
						w={"100%"}
						gap={3}
						mt={[2, 0]}
						alignItems={"center"}
						flexWrap={"wrap"}
						justifyContent={"flex-end"}
					>
						<Flex gap={2} w={"100%"} justifyContent={"flex-end"} alignSelf={"flex-end"}>
							<StaticSearch
								fieldsToFilter={["number"]}
								isDisabled={!ordersHistory?.length}
								searchText={searchingTerm}
								setSearchText={setSearchingTerm}
								handleSetResults={handleSetResults}
								originalArray={ordersHistory}
								dependencies={[
									{ name: "project", value: projectFilterValue },
									{ name: "created", value: createdFilterValue },
									{ name: "priority", value: priorityFilterValue },
								]}
							/>
						</Flex>
						<Flex flexDirection={{ base: "column", md: "row" }} gap={[1, 1, 2]} alignItems={"center"}>
							<Text
								color='#2C7A7B'
								whiteSpace={"pre-wrap"}
								wordBreak={"break-all"}
								fontSize={[13, 14, 15, 16]}
							>
								{t("OrderList.project")}
							</Text>
							<SelectWithFilteration
								name='project'
								isDisabled={!ordersHistory?.length || !initialValues?.projects}
								value={projectFilterValue}
								setFilterValue={setProjectFilterValue}
								searchingBy={{ name: "number", value: searchingTerm }}
								filterFields={[
									{ name: "project", value: projectFilterValue },
									{ name: "created", value: createdFilterValue },
									{ name: "priority", value: priorityFilterValue },
								]}
								values={initialValues?.projects}
								array={ordersHistory}
								placeholder={t("Order.select")}
								handleSetResults={handleSetResults}
							/>
						</Flex>
						<Flex flexDirection={["column", "column", "row"]} gap={[1, 1, 2]} alignItems={"center"}>
							<Text
								color='#2C7A7B'
								whiteSpace={"pre-wrap"}
								wordBreak={"break-all"}
								fontSize={[13, 14, 15, 16]}
							>
								{t("OrderList.createdBy")}
							</Text>
							<SelectWithFilteration
								name='created'
								isDisabled={!ordersHistory?.length || !initialValues?.created_by}
								value={createdFilterValue}
								setFilterValue={setCreatedFilterValue}
								searchingBy={{ name: "number", value: searchingTerm }}
								filterFields={[
									{ name: "project", value: projectFilterValue },
									{ name: "created", value: createdFilterValue },
									{ name: "priority", value: priorityFilterValue },
								]}
								values={initialValues?.created_by}
								array={ordersHistory}
								placeholder={t("Order.select")}
								handleSetResults={handleSetResults}
							/>
						</Flex>
						<Flex flexDirection={["column", "column", "row"]} gap={[1, 1, 2]} alignItems={"center"}>
							<Text
								color='#2C7A7B'
								whiteSpace={"pre-wrap"}
								wordBreak={"break-all"}
								fontSize={[13, 14, 15, 16]}
							>
								{t("OrderList.priority")}
							</Text>
							<SelectWithFilteration
								name='priority'
								isDisabled={!ordersHistory?.length}
								value={priorityFilterValue}
								setFilterValue={setPriorityFilterValue}
								searchingBy={{ name: "number", value: searchingTerm }}
								filterFields={[
									{ name: "project", value: projectFilterValue },
									{ name: "created", value: createdFilterValue },
									{ name: "priority", value: priorityFilterValue },
								]}
								values={[
									{ id: 1, name: t("OrderList.low") },
									{ id: 2, name: t("OrderList.standard") },
									{ id: 3, name: t("OrderList.height") },
								]}
								array={ordersHistory}
								placeholder={t("Order.select")}
								handleSetResults={handleSetResults}
							/>
						</Flex>
					</Box>
				</Box>
				<Divider borderColor='#FFF' borderWidth={["1px", "2px"]} my={4} />
				<LoadingHOC isLoading={!isLoading}>
					{history?.length ? (
						<>
							<RecordsList headers={headers} records={records} />
							<Pagination
								style={{
									marginTop: "10px",
									paddingBottom: "10px",
								}}
								array={historyCopy}
								totalRecords={paginationLength}
								onPageChange={(page) => handleChangePage(page)}
							/>
						</>
					) : (
						<Flex justifyContent='center' alignItems='center' h='80%'>
							<Text fontSize='24px' letterSpacing='4px' fontWeight='600' color='#05050580'>
								{t("History.noData")}
							</Text>
						</Flex>
					)}
				</LoadingHOC>
			</Container>
		</Animation>
	)
}

export default History
