import { Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

const NoData = ({ boxStyles, ...props }) => {
	const { t } = useTranslation("global")

	return (
		<Flex justifyContent='center' alignItems='center' h='40vh' style={boxStyles}>
			<Text fontSize='24px' letterSpacing='4px' fontWeight='600' color='#05050580' {...props}>
				{t("Common.noData")}
			</Text>
		</Flex>
	)
}

export default NoData
