import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons"
import { FixedSizeList as List } from "react-window"
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	Input,
	Tag,
	TagCloseButton,
	TagLabel,
	Text,
	useBreakpointValue,
} from "@chakra-ui/react"
import { useField, useFormikContext } from "formik"
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

const OptionItem = ({ index, style, data, isDisabled }) => {
	const { handleSelect, field, options } = data
	const option = options[index]

	return (
		<Button
			key={option.id}
			isDisabled={isDisabled}
			position='relative'
			zIndex={10}
			display='flex'
			p={0}
			justifyContent='flex-start'
			w='100%'
			h={["30px", "35px", "40px"]}
			bgColor={option.name === field.value && option.name !== "" ? "#F2F2EF" : "#FFF"}
			_hover={{ backgroundColor: "rgba(0,0,0,0.03)" }}
			onMouseDown={() => handleSelect(option)}
			style={style}
		>
			<Box my={2} mx={3}>
				<Text aria-label='Select item button' fontSize={[13, 14, 15, 16]} mx={2}>
					{option.name}
				</Text>
			</Box>
		</Button>
	)
}

const MultiSelect = ({ values, isDisabled, iconStyles, ...props }) => {
	const { t } = useTranslation("global")
	const [field, meta] = useField(props)
	const [options, setOptions] = useState([])
	const [optionsCopy, setOptionsCopy] = useState([])
	const [selectedOptions, setSelectedOptions] = useState([])
	const [searchText, setSearchText] = useState("")
	const optionsRef = useRef(null)
	const inputRef = useRef(null)
	const chevronRef = useRef(null)
	const fieldAreaRef = useRef(null)
	const { setFieldValue } = useFormikContext()
	const heightBreakpoints = {
		base: 150,
		sm: 150,
		md: 200,
		lg: 250,
		xl: 302,
	}
	const height = useBreakpointValue(heightBreakpoints)

	useEffect(() => {
		const newOptions = values.filter((item) => field.value?.every((value) => value !== item.id) && item)
		setOptionsCopy(newOptions)
		if (searchText !== "") {
			const filteredOptions = newOptions.filter((option) =>
				option.name.toString().toLowerCase().includes(searchText.toLowerCase()),
			)
			setOptions(filteredOptions)
		} else {
			setOptions(newOptions)
		}
		const selectedItems = values.filter((item) => field.value?.some((value) => value === item.id) && item)
		setSelectedOptions(selectedItems)
	}, [field.value, values])

	useEffect(() => {
		if (inputRef && inputRef.current.style.display === "flex") {
			inputRef.current.focus()
		}
	}, [options])

	const handleOutsideClick = useCallback((event) => {
		if (
			fieldAreaRef.current &&
			!fieldAreaRef.current.contains(event.target) &&
			chevronRef.current &&
			!chevronRef.current.contains(event.target) &&
			optionsRef.current &&
			!optionsRef.current.contains(event.target)
		) {
			optionsRef.current.style.display = "none"
			inputRef.current.style.display = "none"
		}
	}, [])

	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClick)
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick)
		}
	}, [handleOutsideClick])

	const handleOpenSelect = (e) => {
		e.stopPropagation()
		if (isDisabled) return
		optionsRef.current.style.display = optionsRef.current.style.display === "flex" ? "none" : "flex"
		inputRef.current.style.display = optionsRef.current.style.display
		inputRef.current.style.display === "flex" && inputRef.current.focus()
	}
	const handleSelect = useCallback(
		(option) => {
			setFieldValue(field.name, [...field.value, option.id])
		},
		[field.name, field.value, setFieldValue],
	)

	const handleRemoveItem = useCallback(
		(id) => {
			const newFieldVal = field.value.filter((item) => item !== id)
			setFieldValue(field.name, newFieldVal)
		},
		[field.name, field.value, setFieldValue],
	)

	const handleSearching = (event) => {
		setSearchText(event.target.value)

		if (event.target.value === "") return setOptions(optionsCopy)
		const filteredOptions = optionsCopy.filter((option) =>
			option.name.toString().toLowerCase().includes(event.target.value.toLowerCase()),
		)
		setOptions(filteredOptions)
	}

	const handleFieldAreaClick = () => {
		if (isDisabled) return
		if (inputRef) {
			inputRef.current.style.display = "flex"
			inputRef.current.focus()
		}
		if (optionsRef) optionsRef.current.style.display = "flex"
	}

	const handleClickOutsideField = (e) => {
		if (inputRef.current.style.display === "none") {
			e.stopPropagation()
			inputRef.current.style.display = "none"
			optionsRef.current.style.display = "none"
		}
	}

	const handleClearField = (e) => {
		e.stopPropagation()
		setFieldValue(field.name, [])
	}

	const getItems = useMemo(
		() =>
			selectedOptions?.map((item, index) => (
				<Tag
					key={index}
					size={["sm", "md", "lg"]}
					borderRadius='full'
					variant='solid'
					bgColor={isDisabled ? "#789ddd" : "#2066df"}
					onClick={handleClickOutsideField}
				>
					<TagLabel wordBreak={"break-all"}>{item.name}</TagLabel>
					<TagCloseButton
						aria-label='Close button'
						isDisabled={isDisabled}
						cursor={isDisabled ? "not-allowed" : "pointer"}
						onClick={() => handleRemoveItem(item.id)}
					/>
				</Tag>
			)),
		[handleRemoveItem, isDisabled, selectedOptions],
	)

	return (
		<FormControl {...props} isInvalid={meta.error && meta.touched}>
			<Flex
				ref={fieldAreaRef}
				flexDirection={"column"}
				border='1px solid #e2e8f0'
				borderRadius={"8px"}
				cursor={isDisabled ? "not-allowed" : "text"}
				opacity={isDisabled ? 0.4 : 1}
				minH='20px'
				px={2}
				py={2}
				onClick={handleFieldAreaClick}
			>
				<Flex w='100%' justifyContent='space-between'>
					<Flex alignItems={"center"} flexWrap='wrap' gap={1} fontSize={[13, 14, 15, 16]}>
						{!field.value?.length && (
							<Text color='#67778D' ms={2}>
								{props.placeholder}
							</Text>
						)}
						{getItems}
					</Flex>
					<Flex gap={[1]}>
						{selectedOptions.length > 0 && (
							<Button
								aria-label='Clear field button'
								colorScheme='transparent'
								isDisabled={isDisabled}
								h={["25px", "25px", "30px"]}
								minW={["25px", "25px", "30px"]}
								p={0}
								onClick={handleClearField}
							>
								<CloseIcon color='black' boxSize={[2, 2, 2.5]} />
							</Button>
						)}
						<Button
							aria-label='Expand field button'
							minW={["25px", "25px", "30px"]}
							h={["30px", "35px", "35px", "30px"]}
							p={0}
							isDisabled={isDisabled}
							ref={chevronRef}
							onClick={handleOpenSelect}
							colorScheme={"transparent"}
							color='#000'
							justifyContent='center'
							alignItems='center'
							style={iconStyles}
						>
							<ChevronDownIcon boxSize={[5, 5, 6]} />
						</Button>
					</Flex>
				</Flex>
				<Input
					position={"relative"}
					display={"none"}
					h={"25px"}
					mt={2}
					border='none'
					_focusVisible={{ border: "none", boxShadow: "none" }}
					isDisabled={isDisabled}
					ref={inputRef}
					autoComplete='off'
					list='empty-datalist'
					value={searchText}
					fontSize={[13, 14, 15, 16]}
					onChange={handleSearching}
				/>
				<Box>
					<Box
						display='none'
						ref={optionsRef}
						bgColor='#FFF'
						position='absolute'
						top='100%'
						left={0}
						w='100%'
						zIndex={2}
					>
						{options.length ? (
							<List
								height={options?.length > 5 ? height : options.length * 40}
								display='none'
								itemCount={options.length}
								itemSize={39}
								width='100%'
								style={{ border: "1px solid #D4D4CD", borderRadius: "6px" }}
								itemData={{ options, handleSelect, field }}
							>
								{OptionItem}
							</List>
						) : (
							<Flex
								alignItems={"center"}
								width='100%'
								h={"40px"}
								border='1px solid #D4D4CD'
								borderRadius='6px'
								cursor='default'
							>
								<Text fontSize={[13, 14, 15, 16]} color='gray.500' ms={5}>
									{t("MultiSelect.emptyData")}
								</Text>
							</Flex>
						)}
					</Box>
				</Box>
			</Flex>
			<FormErrorMessage fontSize='15px' my='0'>
				{meta.error}
			</FormErrorMessage>
		</FormControl>
	)
}

export default memo(MultiSelect)
