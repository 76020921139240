import { createIcon } from "@chakra-ui/react"

const DoubleBookmarkIcon = ({ ...props }) => {
	const Icon = createIcon({
		displayName: "DoubleBookmarkIcon",
		viewBox: "0 0 40 52",
		path: (
			<g>
				<path
					d='M34.2857 0H5.71428C2.57142 0 0.0285645 2.55 0.0285645 5.66667L0 51.5L20 39L40 51.5V5.66667C40 2.55 37.4286 0 34.2857 0Z'
					fill='black'
				/>
				<path
					d='M27.1408 11H12.8449C11.2723 11 10 12.4321 10 14.1825V39L20 32.6L30 38.99V14.1825C30 12.4321 28.7134 11 27.1408 11Z'
					fill='white'
				/>
				<path
					d='M34.2857 0H5.71428C2.57142 0 0.0285645 2.55 0.0285645 5.66667L0 51.5L20 39L40 51.5V5.66667C40 2.55 37.4286 0 34.2857 0Z'
					stroke='black'
					fill={props.bgColor}
				/>
				<path
					d='M27.1408 11H12.8449C11.2723 11 10 12.4321 10 14.1825V39L20 32.6L30 38.99V14.1825C30 12.4321 28.7134 11 27.1408 11Z'
					stroke='black'
					fill={props.color}
				/>
			</g>
		),
	})

	return <Icon boxSize={props.boxSize} />
}

export default DoubleBookmarkIcon
