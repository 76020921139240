import { EditIcon } from "@chakra-ui/icons"
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Button,
	Divider,
	Flex,
	Heading,
	Text,
} from "@chakra-ui/react"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

const InventoryDetails = ({ id, documentNumber, inventoryCreator, comments, documentDate, status }) => {
	const { t } = useTranslation("global")
	const accordionBtnRef = useRef(null)
	const navigate = useNavigate()
	const { warehouseId, documentId } = useParams()

	const handleExpandRecord = () => {
		if (!accordionBtnRef) return
		accordionBtnRef.current.click()
	}

	const handleNavigateToEdit = () =>
		navigate(`/warehouse/warehouses/${warehouseId}/documents/inv/${documentId}/edit`, {
			state: {
				id: id,
				document_number: documentNumber,
				inventory_creator: inventoryCreator,
				comments,
				document_date: documentDate,
			},
		})

	const row = (fieldName, children) => (
		<Flex flexDirection={"column"} gap={2}>
			<Flex>
				<Text w={{ base: "200px" }} fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
					{t(`Warehouses.${fieldName}`)}
				</Text>
				{children}
			</Flex>
			<Divider borderColor='#E5E5DF' />
		</Flex>
	)

	return (
		<Accordion
			allowMultiple
			bgColor={"#FFF"}
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			borderRadius={"4px"}
		>
			<AccordionItem cursor={"pointer"} onClick={handleExpandRecord}>
				<Flex px={4} py={[1, 1.5, 2.5, 3]} justifyContent={"space-between"} alignItems={"center"}>
					<Heading as={"h3"} fontSize={[13, 14, 15, 16]} fontWeight={600}>
						{t("Warehouses.inventoryDetails")}
					</Heading>
					<Flex gap={2} alignItems={"center"}>
						{status === 0 && (
							<Button
								bgColor={"#F2F2EF"}
								p={0}
								minW='25px'
								boxSize={["25px", "30px", "35px"]}
								onClick={handleNavigateToEdit}
							>
								<EditIcon boxSize={[3, 4, 5]} />
							</Button>
						)}
						<AccordionButton ref={accordionBtnRef} p={0} boxSize={["30px", "35px"]}>
							<AccordionIcon mx='auto' boxSize={[5, 6, 7]} />
						</AccordionButton>
					</Flex>
				</Flex>
				<AccordionPanel py={0} px={4} onClick={(e) => e.stopPropagation()}>
					<Divider my={5} borderWidth='1px' borderColor='#E5E5DF' />
					<Flex py={4} flexDirection={"column"} gap={3}>
						{row("documentName", <Text fontSize={[13, 14, 15, 16]}>{documentNumber}</Text>)}
						{row("inventoryCreator", <Text fontSize={[13, 14, 15, 16]}>{inventoryCreator}</Text>)}
						{row("comments", <Text fontSize={[13, 14, 15, 16]}>{comments}</Text>)}
						{row("documentDate", <Text fontSize={[13, 14, 15, 16]}>{documentDate}</Text>)}
					</Flex>
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	)
}

export default InventoryDetails
