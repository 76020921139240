import { createAsyncThunk } from "@reduxjs/toolkit"
import { handleRequest } from "../../utils/api/api"

/**
 * Get current time and date
 */
export const getDateAndTime = createAsyncThunk("system/getDateAndTime", async () => {
	const responseData = await handleRequest({ endPoint: "/api/showWeekAndHour" })
	return responseData
})
