import { CheckIcon } from "@chakra-ui/icons"
import { Slide, toast } from "react-toastify"

const successUpdateToast = (toastId, message) => {
	return toast.update(toastId, {
		render: message,
		type: "success",
		isLoading: false,
		position: "bottom-center",
		autoClose: 1500,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: true,
		limit: 5,
		transition: Slide,
		theme: "colored",
		progressStyle: {
			backgroundColor: "#FFF",
		},
		style: {
			backgroundColor: "#38A169",
			color: "#FFFFFF",
		},
		icon: <CheckIcon boxSize={20} p={3} bgColor='#FFF' borderRadius='50%' color='#38A169' />,
	})
}

export default successUpdateToast
