import { createAsyncThunk } from "@reduxjs/toolkit"
import { handleRequest } from "../../../../utils/api/api"

/**
 * Get history function with dispatch action
 */
export const getOrdersHistory = createAsyncThunk("orders/history", async () => {
	const responseData = await handleRequest({ endPoint: "/api/orders/history" })
	return responseData
})
