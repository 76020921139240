import { Input } from "@chakra-ui/react"
import { useCallback, useEffect, useState } from "react"
import { debounce } from "lodash"

const DebouncedInput = ({ id, product_id, handleSave, ...props }) => {
	const [fieldValue, setFieldValue] = useState(props.value)

	useEffect(() => {
		if (props.value !== fieldValue) {
			setFieldValue(props.value)
		}
	}, [props.value])

	const fieldProps = {
		...props,
		value: fieldValue,
		onChange: (e) => setFieldValue(e.target.value),
		onKeyUp: (e) => handleOnChangeValue(e.target.value),
	}

	delete fieldProps?.name

	const handleOnChangeValue = useCallback(
		debounce((value) => handleSave(id, value, product_id), 1000),
		[handleSave],
	)

	return <Input {...fieldProps} />
}

export default DebouncedInput
