import { Flex } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

import BackForwardButtons from "../../../common/BackForwardButtons"
import ModuleHeading from "../../../common/ModuleHeading"

const WarehouseNavigation = ({ currentWarehouse }) => {
	const { t } = useTranslation("global")

	return (
		<Flex alignItems={"center"} gap={2}>
			<BackForwardButtons />
			<ModuleHeading
				whiteSpace={["normal", "nowrap"]}
				heading={`${t("Warehouses.warehouse")}: ${currentWarehouse?.name || ""}`}
			/>
		</Flex>
	)
}

export default WarehouseNavigation
