import { createSlice } from "@reduxjs/toolkit"
import {
	addWarehouseRoles,
	setWarehouseUsersRoles,
	getWarehouseSettings,
	setUserWarehouseAccess,
} from "../../../../actions/modules/warehouse/settings/settings-actions"

const warehouseSettingsSlice = createSlice({
	name: "warehouseSettings",
	initialState: {
		config: null,
	},
	reducers: {
		clearConfig(state) {
			state.config = null
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getWarehouseSettings.fulfilled, (state, action) => {
				state.config = {
					user_group_permissions: action.payload.warehouse_group_permissions,
					user_warehouse_group_permissions: action.payload.user_warehouse_group_permissions,
					access_to_warehouses: action.payload.access_to_warehouses,
				}
			})
			.addCase(addWarehouseRoles.fulfilled, (state, action) => {
				state.config.user_group_permissions.config = action.payload.config
				state.config.user_warehouse_group_permissions.config = action.payload.users.config
			})
			.addCase(setUserWarehouseAccess.fulfilled, (state, action) => {
				const newAccess = state.config.access_to_warehouses.data.map((item) => {
					if (item.id === action.meta.arg.userId) {
						const currentIndex = item.access.findIndex(
							(record) => record.id === action.meta.arg.warehouseId,
						)
						item.access[currentIndex].has_access = action.meta.arg.access
					}
					return item
				})
				state.config.access_to_warehouses.data = newAccess
			})
			.addCase(setWarehouseUsersRoles.fulfilled, (state, action) => {
				const userGroupsPermissions = state.config.user_warehouse_group_permissions.config.map((user) => {
					const matchingItem = action.payload.config.find((item) => user.id === item.id)

					if (matchingItem) {
						user.warehouse_group_permission_id = matchingItem.warehouse_group_permission_id
					}

					return user
				})

				state.config.user_warehouse_group_permissions.config = userGroupsPermissions
				state.config.user_group_permissions.config = action.payload.roles.config
			})
	},
})

export const { clearConfig } = warehouseSettingsSlice.actions

export default warehouseSettingsSlice
