import { Button, Flex } from "@chakra-ui/react"
import { memo, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { useGetIsInventory } from "../../../../hooks/Modules/Warehouse/useGetIsInventory"
import { useGetCurrentLimitedWarehouse } from "../../../../hooks/Modules/Warehouse/useGetCurrentLimitedWarehouse"
import PermissionsChecked from "../../../common/PermissionsChecked"

const WarehouseNavigationButtons = () => {
	const { t } = useTranslation("global")
	const navigate = useNavigate()
	const location = useLocation()
	const { warehouseId } = useParams()
	const limitedWarehouse = useGetCurrentLimitedWarehouse(warehouseId)
	const [splittedPathname] = useState(location.pathname.split("/"))
	const [initPathname] = useState(`/${splittedPathname[1]}/${splittedPathname[2]}/${splittedPathname[3]}`)
	const [isDocumentsPlace, setIsDocumentsPlace] = useState(location.pathname.includes("documents"))
	const [isAddPlace, setIsAddPlace] = useState(splittedPathname[4] === "add")
	const [isRemovePlace, setIsRemovePlace] = useState(splittedPathname[4] === "remove")
	const [isInventoryPlace, setIsInventoryPlace] = useState(location.pathname.includes("inventory"))
	const [isMagazinePlace, setIsMagazinePlace] = useState(
		!isDocumentsPlace && !isAddPlace && !isRemovePlace && !isInventoryPlace,
	)
	const { isInventory } = useGetIsInventory()

	useEffect(() => {
		setIsDocumentsPlace(location.pathname.includes("documents"))
		setIsAddPlace(location.pathname.split("/")[4] === "add")
		setIsRemovePlace(location.pathname.split("/")[4] === "remove")
		setIsInventoryPlace(location.pathname.includes("inventory"))
		setIsMagazinePlace(!isDocumentsPlace && !isAddPlace && !isRemovePlace && !isInventoryPlace)
	}, [location, isDocumentsPlace, isAddPlace, isRemovePlace, isInventoryPlace, isMagazinePlace])

	const handleNavigate = (path) => {
		navigate(`${initPathname}${path}`)
	}

	const navigationButton = (place, primary_color, secondary_color, path, fieldName) => (
		<Button
			h={["25px", "30px", "35px"]}
			bgColor={place ? "#FFF" : primary_color}
			color={place ? primary_color : "#FFF"}
			border={place ? `1px solid ${primary_color}` : "none"}
			fontSize={[13, 14, 15, 16]}
			_hover={{ bgColor: secondary_color, color: "#FFF" }}
			fontWeight={place ? "600" : "400"}
			onClick={() => handleNavigate(path)}
		>
			{t(`Warehouses.${fieldName}`)}
		</Button>
	)

	return (
		<Flex gap={2} flexWrap={"wrap"} justifyContent={"end"}>
			<PermissionsChecked permissions={"warehouse"} allowedPermissions={["warehouse_preview"]}>
				{navigationButton(isMagazinePlace, "#2C7A7B", "teal.700", "", "warehouse")}
			</PermissionsChecked>
			{navigationButton(isDocumentsPlace, "#2C7A7B", "teal.700", "/documents", "warehouseDocuments")}
			<PermissionsChecked permissions={"warehouse"} allowedPermissions={["document_edit"]}>
				{!isInventory && navigationButton(isAddPlace, "#38A169", "green.600", "/add", "addToWarehouse")}
				{!isInventory && navigationButton(isRemovePlace, "#C53030", "red.700", "/remove", "takeFromWarehouse")}
			</PermissionsChecked>
			<PermissionsChecked permissions={"warehouse"} allowedPermissions={["inventory"]}>
				{isInventory
					? navigationButton(
							isInventoryPlace,
							"#2C7A7B",
							"teal.700",
							`/documents/inv/${limitedWarehouse.document_id}`,
							"inventory",
					  )
					: navigationButton(isInventoryPlace, "#2C7A7B", "teal.700", "/inventory", "inventory")}
			</PermissionsChecked>
		</Flex>
	)
}

export default memo(WarehouseNavigationButtons)
