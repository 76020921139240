import { Box, Button, Flex, Grid, GridItem, Image, Input, Text } from "@chakra-ui/react"
import { FastField, FieldArray, useFormikContext } from "formik"
import BinIcon from "../../../../../assets/icons/materials/BinIcon"
import { AddIcon, CopyIcon, EditIcon } from "@chakra-ui/icons"
import AddImageIcon from "../../../../../assets/icons/materials/AddImageIcon"
import TextFieldWithDebounce from "../../../../common/TextFieldWithDebounce"
import LanguageInputs from "../../../../common/LanguageInputs"
import { memo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import imageCompression from "browser-image-compression"
import { warningToast } from "../../../../../utils/notifications/warningToast"

const EditParameterPanel = ({ parameter, parameterId, handleDragImage, getObjectURLFromImg, language }) => {
	const { t } = useTranslation("global")
	const { values, isSubmitting, setFieldValue } = useFormikContext()
	const parameterIconFileRef = useRef(null)
	const [clickedParameterId, setClickedParameterId] = useState()

	const handleUploadParameterIcon = async (e) => {
		if (e.target.files.length === 0) return

		const fileSizeInMB = (e.target.files[0].size / (1024 * 1024)).toFixed(0)
		if (fileSizeInMB >= 100) {
			warningToast(t("Materials.tooBigFile"))
			parameterIconFileRef.current.value = null
		} else {
			const options = {
				maxSizeMB: 100,
				maxWidthOrHeight: 800,
				useWebWorker: true,
			}
			try {
				const compressedFile = await imageCompression(e.target.files[0], options)
				setFieldValue(
					`parameters[${clickedParameterId}].icon`,
					new File([compressedFile], e.target.files[0].name, {
						type: "image/webp",
					}),
				)
				setFieldValue(
					"materials",
					values.materials.map((material) => {
						const parameter_counter = material.parameter_counter || []
						parameter_counter.map((parameterCounter, index) =>
							index === clickedParameterId
								? (parameterCounter.icon = new File([compressedFile], e.target.files[0].name, {
										type: "image/webp",
								  }))
								: parameterCounter,
						)
						return {
							...material,
							parameter_counter,
						}
					}),
				)
				parameterIconFileRef.current.value = null
			} catch (error) {
				console.log(error)
			}
		}
	}

	const handleAddParameterIcon = (id) => {
		parameterIconFileRef.current.click()
		setClickedParameterId(id)
	}

	const handleDropParameterIcon = async (id, e) => {
		e.preventDefault()
		if (e.dataTransfer.files.length === 0) return

		const fileSizeInMB = (e.dataTransfer.files[0].size / (1024 * 1024)).toFixed(0)
		if (fileSizeInMB >= 100) {
			warningToast(t("Materials.tooBigFile"))
			parameterIconFileRef.current.value = null
		} else {
			const fileName = e.dataTransfer.files[0].name
			const options = {
				maxSizeMB: 100,
				maxWidthOrHeight: 1200,
				useWebWorker: true,
			}
			try {
				const compressedFile = await imageCompression(e.dataTransfer.files[0], options)
				setFieldValue(`parameters[${id}].icon`, new File([compressedFile], fileName, { type: "image/webp" }))
				setFieldValue(
					"materials",
					values.materials.map((material) => {
						const parameter_counter = material.parameter_counter || []
						parameter_counter.map((parameterCounter, index) =>
							index === id
								? (parameterCounter.icon = new File([compressedFile], fileName, { type: "image/webp" }))
								: parameterCounter,
						)
						return {
							...material,
							parameter_counter,
						}
					}),
				)
			} catch (error) {
				console.error(error)
			}
		}
	}

	const handleRemoveParameterIcon = (id) => {
		parameterIconFileRef.current.value = null
		setFieldValue(`parameters[${id}].icon`, null)
	}

	const handleRemoveParameter = (remove, parameterId) => {
		const newMaterials = values.materials.map((material) => {
			const newParameterCounter = material.parameter_counter.filter((parameter, index) => index !== parameterId)
			return {
				...material,
				parameter_counter: newParameterCounter,
			}
		})
		setFieldValue("materials", newMaterials)
		remove(parameterId)
	}

	const handleRemoveParameterValue = (remove, valueId, parameterId) => {
		setFieldValue(
			"materials",
			values.materials.map((material) => {
				const parameter_counter = material.parameter_counter || []
				parameter_counter.map((parameterCounter, index) => {
					if (index === parameterId) {
						if (!parameterCounter.values) {
							parameterCounter.count = 0
						} else {
							parameterCounter.values.map((value) => {
								if (valueId === parameterCounter.count - 1) {
									parameterCounter.count = 0
								}
								return value
							})
						}
					}
					return parameterCounter
				})
				return {
					...material,
					parameter_counter,
				}
			}),
		)
		remove(valueId)
	}

	const getParametersValues = (parameterId) => (
		<FieldArray name={`parameters[${parameterId}].values`}>
			{({ remove, push }) => (
				<>
					{values.parameters[parameterId].values?.map((value, index) => (
						<Grid
							key={index}
							templateColumns={"repeat(2, 1fr)"}
							w='100%'
							bgColor='#F2F2EF'
							my={4}
							p={3}
							borderRadius='4px'
						>
							<GridItem>
								<Flex gap={2} mt={["1", "1", "1", "2"]}>
									<Box w={["100%", "100%", "80%", "60%", "40%"]}>
										<TextFieldWithDebounce
											disabled={isSubmitting}
											maxLength={191}
											fontSize={[13, 14, 15, 16]}
											h={["30px", "35px", "40px"]}
											as={Input}
											bgColor='#FFF'
											name={`parameters[${parameterId}].values[${index}].name_${language}`}
											placeholder={t("Materials.parameterValue", {
												lng: language,
											})}
										/>
									</Box>
									<LanguageInputs
										name={`parameters[${parameterId}].values[${index}].name`}
										maxLength={191}
										title={t("Materials.parameterValue", { lng: language })}
									/>
								</Flex>
							</GridItem>
							<GridItem>
								<Box
									display='flex'
									w='100%'
									justifyContent='end'
									alignItems='center'
									mt={["1", "1", "1", "2"]}
								>
									<Button
										aria-label='Copy parameter value button'
										isDisabled={isSubmitting}
										bgColor='transparent'
										minW='30px'
										boxSize={["30px", "35px", "40px"]}
										p={0}
										onClick={() =>
											push({
												name_pl: value.name_pl,
												name_en: value.name_en,
												name_nl: value.name_nl,
											})
										}
									>
										<CopyIcon boxSize={[4, 4, 5]} />
									</Button>
									<Button
										aria-label='Remove parameter value button'
										isDisabled={isSubmitting}
										display={value.is_used && "none"}
										minW='30px'
										boxSize={["30px", "35px", "40px"]}
										bgColor='transparent'
										p={0}
										onClick={() => handleRemoveParameterValue(remove, index, parameterId)}
									>
										<BinIcon color='red.500' boxSize={[4, 5, 6]} />
									</Button>
								</Box>
							</GridItem>
						</Grid>
					))}
					<Button
						isDisabled={isSubmitting}
						bgColor='#2B807E'
						_hover={{ backgroundColor: "teal.700" }}
						h={["30px", "35px", "40px"]}
						fontSize={[13, 14, 15, 16]}
						colorScheme='teal'
						color='#FFF'
						my={5}
						onClick={() => push({ name_pl: "", name_en: "", name_nl: "" })}
					>
						<AddIcon boxSize={3} me={2} />
						<Text aria-label='Add new row button'>{t("Materials.addNew", { lng: language })}</Text>
					</Button>
				</>
			)}
		</FieldArray>
	)

	return (
		parameter && (
			<FieldArray name={`parameters`}>
				{({ remove }) => (
					<>
						<FastField key={language} name={`parameters`}>
							{() => (
								<Box>
									<Grid
										templateColumns={[
											"repeat(1, 1fr)",
											"repeat(1, 1fr)",
											"repeat(1, 1fr)",
											"repeat(3, 1fr)",
											"repeat(2, 1fr)",
										]}
										w='100%'
									>
										<GridItem
											display={["grid", "grid", "grid", "none"]}
											w='100%'
											justifyContent='end'
											pe={1}
										>
											<Box display='flex' w='100%' justifyContent='end' alignItems='center'>
												<Button
													isDisabled={isSubmitting}
													display={parameter.is_used && "none"}
													p={1}
													me={3}
													bgColor='transparent'
													onClick={() => handleRemoveParameter(remove, parameterId)}
												>
													<Text
														aria-label='Remove parameter button'
														fontSize={[13, 14, 15, 16]}
														fontWeight={400}
													>
														{t("Materials.removeParameter", { lng: language })}
													</Text>
													<BinIcon color='red.500' boxSize={[5, 5, 6]} />
												</Button>
											</Box>
										</GridItem>
										<GridItem colSpan={[1, 1, 1, 2, 1]} ps={3}>
											<Box display='flex' alignItems='center'>
												<Box
													border='1px solid #F2F2EF'
													borderRadius='4px'
													p={[1, 1, 1.5]}
													onDragOver={handleDragImage}
													onDrop={(e) => handleDropParameterIcon(parameterId, e)}
												>
													{parameter.icon ? (
														<Box
															display={["block", "block", "flex"]}
															alignItems='center'
															gap={2}
															w={["60px", "90px", "170px", "180px"]}
															h={["100px", "130px", "50px", "50px"]}
														>
															<Box
																h={{ base: "70%", sm: "75%", md: "100%" }}
																w={{ base: "100%", md: "100%" }}
															>
																<Image
																	alt='Parameter icon'
																	mx='auto'
																	src={getObjectURLFromImg(parameter.icon)}
																	w='100%'
																	h='100%'
																	objectFit={"contain"}
																/>
															</Box>
															<Flex
																mt={[1, 1, 0]}
																w='100%'
																gap={2}
																justifyContent={"center"}
															>
																<Button
																	aria-label='Edit parameter icon button'
																	isDisabled={isSubmitting}
																	p={0}
																	minW='25px'
																	w={["25px", "30px", "32px", "35px"]}
																	h={["25px", "30px", "32px", "35px"]}
																	bgColor='#F2F2EF'
																	onClick={() => handleAddParameterIcon(parameterId)}
																>
																	<EditIcon boxSize={[3, 4, 5]} />
																</Button>
																<Button
																	aria-label='Remove parameter icon button'
																	isDisabled={isSubmitting}
																	p={0}
																	minW='25px'
																	w={["25px", "30px", "32px", "35px"]}
																	h={["25px", "30px", "32px", "35px"]}
																	bgColor='#F2F2EF'
																	onClick={() =>
																		handleRemoveParameterIcon(parameterId)
																	}
																>
																	<BinIcon color='red.500' boxSize={[4, 5, 6]} />
																</Button>
															</Flex>
														</Box>
													) : (
														<Button
															aria-label='Add parameter icon button'
															isDisabled={isSubmitting}
															minW='25px'
															boxSize={["25px", "30px", "35px"]}
															p={0}
															onClick={() => handleAddParameterIcon(parameterId)}
														>
															<AddImageIcon boxSize={[4, 4, 5]} />
														</Button>
													)}
												</Box>
												<Flex
													gap={2}
													ms={5}
													alignItems='center'
													h='100%'
													mt={["1", "1", "1", "2"]}
												>
													<TextFieldWithDebounce
														disabled={isSubmitting}
														as={Input}
														fontSize={[13, 14, 15, 16]}
														h={["30px", "35px", "40px"]}
														maxLength={191}
														name={`parameters[${parameterId}].name_${language}`}
														placeholder={t("Materials.parameterName", {
															lng: language,
														})}
													/>
													<LanguageInputs
														name={`parameters[${parameterId}].name`}
														maxLength={191}
														title={t("Materials.parameterName", {
															lng: language,
														})}
													/>
												</Flex>
											</Box>
										</GridItem>
										<GridItem
											display={["none", "none", "none", "grid"]}
											w='100%'
											justifyContent='end'
											pe={1}
										>
											<Box display='flex' w='100%' justifyContent='end' alignItems='center'>
												<Button
													isDisabled={isSubmitting}
													display={parameter.is_used ? "none" : "flex"}
													p={1}
													me={3}
													bgColor='transparent'
													onClick={() => handleRemoveParameter(remove, parameterId)}
												>
													<Text
														aria-label='Remove parameter button'
														fontSize={[13, 14, 15, 16]}
														fontWeight={400}
													>
														{t("Materials.removeParameter", { lng: language })}
													</Text>
													<BinIcon color='red.500' boxSize={[5, 5, 6]} />
												</Button>
											</Box>
										</GridItem>
									</Grid>
									<FastField name={`parameters[${parameterId}].values`}>
										{() => getParametersValues(parameterId)}
									</FastField>
								</Box>
							)}
						</FastField>
						<Input
							display='none'
							name='addedParameterIcon'
							ref={parameterIconFileRef}
							accept='image/*'
							type='file'
							onChange={(e) => handleUploadParameterIcon(e)}
						/>
					</>
				)}
			</FieldArray>
		)
	)
}

export default memo(EditParameterPanel)
