import { Button, ListItem, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from "@chakra-ui/react"
import { useSelector } from "react-redux"

import FiSettings from "../../../../assets/icons/header/FiSettings"
import PermissionsOptions from "../../PermissionsOptions/PermissionsOptions"

const AppPermissionsDesktop = () => {
	const permissionsOptions = useSelector((state) => state.auth.permission_options)

	return (
		permissionsOptions?.length > 0 && (
			<ListItem color='#000' me='8px' fontSize={["14", "14", "14", "14", "16"]}>
				<Popover placement='bottom' trigger='hover'>
					<PopoverTrigger>
						<Button
							aria-label='Settings button'
							position='relative'
							borderRadius='50%'
							bgColor='#E5E5DF'
							h='32px'
							minW='32px'
							p={0}
						>
							<FiSettings
								position='absolute'
								top='50%'
								left='50%'
								transform='translate(-50%, -50%)'
								boxSize={6}
							/>
						</Button>
					</PopoverTrigger>
					<PopoverContent w='100%'>
						<PopoverArrow />
						<PopoverBody>
							<PermissionsOptions
								aria-label='Navigate to permissions'
								styles={{
									padding: 8,
									borderRadius: 24,
								}}
								hover={{
									bgColor: "#E5E5DF",
								}}
							/>
						</PopoverBody>
					</PopoverContent>
				</Popover>
			</ListItem>
		)
	)
}

export default AppPermissionsDesktop
