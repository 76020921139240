import { SearchIcon } from "@chakra-ui/icons"
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"

const DocumentsSearch = ({ array, filterKey, isDisabled, handleSetResults }) => {
	const { t } = useTranslation("global")
	const [searchingTerm, setSearchingTerm] = useState("")

	const getFilterResults = useCallback(
		(filterValue) => {
			const filteredResults = {}
			Object.keys(array).forEach((key) => {
				const filteredArray = array[key].filter((item) =>
					item[filterKey].toString().toLowerCase().includes(filterValue.toLowerCase()),
				)

				if (filteredArray?.length) {
					filteredResults[key] = filteredArray
				}
			})
			return filteredResults
		},
		[array],
	)

	const handleChangeSearch = (val) => {
		setSearchingTerm(val)
		const results = getFilterResults(val)
		handleSetResults(results)
	}

	return (
		<InputGroup justifyContent='end'>
			<Input
				aria-label='Search input'
				isDisabled={isDisabled}
				bgColor={"#fff"}
				w={["200px", "auto"]}
				h={["30px", "35px", "40px"]}
				fontSize={[13, 14, 15, 16]}
				boxShadow={"0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);"}
				borderRadius={"4px"}
				placeholder={t("SearchBar.searchPlaceholder")}
				value={searchingTerm}
				onChange={(e) => handleChangeSearch(e.target.value)}
			/>
			<InputRightElement h={["30px", "35px", "40px"]}>
				<SearchIcon />
			</InputRightElement>
		</InputGroup>
	)
}

export default DocumentsSearch
