import { Form, Formik } from "formik"
import { memo, useCallback } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { infoToast } from "../../utils/notifications/infoToast"
import i18next from "i18next"

const FormikForm = ({ isPatchMethod = false, shouldNavigate = true, middleware, onSubmit, children, ...props }) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()

	const checkIsEmptyObject = (obj) => obj === "" || obj === null || obj === undefined

	const isFileObject = (obj) => obj instanceof File

	const filterValuesToPost = useCallback((data) => {
		if (typeof data !== "object" || data === null || isFileObject(data)) return data

		const filteredEntries = Object.entries(data)
			.map(([key, value]) => {
				if (Array.isArray(value)) {
					const filteredArray = value
						.map((item) => filterValuesToPost(item))
						.filter((item) => !checkIsEmptyObject(item))
					if (!filteredArray.length) return
					return [key, filteredArray]
				}

				if (typeof value === "object" && !checkIsEmptyObject(value)) {
					const filteredObject = filterValuesToPost(value)

					if (!Object.keys(filteredObject).length && !isFileObject(filteredObject)) return

					return [key, filteredObject]
				}

				if (checkIsEmptyObject(value)) return

				if (!key) return [value]

				return [key, value]
			})
			.filter(Boolean)

		return Object.fromEntries(filteredEntries)
	}, [])

	const containsOnlyIdProperty = useCallback((obj) => {
		if (obj === null || typeof obj !== "object") {
			return false
		}

		if (Array.isArray(obj)) {
			return obj.every((value) => containsOnlyIdProperty(value))
		}

		const keys = Object.keys(obj)
		if (keys.length === 1 && keys[0] === "id") {
			return true
		}

		return keys.every((key) => key === "id" && containsOnlyIdProperty(obj[key]))
	}, [])

	const getDeletedArray = useCallback((data, initialData, deletedArray = []) => {
		const processObject = (data, initialData, parentKey) => {
			for (const key in initialData) {
				if (key === "id") continue

				if (!Array.isArray(initialData[key]) && (!data || !(key in data))) {
					const existingEntry = deletedArray.find((entry) =>
						parentKey ? entry.name === parentKey : entry.name === key,
					)
					if (!existingEntry) {
						deletedArray.push({ name: parentKey || key, ids: [initialData.id] })
						break
					}
					existingEntry.ids.push(initialData.id)
					break
				} else if (
					Array.isArray(initialData[key]) &&
					!deletedArray.some(
						(item) => item.name === parentKey && item.ids.some((id) => id === initialData.id),
					)
				) {
					initialData[key].forEach((item) => {
						if (!(key in data)) {
							processObject(null, item, key)
						} else {
							processObject(
								data[key].find((obj) => obj.id === item.id),
								item,
								key,
							)
						}
					})
				}
			}
		}

		processObject(data, initialData, null)
		return deletedArray
	}, [])

	const filterValuesToPatch = useCallback((data, initialData) => {
		const filteredEntries = Object.entries(data)
			.map(([key, value]) => {
				if (key !== "id") {
					if (!Array.isArray(value)) {
						if (!initialData || value !== initialData[key]) return [key, value]
						return
					}

					const filteredArray = value
						.map((item) =>
							filterValuesToPatch(
								item,
								initialData[key].find((object) => object.id === item.id),
							),
						)
						.filter((item) => item !== undefined && !containsOnlyIdProperty(item))

					if (!filteredArray.length) return
					return [key, filteredArray]
				}
				return [key, value]
			})
			.filter(Boolean)

		return Object.fromEntries(filteredEntries)
	}, [])

	const getValuesToPatch = (data, initialData) => {
		const filteredValues = filterValuesToPatch(data, initialData)
		const deleted_array = getDeletedArray(data, initialData)
		if (deleted_array?.length) return { ...filteredValues, deleted_array: deleted_array }
		return filteredValues
	}

	return (
		<Formik
			{...props}
			onSubmit={(data, { setSubmitting }) => {
				const sendedData = middleware ? middleware(data) : { data: data }

				if (!sendedData.data) {
					setSubmitting(false)
					return
				}

				const filteredValues = isPatchMethod
					? getValuesToPatch(sendedData.data, sendedData.initValues || props.initialValues)
					: filterValuesToPost(sendedData.data)

				if (containsOnlyIdProperty(filteredValues)) {
					infoToast(t("Common.noChanges"))
					setSubmitting(false)
					return
				}

				dispatch(onSubmit(filteredValues)).then((event) => {
					setSubmitting(false)
					if (event.error) return

					if (!shouldNavigate) return

					if (!isPatchMethod) {
						navigate("../")
						return
					}

					const path = location.pathname.split("/")
					path.splice(-2)
					navigate(`${path.join("/")}/`)
				})
			}}
		>
			<Form key={i18next.language}>{children}</Form>
		</Formik>
	)
}

export default memo(FormikForm)
